import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import { useHistory } from "react-router";
import PlaceholderImage from "../../../assets/img/placeholder.svg";
import Svg from "components/Svg/Svg.jsx";
import Loader from "views/Components/Loader/Loader";

const SelectServiceModal = (props) => {
  const [allServices, setAllServices] = useState([]);
  const [openFolder, setOpenFolder] = useState({
    isOpen: false,
    folderId: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.show && allServices.length === 0) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/services/folders`)
        .then((res) => {
          let folders = res.data.map((folder) => {
            return { ...folder, opened: false };
          });
          // setAllServices(res.data);
          setAllServices(folders);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.show]);

  useEffect(() => {}, [openFolder.isOpen]);

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Services</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="grid grid-1col gap-16" style={{ padding: "10px" }}>
          {allServices.map((folder) => {
            return (
              <div
                className="mb-4"
                key={folder.id}
                style={{ cursor: "pointer", width: "100%" }}
                onClick={() =>
                  setOpenFolder({
                    ...openFolder,
                    isOpen: !openFolder.isOpen,
                    folderId: folder.id,
                  })
                }
              >
                {/* {loading && (
                  <div
                    className="flex flex-col items-center justify-center"
                    style={{ paddingBottom: "48px" }}
                  >
                    <Loader />
                    <p
                      className="text-xs text-gray-400 mt-6"
                      style={{ color: "var(--theme-color)" }}
                    >
                      Loading services...
                    </p>
                  </div>
                )} */}
                <div
                  className="flex flex-row gap-6 items-center p-8"
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <div>
                    <img
                      src={
                        folder.picture && folder.picture.url
                          ? folder.picture.url
                          : PlaceholderImage
                      }
                      alt={folder.title || ""}
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                        marginRight: 20,
                      }}
                    />
                    <span className="text-semibold main-text text-lg">
                      {folder.title}
                    </span>
                  </div>
                  {openFolder.isOpen ? (
                    <Svg name="chevron-down" className="w-4 h-4" />
                    ) : (
                    <Svg name="chevron-up" className="w-4 h-4" />
                  )}
                </div>
                <div style={{ width: "100%", padding: 10 }}>
                  {folder.services.length > 0 &&
                  openFolder.isOpen &&
                  openFolder.folderId === folder.id
                    ? folder.services.map((service) => {
                        return (
                          <Button
                            key={service.id}
                            bsStyle="danger"
                            disabled={
                              props.person &&
                              props.person.services.find(
                                (s) => s.id === service.id
                              )
                            }
                            onClick={() => {
                              if (props.onConfirm) {
                                props.onConfirm(service);
                              }
                            }}
                            style={{ width: "100%", marginBottom: 15 }}
                          >
                            <div className="flex flex-col gap-6 text-center">
                              <span className="text-semibold main-text text-lg">
                                {service.title}
                              </span>
                              <span className="theme-text text-semibold text-lg">
                                ${" "}
                                {new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                  signDisplay: "never",
                                }).format(
                                  service.price > 0 ? service.price / 100 : 0
                                )}
                              </span>
                            </div>
                          </Button>
                        );
                      })
                    : ""}
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SelectServiceModal;
