import React from "react";
import "./Loader.css";

const Loader = (props) => {
  return (
    <div className="taplabs-loader" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <circle cx="16.06" cy="23.43" r="5.32" fill="none" stroke="#ececec" />
        <circle
          className="loader-circle"
          cx="16.06"
          cy="23.43"
          r="5.32"
          fill="none"
          stroke="var(--theme-color)"
          strokeWidth="1.2"
          strokeLinecap="round"
        />
        <path
          className="loader-drop"
          fill="none"
          stroke="var(--theme-color)"
          strokeWidth="1.2"
          d="M10.74 23.43S10.3 3.8 16.02 3.8s5.28 19.63 5.28 19.63"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default Loader;
