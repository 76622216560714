import React, { useEffect, useState } from "react";
import moment from "moment";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTable from "react-table";
import Swal from "sweetalert2";
import AddPromocodeModal from "./AddPromocodeModal.jsx";
import Svg from "components/Svg/Svg.jsx";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import { parseError, dateFormat } from "api/common.js";

const PromoCodes = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [pageSize] = useState(15);
  const [addPromocodeModalOpen, setAddPromocodeModalOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    getDiscounts();
  }, []);

  const getDiscounts = (pageToken = null, isBackward = null) => {
    setLoading(true);
    setError(null);
    setData([]);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/discounts?pageSize=${pageSize}&paginationToken=${
          pageToken || ""
        }&isBackward=${isBackward ? "true" : "false"}`
      )
      .then((res) => {
        setNextPageToken(res.data.nextPageToken);
        setPreviousPageToken(res.data.previousPageToken);
        setData(mapItems(res.data.items));
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const mapItems = (items) => {
    return items.map((prop) => {
      return mapCode(prop);
    });
  };

  const mapCode = (prop) => {
    return {
      id: prop.id,
      name: prop.name,
      code: prop.code,
      expireAt: formatDate(prop.expireAt),
      redemptionsCount: prop.redemptionsCount,
      value: formatValue(prop),
      status: (
        <div className={`badge ${prop.active ? "success" : "error"}`}>
          {prop.active ? "Active" : "Disabled"}
        </div>
      ),
      type: prop.type,
      actions: (
        <div className="flex items-center gap-6">
          <Button
            disabled={actionLoading}
            title="View/Edit Details"
            bsStyle="danger"
            outline="true"
            style={{ padding: "7px 10px" }}
            onClick={() => props.history.push(`/admin/edit-promocode/${prop.code}`)}
          >
            <Svg name="edit" className="w-4 h-4" />
          </Button>{" "}
          <Button
            disabled={actionLoading}
            onClick={() => deleteDiscount(prop.code)}
            title="Delete Promo Code"
            bsStyle="danger"
            outline="true"
            style={{ padding: "7px 10px" }}
          >
            <Svg name="trash" className="w-4 h-4" />
          </Button>
        </div>
      ),
    };
  };

  const deleteDiscount = (promoCode) => {
    Swal.fire({
      title: `Are you sure you want to delete this promo code (${promoCode})?`,
      text: "You can only remove the promo code that have not been redeemed yet. Otherwise, please deactivate promo code to prevent it from being redeemed.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Promo Code",
    }).then((result) => {
      if (result.isConfirmed) {
        setActionLoading(true);
        axios
          .delete(`${process.env.REACT_APP_API_URL}/discounts/${promoCode}`)
          .then((res) => {
            setActionLoading(false);
            getDiscounts();

            Swal.fire({
              title: "Promo Code Deleted",
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            setActionLoading(false);
            let error = parseError(err);
            Swal.fire("Delete Error", error, "error");
          });
      }
    });
  };

  const formatDate = (internalDate) => {
    if (!internalDate) {
      return "--";
    }
    return moment(
      `${internalDate.day}/${internalDate.month}/${internalDate.year}`,
      "D/M/YYYY"
    ).format(dateFormat);
  };

  const formatValue = (item) => {
    if (item.type === "fixed") {
      return `$${new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        signDisplay: "never",
      }).format(item.value > 0 ? item.value / 100 : 0)}`;
    }

    return `${new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 0,
      signDisplay: "never",
    }).format(item.value)}%`;
  };

  return (
    <div className="main-content flex flex-col" style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-center w-full">
            <div className="title">Promo Codes</div>
            <div style={{ marginLeft: "auto" }}>
              <Button
                style={{ marginLeft: "auto" }}
                bsStyle="danger"
                fill
                onClick={() => {
                  setAddPromocodeModalOpen(true);
                }}
              >
                <Svg name="plus" className="w-4 h-4" />
                New Promo Code
              </Button>
            </div>
          </div>
        }
        content={
          <div className="flex flex-col gap-16">
            <ReactTable
              loading={loading}
              loadingText="Loading promo codes..."
              noDataText={error ? error : loading ? "" : "No promo codes found..."}
              data={data}
              columns={[
                {
                  Header: "Code",
                  accessor: "code",
                  sortable: false,
                  filterable: false,
                  style: { whiteSpace: "unset" },
                },
                {
                  Header: "Promo Name",
                  accessor: "name",
                  sortable: false,
                  filterable: false,
                  minWidth: 200,
                  style: { whiteSpace: "unset" },
                },
                {
                  Header: "Expire At",
                  accessor: "expireAt",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "Value",
                  accessor: "value",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "Status",
                  accessor: "status",
                  sortable: false,
                  filterable: false,
                  maxWidth: 110,
                },
                {
                  Header: "Usages",
                  accessor: "redemptionsCount",
                  sortable: false,
                  filterable: false,
                  maxWidth: 70,
                },
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  maxWidth: 100,
                  resizable: false,
                },
              ]}
              showPaginationBottom={false}
              defaultPageSize={pageSize}
              className="-striped -highlight"
            />
            <div className="flex items-center" style={{ marginLeft: "auto" }}>
              <Button
                className="pagination-btn left btn-fill"
                disabled={!previousPageToken || loading}
                onClick={() => getDiscounts(previousPageToken, true)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-left" className="w-6 h-6" />
              </Button>
              <Button
                className="pagination-btn right btn-fill"
                disabled={!nextPageToken || loading}
                onClick={() => getDiscounts(nextPageToken, false)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-right" className="w-6 h-6" />
              </Button>
            </div>
          </div>
        }
      />
      <AddPromocodeModal
        show={addPromocodeModalOpen}
        onCreated={() => {
          getDiscounts();
        }}
        onHide={() => setAddPromocodeModalOpen(false)}
      />
    </div>
  );
};

export default PromoCodes;
