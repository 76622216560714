import React, {useEffect, useState} from "react";
import Svg from "../Svg/Svg";

export default function CreditCardModel({data}){
    const [cardBrand, setCardBrand] = useState(null);

    useEffect(() => {
        switch (data.brand.toLowerCase()){
            case "visa":
            case "mastercard":
            case "amex":
                setCardBrand(<Svg name={`${data.brand.toLowerCase()}`} className="w-4 h-4"/>);
                break;
            default:
                setCardBrand(<Svg name="card" className="w-4 h-4"/>);
        }
    }, [])

    return(
        <div className="flex">
            {cardBrand}
            <div>
                {` .... .... ${data.last4}`}
            </div>
        </div>
    );
}