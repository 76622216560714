import React, { useState, useEffect } from "react";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import { parseError } from "api/common";
import NumberFormat from "react-number-format";

const RefundAppointmentModal = (props) => {
  const [notes, setNotes] = useState("");
  const [refundAmount, setRefundAmount] = useState(props.availableAmount > 0 ? props.availableAmount / 100.0 : 0.1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setNotes("");
    setRefundAmount(props.availableAmount > 0 ? props.availableAmount / 100.0 : 0.1);
    setError(null);
    setLoading(false);
  }, [props.show, props.appointment]);

  const onRefundAppointment = () => {
    setError(null);
    setLoading(true);

    axios
      .patch(`${process.env.REACT_APP_API_URL}/appointments/${props.appointmentId}/refund`, {
        notes: notes,
        amount: parseInt(refundAmount * 100),
      })
      .then((res) => {
        if (props.onConfirm) {
          props.onConfirm(res.data || []);
        }

        setLoading(false);
        props.onHide();
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Appointment Refund</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <Card
            content={
              <div className="flex flex-col gap-16">
                <FormGroup>
                  <ControlLabel>AVAILABLE TO REFUND</ControlLabel>
                  <FormControl
                    type="text"
                    maxLength="32"
                    name="lastName"
                    disabled={true}
                    readOnly={true}
                    value={props.availableAmount > 0 ? (props.availableAmount / 100.0).toFixed(2) : 0}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>REFUND ({props.currency})</ControlLabel>
                  <div className="flex items-center">
                    <NumberFormat
                      value={refundAmount}
                      defaultValue={0}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      prefix="$"
                      className="form-control"
                      inputMode="numeric"
                      isAllowed={({ floatValue }) => {
                        return floatValue == undefined || floatValue < props.availableAmount;
                      }}
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setRefundAmount(value ? parseFloat(value) : 0);
                      }}
                    />
                  </div>
                  {(refundAmount * 100 > props.availableAmount || refundAmount < 0.1) && (
                    <small className="text-danger" style={{ marginTop: "4px" }}>
                      Refund cannot be more than $
                      {new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(props.availableAmount > 0 ? props.availableAmount / 100 : 0)}{" "}
                      {props.currency} or less than $0.1 {props.currency}.
                    </small>
                  )}
                </FormGroup>

                <FormGroup>
                  <ControlLabel>Notes</ControlLabel>
                  <FormControl
                    type="textarea"
                    maxLength={500}
                    componentClass="textarea"
                    rows={5}
                    disabled={loading}
                    readOnly={loading}
                    value={notes}
                    onChange={(event) => {
                      setNotes(event.target.value);
                    }}
                  />
                </FormGroup>

                {error && (
                  <div className="error-alert" style={{ padding: "0.6rem" }}>
                    {error}
                  </div>
                )}
              </div>
            }
          />

          <div className="flex items-center gap-10" style={{ marginLeft: "auto", marginTop: "16px" }}>
            <Button disabled={loading} bsStyle="danger" outline="true" onClick={() => props.onHide()}>
              Cancel
            </Button>

            <Button
              loading={loading}
              bsStyle="danger"
              fill
              onClick={() => onRefundAppointment()}
              disabled={loading || refundAmount < 0.1 || refundAmount > 999999.99 || props.availableAmount <= 0}
            >
              Refund
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RefundAppointmentModal;
