import React, { useState, useEffect } from "react";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import "react-phone-number-input/style.css";
import Svg from "components/Svg/Svg";
import { parseError } from "api/common";

const AddLabTechModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState({ value: "" });
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [genders, setGenders] = useState([]);
  const [serviceCentresList, setServiceCentresList] = useState([]);
  const [serviceCentre, setServiceCentre] = useState(null);
  const [recruitmentType, setRecruitmentType] = useState({ value: "" });
  const [recruitmentTypes] = useState([
    { label: "Commission", value: "Commission" },
    { label: "Salary", value: "Salary" },
  ]);

  useEffect(() => {
    if (showModal) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/service-centres`)
        .then((res) => {
          setServiceCentresList(res.data.items);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(`${process.env.REACT_APP_API_URL}/account/genders`)
        .then((res) => {
          setGenders(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setGender({ value: "" });
      setServiceCentre(null);
      setError(null);
      setLoading(false);
      setRecruitmentType({ value: "" });
      setPhone("");
      setEmailError(null);
      setPhoneError(null);
    }
  }, [showModal]);

  const handleEmailChange = (event) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = re.test(event.target.value) === true;
    if (!isValid && event.target.value) {
      setEmailError(
        <small className="text-danger">Invalid email address format. Please make sure that you are using valid email address.</small>
      );
    } else {
      setEmailError(null);
    }
  };

  const onCreateAccount = () => {
    setLoading(true);
    setError(null);

    const obj = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      gender: gender.value,
      phone: phone,
      technicianType: recruitmentType.value,
      serviceCentreId: serviceCentre.value,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/technicians`, obj)
      .then((res) => {
        setLoading(false);
        setShowModal(false);

        Swal.fire({
          title: "The technician's account has been created.",
          icon: "success",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed && props.onConfirm) {
            props.onConfirm();
          }
        });
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  return (
    <div className="register-company">
      <Button bsStyle="danger" fill onClick={() => setShowModal(true)}>
        <Svg name="user-plus" className="w-4 h-4" />
        New Account
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Technician Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col">
            <Card
              content={
                <div className="grid gap-6" style={{ gridTemplateColumns: "repeat(2,minmax(0,1fr))", gridColumnGap: "16px" }}>
                  <FormGroup>
                    <ControlLabel>
                      First Name: <span className="star">*</span>
                    </ControlLabel>
                    <FormControl
                      type="text"
                      maxLength="32"
                      name="firstName"
                      value={firstName}
                      onChange={(event) => {
                        setFirstName(event.target.value.replace(/[^a-z ]/gi, ""));
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>
                      Last Name: <span className="star">*</span>
                    </ControlLabel>
                    <FormControl
                      type="text"
                      maxLength="32"
                      name="lastName"
                      value={lastName}
                      onChange={(event) => {
                        setLastName(event.target.value.replace(/[^a-z ]/gi, ""));
                      }}
                    />
                  </FormGroup>

                  <div className="form-group phone-input">
                    <ControlLabel>
                      Phone Number: <span className="star">*</span>
                    </ControlLabel>
                    <PhoneInput
                      maxLength="16"
                      max="10"
                      defaultCountry="CA"
                      extension="true"
                      value={phone}
                      onChange={(value) => {
                        setPhone(value);
                        if (value && !isPossiblePhoneNumber(value)) {
                          setPhoneError(<small className="text-danger">A valid Canadian or US phone number is required.</small>);
                        } else {
                          setPhoneError(null);
                        }
                      }}
                      required
                    />
                    {phoneError}
                  </div>

                  <FormGroup>
                    <ControlLabel>
                      Email address: <span className="star">*</span>
                    </ControlLabel>
                    <FormControl
                      maxLength="100"
                      type="text"
                      name="email"
                      onChange={(event) => setEmail(event.target.value)}
                      onBlur={(event) => handleEmailChange(event)}
                      required
                    />
                    {emailError}
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>
                      Gender: <span className="star">*</span>
                    </ControlLabel>
                    <Select
                      name="gender-select"
                      isClearable={false}
                      className="react-select react-select-icon"
                      isSearchable={true}
                      placeholder="Select Gender"
                      noOptionsMessage={(inp) => "No Genders Loaded"}
                      isMulti={false}
                      value={gender}
                      onChange={(opt) => {
                        setGender(opt);
                      }}
                      options={genders.map((s) => {
                        return { label: s.value, value: s.key };
                      })}
                      formatGroupLabel={(data) => <div className="flex items-center text-theme font-semibold">{data.label}</div>}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>
                      Recruitment Type: <span className="star">*</span>
                    </ControlLabel>
                    <Select
                      name="recruitment-select"
                      isClearable={false}
                      className="react-select react-select-icon"
                      isSearchable={true}
                      placeholder="Select Recruitment Type"
                      isMulti={false}
                      value={recruitmentType}
                      onChange={(opt) => {
                        setRecruitmentType(opt);
                      }}
                      options={recruitmentTypes}
                      formatGroupLabel={(data) => <div className="flex items-center text-theme font-semibold">{data.label}</div>}
                    />
                  </FormGroup>
                  <FormGroup style={{ gridColumn: "1 / span 2" }}>
                    <ControlLabel>
                      Service Centre: <span className="star">*</span>
                    </ControlLabel>
                    <Select
                      name="service-centre-select"
                      isClearable={false}
                      className="react-select react-select-icon"
                      isSearchable={true}
                      placeholder="Select Service Centre"
                      noOptionsMessage={(inp) => "No Service Centres"}
                      isMulti={false}
                      value={serviceCentre}
                      onChange={(opt) => {
                        setServiceCentre(opt);
                      }}
                      options={serviceCentresList.map((s) => {
                        return { label: s.name, value: s.id };
                      })}
                      formatGroupLabel={(data) => <div className="flex items-center text-theme font-semibold">{data.label}</div>}
                    />
                  </FormGroup>
                </div>
              }
            />

            {error && (
              <div className="error-alert" style={{ gridColumn: "1 / span 2", marginTop: "12px" }}>
                {error}
              </div>
            )}

            <div className="flex items-center gap-10" style={{ marginLeft: "auto", marginTop: "16px" }}>
              <Button disabled={loading} bsStyle="danger" outline="true" onClick={() => setShowModal(false)}>
                Cancel
              </Button>

              <Button
                loading={loading}
                bsStyle="danger"
                fill
                onClick={() => onCreateAccount()}
                disabled={
                  !firstName || !lastName || !email || !phone || !gender || !recruitmentType || !serviceCentre || phoneError || emailError
                }
              >
                Create Account
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddLabTechModal;
