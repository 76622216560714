import React, { useState, useEffect } from "react";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import moment from "moment";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import axios from "axios";
import { parseError } from "api/common";
import { dateTimeFormat } from "api/common";
import { dateFormat } from "api/common";

const RescheduleModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [availabilitiesLoading, setAvailabilitiesLoading] = useState(false);
  const [error, setError] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(new Date());
  const [scheduledTime, setScheduledTime] = useState(null);
  const [availabilities, setAvailabilities] = useState([]);

  useEffect(() => {
    setError(null);
    setLoading(false);
    setScheduledTime(null);
    setScheduledDate(new Date());
    setAvailabilities([]);
    setAvailabilitiesLoading(false);

    if (props.show && props.appointmentId && props.appointment) {
      getAvailabilities();
    }
  }, [props.show, props.appointmentId, props.appointment]);

  useEffect(() => {
    if (props.show) {
      getAvailabilities();
    }
  }, [scheduledDate]);

  const getAvailabilities = () => {
    if (availabilitiesLoading) {
      setTimeout(() => {
        getAvailabilities();
      }, 1000);
      return;
    }

    if (!scheduledDate) {
      return;
    }

    let appServices = props.appointment.appointmentPatients
      .flatMap((p) => p.appointmentItems)
      .map((s) => s.service.id);
    let serviceCounts = appServices.filter(distinctValue).map((svc) => {
      return {
        serviceId: svc,
        count: appServices.filter((sId) => sId == svc).length,
      };
    });

    setAvailabilities([]);
    setAvailabilitiesLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/appointments/availabilities`, {
        latitude: props.appointment.location.latitude,
        longitude: props.appointment.location.longitude,
        date: {
          year: scheduledDate.getFullYear(),
          month: scheduledDate.getMonth() + 1,
          day: scheduledDate.getDate(),
        },
        requestedServices: serviceCounts,
      })
      .then((res) => {
        setAvailabilitiesLoading(false);

        let today = new Date();
        let froms =
          res.data.date.day === today.getDate()
            ? res.data.froms.filter(
                (f) =>
                  f.hour > today.getHours() ||
                  (f.hour === today.getHours() && f.minute > today.getMinutes() + 15)
              )
            : res.data.froms;
        setAvailabilities(froms);
      })
      .catch((err) => {
        let error = parseError(err);
        setAvailabilitiesLoading(false);
        setAvailabilities([]);
      });
  };

  const onRescheduleAppointment = () => {
    setError(null);
    setLoading(true);

    axios
      .patch(`${process.env.REACT_APP_API_URL}/appointments/${props.appointmentId}`, {
        year: scheduledDate.getFullYear(),
        month: scheduledDate.getMonth() + 1,
        day: scheduledDate.getDate(),
        hour: parseInt(scheduledTime.value.split(":")[0]),
        minute: parseInt(scheduledTime.value.split(":")[1]),
      })
      .then((res) => {
        if (props.onConfirm) {
          props.onConfirm(res.data);
        }

        setLoading(false);
        props.onHide();
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const distinctValue = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const formatDate = (inDate) => {
    if (!inDate) {
      return "--";
    }

    let dateString = moment(
      `${inDate.day}/${inDate.month}/${inDate.year} ${inDate.hour}:${inDate.minute}`,
      "D/M/YYYY H:mm"
    ).format(dateTimeFormat);

    return dateString;
  };

  const isValidDate = function (current) {
    return current.isAfter(moment().add(-1, "days"));
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Reschedule Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <Card
            content={
              <div className="flex flex-col gap-16">
                <FormGroup>
                  <ControlLabel>ORIGINAL DATE</ControlLabel>
                  <FormControl
                    type="text"
                    name="appDate"
                    disabled={true}
                    readOnly={true}
                    value={formatDate(props.appointment.startTime)}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>New Date</ControlLabel>
                  <ReactDatetime
                    className="w-full"
                    dateFormat={dateFormat}
                    value={scheduledDate}
                    inputProps={{
                      required: true,
                      placeholder: "Select Date",
                      disabled: loading || availabilitiesLoading,
                    }}
                    timeFormat={false}
                    isValidDate={isValidDate}
                    closeOnSelect={true}
                    onChange={(e) => {
                      setScheduledTime(null);
                      setScheduledDate(moment(e).toDate());
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>New Time</ControlLabel>
                  <Select
                    isDisabled={loading || !scheduledDate}
                    name="time-select"
                    isClearable={false}
                    className="react-select react-select-icon w-full"
                    isSearchable={false}
                    isLoading={loading || availabilitiesLoading}
                    placeholder="Select Time"
                    noOptionsMessage={(inp) => "No time slots"}
                    isMulti={false}
                    value={scheduledTime}
                    onChange={(opt) => {
                      setScheduledTime(opt);
                    }}
                    options={availabilities.map((s) => {
                      return {
                        label: `${s.hour}:${s.minute.toString().padStart(2, "0")}`,
                        value: `${s.hour}:${s.minute.toString().padStart(2, "0")}`,
                      };
                    })}
                    formatGroupLabel={(data) => (
                      <div className="flex items-center text-theme font-semibold">{data.label}</div>
                    )}
                  />
                </FormGroup>

                {error && (
                  <div className="error-alert" style={{ padding: "0.6rem" }}>
                    {error}
                  </div>
                )}
              </div>
            }
          />

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button
              disabled={loading}
              bsStyle="danger"
              outline="true"
              onClick={() => props.onHide()}
            >
              Close
            </Button>

            <Button
              loading={loading}
              bsStyle="danger"
              fill
              onClick={() => onRescheduleAppointment()}
              disabled={loading || !scheduledDate || !scheduledTime || availabilitiesLoading}
            >
              Reschedule Appointment
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RescheduleModal;
