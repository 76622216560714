import React, { useState, useEffect } from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import Button from "components/CustomButton/CustomButton.jsx";
import OpenCloseTime from "./OpenCloseTime.jsx";
import AddLocationModal from "../../Components/AutocompleteWithMap/AddLocationModal.jsx";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import Svg from "components/Svg/Svg.jsx";
import DatesListControl from "./DatesListControl/DatesListControl.jsx";
import { parseError } from "api/common.js";

const CreateDropOffLocation = (props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [addressData, setAddressData] = useState(null);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [locationServices, setLocationServices] = useState([]);
  const [closureDates, setClosureDates] = useState([]);
  const [dropLocationId, setDropLocationId] = useState(null);
  const [hoursOfOperations, setHoursOfOperation] = useState([
    {
      dayOfWeek: "Sunday",
      start: "Closed",
      end: "Closed",
    },
    {
      dayOfWeek: "Monday",
      start: "Closed",
      end: "Closed",
    },
    {
      dayOfWeek: "Tuesday",
      start: "Closed",
      end: "Closed",
    },
    {
      dayOfWeek: "Wednesday",
      start: "Closed",
      end: "Closed",
    },
    {
      dayOfWeek: "Thursday",
      start: "Closed",
      end: "Closed",
    },
    {
      dayOfWeek: "Friday",
      start: "Closed",
      end: "Closed",
    },
    {
      dayOfWeek: "Saturday",
      start: "Closed",
      end: "Closed",
    },
  ]);

  useEffect(() => {
    getServices();

    let dropId = props.match.params.id;
    if (dropId !== "new") {
      setDropLocationId(dropId);
    }
  }, []);

  useEffect(() => {
    if (dropLocationId && dropLocationId != "new") {
      setLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL}/dropofflocations/${dropLocationId}`)
        .then((res) => {
          setLoading(false);
          initializeData(res.data);
        })
        .catch((err) => {
          setLoading(false);
          let error = parseError(err);
          Swal.fire("Drop Off Location Error", error, "error");
        });
    }
  }, [dropLocationId]);

  const initializeData = (dropData) => {
    setName(dropData.name);
    let hopList = hoursOfOperations.map((hop) => {
      var scItem = dropData.hoursOfOperations.find((hh) => hh.dayOfWeek === hop.dayOfWeek);
      if (scItem && scItem.startTime && scItem.endTime) {
        let start = moment(scItem.startTime.hour + ":" + scItem.startTime.minute, ["h:mm A"]);
        let end = moment(scItem.endTime.hour + ":" + scItem.endTime.minute, ["h:mm A"]);

        return {
          dayOfWeek: hop.dayOfWeek,
          start: { value: start.format("h:mm A"), label: start.format("h:mm A") },
          end: { value: end.format("h:mm A"), label: end.format("h:mm A") },
        };
      }

      return {
        dayOfWeek: hop.dayOfWeek,
        start: { value: "Closed", label: "Closed" },
        end: { value: "Closed", label: "Closed" },
      };
    });
    setHoursOfOperation(hopList);
    setClosureDates(
      dropData.closureDates.map((item) => {
        return {
          date: moment(
            `${item.date.year}-${item.date.month.toString().padStart(2, "0")}-${item.date.day.toString().padStart(2, "0")}`,
            "YYYY-MM-DD"
          ).toDate(),
          text: item.reason,
        };
      })
    );

    let addr = Object.assign({}, dropData.address);
    Object.assign(addr, dropData.location);
    setAddressData(addr);
    setLocationServices(
      dropData.services.map((s) => {
        return {
          value: s.id,
          label: s.title,
        };
      })
    );
  };

  const getServices = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/services`);
    const data = res.data;
    const serviceItems = data.map((service) => ({
      value: service.id,
      label: service.title,
    }));

    setServices(serviceItems);
  };

  const saveDropOff = () => {
    const isUpdate = dropLocationId && dropLocationId !== "new";

    const data = {
      id: isUpdate ? dropLocationId : null,
      name: name,
      address: {
        province: addressData.province || addressData.region,
        city: addressData.city || addressData.place,
        street: addressData.street,
        postalCode: addressData.postalCode || addressData.postCode,
      },
      location: {
        latitude: addressData.latitude,
        longitude: addressData.longitude,
      },
      hoursOfOperations: hoursOfOperations
        .filter((s) => s.start.value !== "Closed" && s.end.value !== "Closed" && s.start.value && s.end.value)
        .map((item) => {
          let start = moment(item.start.value, ["h:mm A"]);
          let end = moment(item.end.value, ["h:mm A"]);

          return {
            dayOfWeek: item.dayOfWeek,
            startTime: {
              hour: parseInt(start.format("H")),
              minute: parseInt(start.format("m")),
            },
            endTime: {
              hour: parseInt(end.format("H")),
              minute: parseInt(end.format("m")),
            },
          };
        }),

      services: locationServices.map((service) => service.value),
      closureDates: closureDates.map((cDate) => {
        return {
          date: {
            year: cDate.date.getFullYear(),
            month: cDate.date.getMonth() + 1,
            day: cDate.date.getDate(),
          },
          reason: cDate.text,
        };
      }),
    };

    setLoading(true);

    const requestOptions = {
      method: isUpdate ? "PUT" : "POST",
      url: isUpdate
        ? `${process.env.REACT_APP_API_URL}/dropofflocations/${dropLocationId}`
        : `${process.env.REACT_APP_API_URL}/dropofflocations`,
      data: data,
    };

    axios(requestOptions)
      .then((res) => {
        setLoading(false);
        if (isUpdate) {
          initializeData(res.data);
        }

        Swal.fire({
          title: "Drop Off Location has been created successfully.",
          icon: "success",
          showCancelButton: false,
        }).then(() => {
          if (!isUpdate) {
            props.history.push("/admin/drop-off");
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Drop Off Location Error", error, "error");
      });
  };

  const getAddress = (addr) => {};

  const getAddressData = (addrData) => {
    setAddressData(addrData);
  };

  const formatAddress = (address) => {
    if (!address) {
      return "";
    }
    return `${address.street}, ${address.city || address.place}, ${address.postalCode || address.postCode}, ${
      address.province || address.region
    }`;
  };

  const onChangeHoursOfOperation = (data) => {
    setHoursOfOperation((list) =>
      list.map((h) => {
        if (h.dayOfWeek === data.dayOfWeek) {
          return {
            dayOfWeek: h.dayOfWeek,
            start: data.startTime,
            end: data.endTime,
          };
        }
        return h;
      })
    );
  };

  return (
    <div className="flex flex-col main-content">
      <Button
        simple
        icon
        style={{ padding: "0", marginBottom: "12px", marginRight: "auto" }}
        onClick={() => {
          props.history.go(-1);
        }}
      >
        <Svg name="chevron-left" className="w-6 h-6" />
        <span className="text-bold">Go Back</span>
      </Button>
      <div className="grid grid-2col gap-16 items-start">
        <Card
          content={
            <div className="flex flex-col gap-16">
              <FormGroup style={{ marginBottom: "0" }}>
                <ControlLabel>
                  Drop Off Location Name: <span className="star">*</span>
                </ControlLabel>
                <FormControl
                  disabled={loading}
                  required
                  type="text"
                  name="name"
                  maxLength="100"
                  value={name}
                  placeholder="Enter name for this location"
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </FormGroup>

              <div className="separator horizontal"></div>

              <div className="flex items-start">
                <div className="form-group" style={{ paddingLeft: 0, marginBottom: 0 }}>
                  <ControlLabel>
                    Address: <span className="star">*</span>
                  </ControlLabel>
                  {addressData && <div className="text-lg main-text">{formatAddress(addressData)}</div>}
                  {!addressData && <div className="sub-text">No address provided</div>}
                </div>

                {(!dropLocationId || dropLocationId === "new") && (
                  <Button
                    disabled={loading}
                    bsStyle="danger"
                    style={{ marginLeft: "auto" }}
                    fill
                    onClick={() => setLocationModalOpen(true)}
                  >
                    <Svg name="pin" className="w-4 h-4" />
                    {addressData ? "Edit Address" : "Select Address"}
                  </Button>
                )}
              </div>

              <div className="separator horizontal"></div>

              <div className="form-group" style={{ marginBottom: "0" }}>
                <ControlLabel>Supported Services:</ControlLabel>
                <Select
                  isDisabled={loading}
                  required
                  isMulti
                  options={services}
                  value={locationServices}
                  onChange={(value) => setLocationServices(value)}
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Select Services"
                  name="multipleSelect"
                />
              </div>

              <div className="separator horizontal"></div>

              <div className="flex flex-col gap-16">
                <div className="form-group" style={{ marginBottom: "0" }}>
                  <ControlLabel>CLOSURE DATES:</ControlLabel>
                </div>

                <DatesListControl
                  disabled={loading}
                  items={closureDates}
                  onChange={(items) => {
                    setClosureDates(items);
                  }}
                  textLabel="REASON"
                />

                <div className="separator horizontal"></div>

                <Button
                  bsStyle="danger"
                  fill
                  loading={loading}
                  type="submit"
                  style={{ marginLeft: "auto", marginTop: "12px" }}
                  onClick={() => saveDropOff()}
                  disabled={
                    loading ||
                    !name ||
                    !addressData ||
                    locationServices.length === 0 ||
                    hoursOfOperations.filter((h) => h.start.value && h.end.value) === 0
                  }
                >
                  Save Drop Off Location
                </Button>

                <AddLocationModal
                  show={locationModalOpen}
                  onHide={() => setLocationModalOpen(false)}
                  address={formatAddress(addressData)}
                  onSelectAddress={getAddress}
                  onSelectAddressData={getAddressData}
                />
              </div>
            </div>
          }
        />

        <Card
          title="Hours of operation"
          content={
            <div>
              {hoursOfOperations.map((item, key) => (
                <OpenCloseTime
                  key={key}
                  changeWeekTime={(data) => onChangeHoursOfOperation(data)}
                  Day={item.dayOfWeek}
                  Open={item.start}
                  Close={item.end}
                />
              ))}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default CreateDropOffLocation;
