//
// //
// // // For notifications
// //
//
const defaultWidth = window.screen.width > 768 ? window.screen.width / 3 : window.screen.width;

const style = {
  Wrapper: {},
  Containers: {
    DefaultStyle: {
      position: "fixed",
      width: "auto",
      padding: "10px 10px 10px 20px",
      zIndex: 9998,
      WebkitBoxSizing: "",
      MozBoxSizing: "",
      boxSizing: "",
      display: "inline-block",
      border: "0",
      fontSize: "14px",
      WebkitFontSmoothing: "antialiased",
      fontFamily:
        "Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
      fontWeight: "400",
      color: "#FFFFFF",
      height: "auto",
    },

    tl: {
      top: "0px",
      bottom: "auto",
      left: "0px",
      right: "auto",
    },

    tr: {
      top: "0px",
      bottom: "auto",
      left: "auto",
      right: "0px",
    },

    tc: {
      top: "0px",
      bottom: "auto",
      margin: "0 auto",
      left: "50%",
      marginLeft: -(defaultWidth / 2),
    },

    bl: {
      top: "auto",
      bottom: "0px",
      left: "0px",
      right: "auto",
    },

    br: {
      top: "auto",
      bottom: "0px",
      left: "auto",
      right: "0px",
    },

    bc: {
      top: "auto",
      bottom: "0px",
      margin: "0 auto",
      left: "50%",
      marginLeft: -(defaultWidth / 2),
    },
  },

  NotificationItem: {
    DefaultStyle: {
      position: "relative",
      width: "100%",
      cursor: "pointer",
      borderRadius: "6px",
      fontSize: "14px",
      margin: "72px 0 0",
      padding: "12px",
      display: "flex",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
      opacity: 0,
      transition: "all 0.5s ease-in-out",
      WebkitTransform: "translate3d(0, 0, 0)",
      transform: "translate3d(0, 0, 0)",
      willChange: "transform, opacity",
      height: "auto",

      isHidden: {
        opacity: 0,
      },

      isVisible: {
        opacity: 1,
      },
    },

    success: {
      borderTop: 0,
      color: "#323c46",
      backgroundColor: "#edfaf1",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    },

    error: {
      borderTop: 0,
      color: "#323c46",
      backgroundColor: "#fcede9",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    },

    warning: {
      borderTop: 0,
      color: "#323c46",
      backgroundColor: "#fef7ea",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    },

    info: {
      borderTop: 0,
      color: "#323c46",
      backgroundColor: "#e8f1fc",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    },
  },

  Title: {
    DefaultStyle: {
      fontSize: "14px",
      margin: "0",
      padding: 0,
      fontWeight: 600,
      color: "#323c46",
      display: "block",
    },

    success: {
      color: "#3ab35a",
    },

    error: {
      color: "#ea4e2c",
    },

    warning: {
      color: "#ee9500",
    },

    info: {
      color: "#006ce5",
    },
  },

  MessageWrapper: {
    DefaultStyle: {
      display: "inline-block",
      marginLeft: "0px",
      padding: "0",
      color: "#323c46",
      maxWidth: "88%",
    },
  },

  Dismiss: {
    DefaultStyle: {
      fontFamily: "inherit",
      fontSize: "21px",
      color: "#69737d",
      float: "right",
      position: "absolute",
      right: "12px",
      top: "12px",
      backgroundColor: "#FFFFFF",
      display: "block",
      borderRadius: "50%",
      opacity: ".95",
      lineHeight: "11px",
      width: "25px",
      height: "25px",
      outline: "0 !important",
      textAlign: "center",
      padding: "6px 3px 3px 3px",
      fontWeight: "300",
      marginLeft: "65px",
    },

    success: {
      backgroundColor: "#fff",
    },

    error: {
      backgroundColor: "#fff",
    },

    warning: {
      backgroundColor: "#fff",
    },

    info: {
      backgroundColor: "#fff",
    },
  },

  Action: {
    DefaultStyle: {
      background: "#ffffff",
      borderRadius: "6px",
      padding: "6px 20px",
      fontWeight: "600",
      margin: "0",
      border: 0,
    },

    success: {
      backgroundColor: "#3ab35a",
      color: "#fff",
    },

    error: {
      backgroundColor: "#ea4e2c",
      color: "#fff",
    },

    warning: {
      backgroundColor: "#ee9500",
      color: "#fff",
    },

    info: {
      backgroundColor: "#006ce5",
      color: "#fff",
    },
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },
};

module.exports = {
  style, // For notifications (App container and Notifications view)
};
