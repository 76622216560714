import React, { useState } from "react";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import { parseError } from "api/common.js";
import Svg from "components/Svg/Svg.jsx";
import Swal from "sweetalert2";
import PlaceholderImage from "../../../assets/img/placeholder.svg";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import { useEffect } from "react";
import ServicesGridView from "./ServicesGrid";
import { useHistory } from "react-router";
import Loader from "views/Components/Loader/Loader";

function SortableFolderItem(props) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
    disabled: props.disable || isOpen,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    if (props.onCollapseChanged) {
      props.onCollapseChanged(props.folder.id, isOpen);
    }
  }, [isOpen]);

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="service-drag-container service-folder-container"
    >
      <div
        className="service-card service-folder"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen((prev) => !prev);
        }}
      >
        <img
          src={
            props.folder.picture && props.folder.picture.url
              ? props.folder.picture.url
              : PlaceholderImage
          }
          alt={props.folder.title || ""}
        />
        <div className="flex flex-col gap-2">
          <span className="service-title">{props.folder.title}</span>
          <span className="service-price">{props.folder.services.length} service(s)</span>
        </div>

        <div className="flex items-center gap-12 ml-auto">
          <div className="flex flex-col gap-6">
            <Button
              outline="true"
              bsStyle="default"
              style={{ padding: "3px 5px" }}
              title="Edit Folder"
              onClick={() => {
                history.push(`/admin/services/folder/${props.folder.id}`);
              }}
            >
              <Svg name="edit" className="w-4 h-4" />
            </Button>
            <Button
              bsStyle="danger"
              outline="true"
              style={{ padding: "3px 5px" }}
              title="Delete Folder"
              onClick={() => {
                props.onDelete(props.folder);
              }}
            >
              <Svg name="trash" className="w-4 h-4" />
            </Button>
          </div>
          <Button
            bsStyle="danger"
            style={{ padding: "3px 5px", border: "none" }}
            title="Expand Folder"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen((prev) => !prev);
            }}
          >
            <Svg name={isOpen ? "chevron-down" : "chevron-up"} className="w-4 h-4" />
          </Button>
        </div>
      </div>

      <div className={`service-folder-content ${isOpen ? "open" : "closed"}`}>
        <ServicesGridView
          onEdit={(svc) => props.onEditService(svc, props.folder)}
          onDelete={(svc) => props.onDeleteService(svc, props.folder)}
          services={props.folder.services || []}
          folder={props.folder}
        />
      </div>
    </div>
  );
}

const ServicesView = (props) => {
  const [folders, setFolders] = useState([]);
  const [openFolders, setOpenFolders] = useState([]);
  const [serviceWithoutFolder, setServicesWithoutFolder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  useEffect(() => {
    getServiceFolders();
    getServiceWithoutFolders();
  }, []);

  const getServiceFolders = () => {
    setLoading(true);
    setError(null);
    setFolders([]);

    axios
      .get(`${process.env.REACT_APP_API_URL}/services/folders`)
      .then((res) => {
        setFolders(res.data);
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const getServiceWithoutFolders = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/services/folders/services-no-folder`)
      .then((res) => {
        setServicesWithoutFolder(res.data);
      })
      .catch((err) => {
        parseError(err);
      });
  };

  const deleteService = (item, folder) => {
    Swal.fire({
      title: "Delete Service",
      text: `Are you sure you want to delete '${item.title}' service? This service will be unavailable for new bookings after the deletion. This action cannot be undone.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Service",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/services/${item.id}`)
          .then(() => {
            if (folder) {
              setFolders((prev) =>
                prev.map((f) => {
                  if (f.services.some((s) => s.id === item.id)) {
                    return Object.assign({}, f, {
                      services: f.services.filter((s) => s.id !== item.id),
                    });
                  }
                  return f;
                })
              );
            } else {
              setServicesWithoutFolder((prev) => prev.filter((s) => s.id !== item.id));
            }

            Swal.fire({
              title: `Service '${item.title}' has been deleted successfully.`,
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            Swal.fire("Delete Error", error, "error");
          });
      }
    });
  };

  const deleteServiceFolder = (folder) => {
    Swal.fire({
      title: "Delete Service Folder",
      text: `Are you sure you want to delete '${folder.title}' folder? All services from that folder (if any) will become the services without any folder. This action cannot be undone.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Service Folder",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/services/folders/${folder.id}`)
          .then(() => {
            setFolders((prev) => prev.filter((f) => f.id !== folder.id));
            getServiceWithoutFolders();

            Swal.fire({
              title: `Service folder '${folder.title}' has been deleted successfully.`,
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            Swal.fire("Delete Folder Error", error, "error");
          });
      }
    });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setFolders((items) => {
        const oldIndex = items.findIndex((s) => s.id === active.id);
        const newIndex = items.findIndex((s) => s.id === over.id);

        let orderedList = arrayMove(items, oldIndex, newIndex);
        updateOrder(orderedList);
        return orderedList;
      });
    }
  };

  const updateOrder = (folders) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/services/folders/order`, {
        folders: Object.fromEntries(folders.map((s) => [`SF#${s.id}`, folders.indexOf(s) + 1])),
      })
      .then(() => {})
      .catch((err) => {
        let error = parseError(err);
        Swal.fire("Order Update Error", error, "error");
      });
  };

  return (
    <div className="main-content flex flex-col gap-16" style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-start gap-6">
            <div className="flex flex-col gap-6">
              <div className="title">Services</div>
              <span className="sub-text text-xs" style={{ lineHeight: "18px" }}>
                Please note that the empty folders are not being displayed on the web site or inside
                the mobile apps.
              </span>
            </div>

            <div className="flex items-start ml-auto gap-12">
              <Button
                bsStyle="danger"
                outline="true"
                onClick={() => {
                  props.history.push("/admin/services/folder/new");
                }}
              >
                <Svg name="folder-add" className="w-4 h-4" />
                New Folder
              </Button>
              <Button
                bsStyle="danger"
                fill
                onClick={() => {
                  props.history.push("/admin/services/new");
                }}
              >
                <Svg name="plus" className="w-4 h-4" />
                New Service
              </Button>
            </div>
          </div>
        }
        content={
          <div className="d-flex flex-col gap-16" style={{ marginTop: "12px" }}>
            {loading && (
              <div
                className="flex flex-col items-center justify-center"
                style={{ paddingBottom: "48px" }}
              >
                <Loader />
                <p className="text-xs text-gray-400 mt-6" style={{ color: "var(--theme-color)" }}>
                  Loading services...
                </p>
              </div>
            )}

            {error && (
              <div
                className="error-alert"
                style={{ margin: "32px auto", padding: "0.6rem", maxWidth: "500px" }}
              >
                {error}
              </div>
            )}

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToParentElement]}
            >
              <SortableContext items={folders} strategy={rectSortingStrategy}>
                {folders.map((folder) => (
                  <SortableFolderItem
                    key={folder.id}
                    id={folder.id}
                    folder={folder}
                    disable={openFolders.length > 0}
                    index={folders.findIndex((s) => s.id === folder.id) + 1}
                    onCollapseChanged={(id, isOpen) => {
                      setOpenFolders((prev) =>
                        isOpen ? prev.concat(id) : prev.filter((s) => s !== id)
                      );
                    }}
                    onDelete={(f) => deleteServiceFolder(f)}
                    onEditService={(svc, folder) => {
                      props.history.push(`/admin/services/${svc.id}`);
                    }}
                    onDeleteService={(svc, folder) => {
                      deleteService(svc, folder);
                    }}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>
        }
      />

      {serviceWithoutFolder.length > 0 && (
        <Card
          title={
            <div className="d-flex flex-col gap-6">
              <div className="title">Other Services (No Folder)</div>
              <span className="sub-text text-xs" style={{ lineHeight: "18px" }}>
                These services are not included in any of the folders and therefore not visible on
                the app or on the web site. Please include these services in any folder to show them
                within the app or on the web site.
              </span>
            </div>
          }
          content={
            <div className="services-grid" style={{ marginTop: "12px" }}>
              {serviceWithoutFolder.map((service) => (
                <div className="service-card" key={service.id}>
                  <img
                    src={
                      service.picture && service.picture.url
                        ? service.picture.url
                        : PlaceholderImage
                    }
                    alt={service.title || ""}
                  />
                  <div className="flex flex-col gap-2 overflow-hidden">
                    <span className="service-title">{service.title}</span>
                    <span className="service-price">{`$${new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      signDisplay: "never",
                    }).format(service.price > 0 ? service.price / 100.0 : 0)}`}</span>
                  </div>

                  <div className="flex flex-col items-center gap-4 ml-auto">
                    <Button
                      bsStyle="default"
                      outline="true"
                      style={{ padding: "2px 4px" }}
                      title="View or Edit Service"
                      onClick={() => {
                        props.history.push(`/admin/services/${service.id}`);
                      }}
                    >
                      <Svg name="edit" className="w-4 h-4" />
                    </Button>
                    <Button
                      bsStyle="danger"
                      outline="true"
                      className="btn-error"
                      title="Delete Service"
                      style={{ padding: "2px 4px" }}
                      onClick={() => {
                        deleteService(service);
                      }}
                    >
                      <Svg name="trash" className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          }
        />
      )}
    </div>
  );
};

export default ServicesView;
