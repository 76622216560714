import React, { Component } from "react";
import axios from "axios";
import { ControlLabel, Row, Col } from "react-bootstrap";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import moment from "moment";
import Svg from "../../../components/Svg/Svg";
import Swal from "sweetalert2";
import { parseError } from "api/common.js";
import Select from "react-select";
import AddLocationModal from "../../Components/AutocompleteWithMap/AddLocationModal.jsx";
import PatientAppointments from "../Appointments/AppointmentsTable.jsx";
import Datetime from "react-datetime";
import { dateFormat } from "api/common";

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientId: "",
      patient: [],
      gender: { value: "" },
      genders: [],
      dateOfBirth: null,
      loading: false,
      editProfileOn: false,
      phone: "",
      address: "",
      initialGender: { value: "" },
      status: "",
      firstName: "",
      lastName: "",
      email: "",
      deleted: false,
    };
  }

  componentDidMount() {
    const self = this;
    let patientId = this.props.match.params.id;

    if (patientId) {
      this.setState({ patientId: patientId, loading: true });
      axios
        .get(`${process.env.REACT_APP_API_URL}/account/${patientId}`)
        .then((res) => {
          this.setState({
            patient: res.data,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            phone: res.data.phoneNumber,
            email: res.data.email,
            type: res.data.type,
            status: res.data.status,
            deleted: res.data.deletedAt !== undefined,
            gender: { value: res.data.gender },
            dateOfBirth: moment(
              `${res.data.dateOfBirth.month}/${res.data.dateOfBirth.day}/${res.data.dateOfBirth.year}`,
              "M/D/YYYY"
            ).toDate(),
          });

          axios
            .get(`${process.env.REACT_APP_API_URL}/account/genders`)
            .then((res) => {
              var genders = res.data.map((s) => {
                return { label: s.value, value: s.key };
              });

              this.setState({
                genders: genders,
                gender: self.state.gender
                  ? genders.find((g) => g.value === self.state.gender.value)
                  : self.state.gender,
              });
            })
            .catch((err) => {
              let error = parseError(err);
            });

          this.setState({
            initialGender: { value: res.data.gender },
            address: `${res.data.address.street} ${res.data.address.city}, ${res.data.address.province} ${res.data.address.postalCode}`,
            province: res.data.address.province,
            postalCode: res.data.address.postalCode,
            city: res.data.address.city,
            street: res.data.address.street,
            latitude: res.data.location ? res.data.location.latitude : 0,
            longitude: res.data.location ? res.data.location.longitude : 0,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  updateUserInfo = () => {
    const self = this;
    this.setState({ loading: true });

    const data = {
      id: self.state.patientId,
      picture: {
        fileName: "",
      },
      firstName: self.state.firstName,
      lastName: self.state.lastName,
      phoneNumber: self.state.phone,
      address: {
        province: self.state.province,
        city: self.state.city,
        street: self.state.street,
        postalCode: self.state.postalCode,
      },
      gender: self.state.gender.value,
      dateOfBirth: {
        year: self.state.dateOfBirth.getFullYear(),
        month: self.state.dateOfBirth.getMonth() + 1,
        day: self.state.dateOfBirth.getDate(),
      },
      location: {
        latitude: self.state.latitude,
        longitude: self.state.longitude,
      },
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/Account`, data)
      .then((res) => {
        self.setState({
          loading: false,
          editProfileOn: !self.state.editProfileOn,
          patient: {
            firstName: data.firstName,
            lastName: data.lastName,
          },
          address: `${data.address.street}  ${data.address.city},  ${data.address.province} ${data.address.postalCode}`,
          initialGender: data.gender,
        });

        Swal.fire("User information has been updated successfully.", "", "success");
      })
      .catch((err) => {
        let error = parseError(err);
        self.cancelChanges();
        Swal.fire(error, "", "error");
      });
  };

  disableAccount = async () => {
    const self = this;

    Swal.fire({
      title: "Are you sure you want to disable this account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: "Disable Account",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ loading: true });

        axios
          .delete(`${process.env.REACT_APP_API_URL}/Account/${self.state.patientId}/disable`)
          .then((res) => {
            self.setState({ status: "disabled", loading: false });
            Swal.fire({
              title: "Account has been disabled.",
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            self.setState({ loading: false });
            Swal.fire(error, "", "error");
          });
      }
    });
  };

  enableAccount = async () => {
    const self = this;

    Swal.fire({
      title: "Are you sure you want to enable this account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: "Enable Account",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ loading: true });

        axios
          .put(`${process.env.REACT_APP_API_URL}/Account/${self.state.patientId}/enable`)
          .then((res) => {
            Swal.fire({
              title: "Account has been enabled.",
              icon: "success",
              showCancelButton: false,
            }).then(() => {
              self.setState({ status: "enabled", loading: false });
            });
          })
          .catch((err) => {
            let error = parseError(err);
            self.setState({ loading: false });
            Swal.fire(error, "", "error");
          });
      }
    });
  };

  getAddress = (value) => {
    this.setState({ address: value });
  };

  getAddressData = (value) => {
    this.setState({
      addressData: value,
      province: value.province,
      city: value.city,
      street: value.street,
      postalCode: value.postalCode,
      latitude: value.latitude,
      longitude: value.longitude,
    });
  };

  changeDate = (event) => {
    this.setState({ dateOfBirth: event.toDate() });
  };

  disableFutureDt = (current) => {
    return current.isBefore(moment()) && current.isAfter(moment("1-1-1800"));
  };

  cancelChanges = () => {
    this.setState({
      loading: false,
      firstName: this.state.patient.firstName,
      lastName: this.state.patient.lastName,
      latitude: this.state.patient.location.latitude,
      longitude: this.state.patient.location.longitude,
      gender: this.state.genders.find((s) => s.value === this.state.initialGender.value),
      address: `${this.state.patient.address.street} ${this.state.patient.address.city}, ${this.state.patient.address.province} ${this.state.patient.address.postalCode}`,
      dateOfBirth: moment(
        `${this.state.patient.dateOfBirth.month}/${this.state.patient.dateOfBirth.day}/${this.state.patient.dateOfBirth.year}`,
        "M/D/YYYY"
      ).toDate(),
    });

    this.setState({ editProfileOn: false });
  };

  render() {
    return (
      <div className="main-content flex flex-col gap-24">
        <Button
          simple
          icon
          style={{ padding: "0", marginBottom: "-12px", marginRight: "auto" }}
          onClick={() => {
            this.props.history.go(-1);
          }}
        >
          <Svg name="chevron-left" className="w-6 h-6" />
          <span className="text-bold">Go Back</span>
        </Button>

        <Card
          content={
            <div className="flex flex-col">
              <div className="flex flex-col items-center gap-12" style={{ marginTop: "32px" }}>
                {!this.state.patient && (
                  <div className="sub-text text-regular">No user selected</div>
                )}
                <Svg name="profile" style={{ width: "72px", height: "72px" }} />
                {this.state.patient && (
                  <div
                    style={{ padding: "8px 12px" }}
                    className="flex items-center flex-col gap-12"
                  >
                    <div className="text-xxl text-bold">
                      {this.state.patient.firstName} {this.state.patient.lastName}
                    </div>
                    <div className="sub-text text-xs text-regular" style={{ marginTop: "-6px" }}>
                      {this.state.email}
                      {" • "}
                      {this.state.phone}
                    </div>

                    <div className="flex gap-12">
                      <div
                        className={`badge ${this.state.status == "enabled" ? "success" : "error"}`}
                      >
                        {this.state.deleted
                          ? "Account Deleted"
                          : this.state.status == "enabled"
                          ? "Enabled"
                          : "Disabled"}
                      </div>

                      <div className="sub-text text-xs text-regular">
                        Account type:{" "}
                        <span style={{ textTransform: "capitalize" }}> {this.state.type}</span>
                      </div>
                    </div>
                    {!this.state.deleted && !this.state.loading && (
                      <div className="flex gap-10 item-center" style={{ marginTop: "16px" }}>
                        <Button
                          disabled={this.state.loading}
                          bsStyle="danger"
                          outline="true"
                          onClick={
                            this.state.status == "enabled"
                              ? () => this.disableAccount()
                              : () => this.enableAccount()
                          }
                        >
                          {this.state.status == "enabled" ? "Disable Account" : "Enable Account"}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          }
        />

        <Card
          title={
            <div className="flex items-center">
              <div className="title">Profile Information</div>
              {!this.state.loading && !this.state.deleted && (
                <Button
                  bsStyle="danger"
                  disabled={this.state.editProfileOn || this.state.loading}
                  fill
                  style={{ marginLeft: "auto" }}
                  onClick={(e) => this.setState({ editProfileOn: true })}
                >
                  <Svg name="edit" className="w-4 h-4" />
                  Edit
                </Button>
              )}
            </div>
          }
          content={
            <div style={{ paddingTop: 15 }}>
              <FormInputs
                ncols={["col-md-12"]}
                proprieties={[
                  {
                    label: "First name",
                    type: "text",
                    maxLength: "32",
                    bsClass: "form-control",
                    placeholder: "First Name",
                    value: this.state.firstName,
                    onChange: (e) =>
                      this.setState({ firstName: e.target.value.replace(/[^a-z ]/gi, "") }),
                    disabled: !this.state.editProfileOn,
                  },
                ]}
              />
              <FormInputs
                ncols={["col-md-12"]}
                proprieties={[
                  {
                    label: "Last name",
                    type: "text",
                    maxLength: "32",
                    bsClass: "form-control",
                    placeholder: "Last Name",
                    value: this.state.lastName,
                    onChange: (e) =>
                      this.setState({ lastName: e.target.value.replace(/[^a-z ]/gi, "") }),
                    disabled: !this.state.editProfileOn,
                  },
                ]}
              />
              <div className="form-group">
                <ControlLabel>Gender:</ControlLabel>
                <Select
                  name="gender-select"
                  isDisabled={!this.state.editProfileOn}
                  isClearable={false}
                  className="react-select react-select-icon"
                  isSearchable={true}
                  placeholder="Select Gender"
                  noOptionsMessage={(inp) => "No Genders Loaded"}
                  isMulti={false}
                  value={this.state.gender}
                  onChange={(opt) => this.setState({ gender: opt })}
                  options={this.state.genders}
                  formatGroupLabel={(data) => (
                    <div className="flex items-center text-theme font-semibold">{data.label}</div>
                  )}
                />
              </div>
              <div className="form-group">
                <ControlLabel>Date of Birth:</ControlLabel>
                <Datetime
                  dateFormat={dateFormat}
                  timeFormat={false}
                  isValidDate={this.disableFutureDt}
                  value={this.state.dateOfBirth}
                  closeOnSelect={true}
                  onChange={this.changeDate}
                  inputProps={{ disabled: !this.state.editProfileOn, placeholder: "" }}
                />
              </div>
              <Row style={{ marginBottom: 10 }}>
                <Col md={12} className="form-group flex">
                  <div className="form-group">
                    <ControlLabel className="title">Address</ControlLabel>
                    <div>{this.state.address} </div>
                  </div>
                  {this.state.editProfileOn && (
                    <div md={12} style={{ marginLeft: "auto" }}>
                      <Button
                        disabled={!this.state.editProfileOn}
                        bsStyle="danger"
                        outline
                        onClick={() => this.setState({ AddLocationModalOpen: true })}
                      >
                        {this.state.address ? "Edit Address" : "Add Location"}
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
              {this.state.editProfileOn ? (
                <Row className="flex items-center gap-10">
                  <Button
                    disabled={this.state.loading}
                    bsStyle="danger"
                    outline="true"
                    style={{ marginLeft: "auto" }}
                    onClick={() => this.cancelChanges()}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={this.state.loading}
                    loading={this.state.loading}
                    bsStyle="danger"
                    fill
                    onClick={() => this.updateUserInfo()}
                  >
                    Save Changes
                  </Button>
                </Row>
              ) : null}
            </div>
          }
        />

        {this.state.patientId && (
          <Card
            title="Booked Appointments"
            content={<PatientAppointments pageSize={8} patientId={this.state.patientId} />}
          />
        )}

        <AddLocationModal
          show={this.state.AddLocationModalOpen}
          onHide={() => this.setState({ AddLocationModalOpen: false })}
          address={this.state.address}
          onSelectAddress={this.getAddress}
          onSelectAddressData={this.getAddressData}
        />
      </div>
    );
  }
}

export default UserDetail;
