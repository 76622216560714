import React, { Component } from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import Select from "react-select";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import Swal from "sweetalert2";
import showPass from "assets/img/show-icon.svg";
import hidePass from "assets/img/hide-icon.svg";
import Svg from "components/Svg/Svg.jsx";
import { getUser, getSession } from "api/auth";
import { parseError } from "api/common.js";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: getUser(),

      firstName: "",
      lastName: "",
      email: "",
      emailError: null,
      editProfileOn: false,
      initialFirstName: "",
      initialLastName: "",
      initialEmail: "",
      serviceCentre: [],

      currentPassword: "",
      password: "",
      cfpassword: "",
      passwordError: null,
      cfpasswordError: null,
      currentPasswordError: null,
      editPasswordOn: true,
      disabled: true,
      uppercaseValid: true,
      specialCharValid: true,
      lowercaseValid: true,
      numberValid: true,
      oldType: "password",
      newType: "password",
      confirmType: "password",
      expireAt: {},
      gender: { value: "" },
      genders: [],
      initialGender: { value: "" },
      initialPhone: "",
      phone: "",
      adminId: "",
      showCodeInput: false,
      sessionId: "",
      smsCode: "",
      session: getSession(),
      codeRequired: false,
      phoneCode: "",
      phoneError: null,
    };
  }

  componentDidMount() {
    const self = this;

    axios
      .get(`${process.env.REACT_APP_API_URL}/Admin/Information`)
      .then((response) => {
        this.setState({ sessionId: this.state.session.accessToken });
        this.setState({ adminId: response.data.id });
        this.setState({ firstName: response.data.firstName });
        this.setState({ initialFirstName: response.data.firstName });
        this.setState({ lastName: response.data.lastName });
        this.setState({ initialLastName: response.data.lastName });
        this.setState({ email: response.data.email });
        this.setState({ initialEmail: response.data.email });
        this.setState({ phone: response.data.phone });
        this.setState({ initialPhone: response.data.phone });
        this.setState({ gender: { value: response.data.gender } });
        axios
          .get(`${process.env.REACT_APP_API_URL}/account/genders`)
          .then((res) => {
            var genders = res.data.map((s) => {
              return { label: s.value, value: s.key };
            });

            this.setState({
              genders: genders,
              gender: self.state.gender ? genders.find((g) => g.value === self.state.gender.value) : self.state.gender,
            });
          })
          .catch((err) => {
            parseError(err);
          });
        this.setState({ initialGender: { value: response.data.gender } });
        this.setState({
          serviceCentre: response.data.serviceCentres.map((centre) => {
            return centre.name;
          }),
        });
      })
      .catch((err) => {
        parseError(err);
      });
  }

  confirmPhone = () => {
    const self = this;
    this.setState({ loading: true });

    if (this.state.phoneCode) {
      const data = {
        phoneNumber: self.state.phone,
        code: self.state.phoneCode,
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/Account/ConfirmPhone`, data)
        .then((res) => {
          if (res.status === 200) {
            const data = {
              phone: self.state.phone,
              firstName: self.state.firstName,
              lastName: self.state.lastName,
              gender: self.state.gender.value,
            };

            axios
              .put(`${process.env.REACT_APP_API_URL}/Admin/${this.state.adminId}`, data)
              .then(() => {
                self.setState({
                  loading: false,
                  editProfileOn: !self.state.editProfileOn,
                  initialFirstName: data.firstName,
                  initialLastName: data.lastName,
                  initialPhone: data.phone,
                  initialGender: data.gender,
                });
                Swal.fire("Profile information has been updated successfully.", "", "success").then(() => {
                  window.location.reload();
                });
              })
              .catch((err) => {
                let error = parseError(err);
                self.resetChanges();
                Swal.fire(error, "", "error");
              });
          } else {
            Swal.fire("Something went wrong. Please try again later.", "", "error");
          }
        })
        .catch((err) => {
          let error = parseError(err);
          Swal.fire(error, "", "error");
          this.setState({ loading: false });
        });
    }
  };

  handleUpdateAdmin = () => {
    if (this.state.phone !== this.state.initialPhone) {
      this.confirmPhone();
      return;
    }
    this.updateAdmin();
  };

  updateAdmin = () => {
    const self = this;
    this.setState({ loading: true });
    if (self.state.phone === self.state.initialPhone) {
      const data = {
        phone: self.state.phone,
        firstName: self.state.firstName,
        lastName: self.state.lastName,
        gender: self.state.gender.value,
      };

      axios
        .put(`${process.env.REACT_APP_API_URL}/Admin/${this.state.adminId}`, data)
        .then(() => {
          self.setState({
            loading: false,
            editProfileOn: !self.state.editProfileOn,
            initialFirstName: data.firstName,
            initialLastName: data.lastName,
            initialPhone: data.phone,
            initialGender: data.gender,
          });
          Swal.fire("Profile information has been updated successfully.", "", "success");
          window.location.reload();
        })
        .catch((err) => {
          let error = parseError(err);
          self.resetChanges();
          Swal.fire(error, "", "error");
        });
    } else {
      this.setState({ codeRequired: true });
      axios
        .post(`${process.env.REACT_APP_API_URL}/Account/SMS/${self.state.phone}`)
        .then((res) => {
          console.log("sms", res);
        })
        .catch((err) => {
          this.setState({ loading: true });
          let error = parseError(err);
          Swal.fire(error, "", "error");
        });
    }
  };

  resetChanges() {
    let self = this;
    this.setState({
      editProfileOn: false,
      firstName: this.state.initialFirstName,
      lastName: this.state.initialLastName,
      phone: this.state.initialPhone,
      gender: self.state.genders.find((s) => s.value === self.state.initialGender.value),
      phoneError: null,
    });
  }

  handleCurrentPasswordChange(event) {
    this.setState({
      currentPassword: event.target.value,
    });
    event.target.value === ""
      ? this.setState({
          currentPasswordError: <small className="text-danger">Please enter your current password.</small>,
        })
      : this.setState({ currentPasswordError: null });
  }

  // Check for an lowercase character
  checkLowercase = (password) => {
    // const pattern = /[a-z]/;
    const pattern = /[a-z]/;
    if (pattern.test(password)) {
      this.setState({ lowercaseValid: true });
    } else {
      this.setState({ lowercaseValid: false });
    }
  };
  // Check for an uppercase character
  checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      this.setState({ uppercaseValid: true });
    } else {
      this.setState({ uppercaseValid: false });
    }
  };
  // Check for special characters
  checkSpecialCharacters = (password) => {
    const pattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      this.setState({
        specialCharValid: true,
      });
    } else {
      this.setState({
        specialCharValid: false,
      });
    }
  };
  // Check for a number
  checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      this.setState({ numberValid: true });
    } else {
      this.setState({ numberValid: false });
    }
  };

  handlePasswordChange(event) {
    this.checkLowercase(event.target.value);
    this.checkUppercase(event.target.value);
    this.checkNumber(event.target.value);
    this.checkSpecialCharacters(event.target.value);
    this.setState({
      password: event.target.value,
    });
    event.target.value.length < 8 ||
    !this.state.uppercaseValid ||
    !this.state.numberValid ||
    !this.state.lowercaseValid ||
    !this.state.specialCharValid
      ? this.setState({
          passwordError: (
            <small className="text-danger">
              Make sure your password has at least one lowercase, one uppercase, one number, one special character and at least 8 characters
              long.
            </small>
          ),
        })
      : this.setState({ passwordError: null });
  }
  handleCfPasswordChange(event) {
    this.setState({
      cfpassword: event.target.value,
    });
    event.target.value !== this.state.password
      ? this.setState({
          cfpasswordError: <small className="text-danger">Passwords do not match.</small>,
        })
      : this.setState({ cfpasswordError: null });
  }
  changePassword = () => {
    const self = this;
    this.setState({ loading: true });

    const object = {
      currentPassword: self.state.currentPassword,
      newPassword: self.state.password,
      newPasswordConfirmation: self.state.cfpassword,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/Admin/ChangePassword`, object)
      .then((res) => {
        if (res.status === 202) {
          self.setState({
            loading: false,
            sessionId: res.data.session,
            showCodeInput: true,
          });
        } else {
          this.setState({ session: res.data });
          window.location.href = "/";
        }
      })
      .catch((err) => {
        let error = parseError(err);
        this.setState({ loading: false });
        Swal.fire(error, "", "error");
      });
  };
  confirm2FA = () => {
    const self = this;
    this.setState({ loading: true });
    const data = {
      currentPassword: self.state.currentPassword,
      newPassword: self.state.password,
      newPasswordConfirmation: self.state.cfpassword,
      code: self.state.smsCode,
      session: self.state.sessionId,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/Admin/ChangePassword`, data)
      .then((res) => {
        if (res.status === 200) {
          Swal.fire("Password has been changed successfully.", "", "success");
          this.setState({ loading: false });
          this.setState({ currentPassword: "" });
          this.setState({ password: "" });
          this.setState({ cfpassword: "" });
          this.setState({ smsCode: "" });
          this.setState({ showCodeInput: false });
        } else {
          Swal.fire("Something went wrong. Please try again.", "", "error");
        }
      })
      .catch((err) => {
        let error = parseError(err);
        this.setState({ loading: false });
        Swal.fire("Unable To Verify Code", error, "error");
      });
  };

  handleStep = () => {
    if (this.state.showCodeInput) {
      this.confirm2FA();
      return;
    }
    this.changePassword();
  };

  resetPassword() {
    this.setState({
      currentPassword: "",
      password: "",
      cfpassword: "",
      passwordError: "",
      cfpasswordError: "",
      currentPasswordError: "",
    });
  }

  showHide = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    let newPassType = "password";
    if (type === "old") {
      if (this.state.oldType === "password") {
        newPassType = "input";
      }
      this.setState({ oldType: newPassType });
    } else if (type === "new") {
      if (this.state.newType === "password") {
        newPassType = "input";
      }
      this.setState({ newType: newPassType });
    } else if (type === "confirm") {
      if (this.state.confirmType === "password") {
        newPassType = "input";
      }
      this.setState({ confirmType: newPassType });
    }
  };

  changeDate = (event) => {
    this.setState({ ...this.state, expireAt: event.toDate() });
  };

  render() {
    return (
      <div className="main-content">
        <div className="grid grid-2col gap-24 items-start">
          <Card
            title={
              <div className="">
                <div className="title">
                  {this.state.userData.firstName} {this.state.userData.lastName}
                </div>
                <div className="sub-text text-xs text-regular" style={{ marginTop: "-6px" }}>
                  {this.state.email}
                </div>
                <div className="sub-text text-xs text-regular" style={{ marginTop: "-6px" }}>
                  {this.state.serviceCentre}
                </div>
              </div>
            }
            content={
              <form>
                <FormInputs
                  ncols={["col-md-12"]}
                  proprieties={[
                    {
                      label: "First name",
                      type: "text",
                      maxLength: "32",
                      bsClass: "form-control",
                      placeholder: "First Name",
                      value: this.state.firstName,
                      onChange: (e) => this.setState({ firstName: e.target.value.replace(/[^a-z ]/gi, "") }),
                      disabled: !this.state.editProfileOn,
                    },
                  ]}
                />
                <FormInputs
                  ncols={["col-md-12"]}
                  proprieties={[
                    {
                      label: "Last name",
                      type: "text",
                      maxLength: "32",
                      bsClass: "form-control",
                      placeholder: "Last Name",
                      value: this.state.lastName,
                      onChange: (e) => this.setState({ lastName: e.target.value.replace(/[^a-z ]/gi, "") }),
                      disabled: !this.state.editProfileOn,
                    },
                  ]}
                />
                <div className="form-group">
                  <ControlLabel>Gender:</ControlLabel>
                  <Select
                    name="gender-select"
                    isDisabled={!this.state.editProfileOn}
                    isClearable={false}
                    className="react-select react-select-icon"
                    isSearchable={true}
                    placeholder="Select Gender"
                    noOptionsMessage={() => "No Genders Loaded"}
                    isMulti={false}
                    value={this.state.gender}
                    onChange={(opt) => this.setState({ gender: opt })}
                    options={this.state.genders}
                    formatGroupLabel={(data) => <div className="flex items-center text-theme font-semibold">{data.label}</div>}
                  />
                </div>
                <div className="form-group phone-input">
                  <ControlLabel>
                    Phone Number: <span className="star">*</span>
                  </ControlLabel>
                  <PhoneInput
                    maxLength="16"
                    disabled={!this.state.editProfileOn}
                    defaultCountry="CA"
                    extension="true"
                    value={this.state.phone}
                    onChange={(value) => {
                      this.setState({ phone: value });
                      if (value && !isPossiblePhoneNumber(value)) {
                        this.setState({
                          phoneError: <small className="text-danger">A valid Canadian or US phone number is required.</small>,
                        });
                      } else {
                        this.setState({ phoneError: null });
                      }
                    }}
                    required
                  />
                  {this.state.phoneError}
                </div>
                {this.state.codeRequired && (
                  <React.Fragment>
                    <div className="separator horizontal" style={{ margin: "16px 0" }}></div>
                    <FormGroup>
                      <ControlLabel>
                        SMS CODE: <span className="star">*</span>
                      </ControlLabel>
                      <FormControl
                        disabled={this.state.loading}
                        placeholder="Enter SMS Verification Code"
                        type="text"
                        name="phoneCode"
                        maxLength="6"
                        value={this.state.phoneCode}
                        onChange={(event) => {
                          this.setState({ phoneCode: event.target.value });
                        }}
                      />
                    </FormGroup>
                    <div className="sub-text text-xxs text-center " style={{ marginBottom: 20 }}>
                      We have sent a verification code to your phone number.
                      <br />
                      Please enter this code below to confirm your identity.
                    </div>
                  </React.Fragment>
                )}
                {this.state.editProfileOn ? (
                  <div className="flex gap-10">
                    <Button bsStyle="danger" outline onClick={() => this.resetChanges()} style={{ width: "30%" }}>
                      Cancel
                    </Button>
                    {this.state.codeRequired ? (
                      <Button
                        onClick={() => this.confirmPhone()}
                        bsStyle="danger"
                        fill
                        loading={this.state.loading}
                        style={{ width: "70%" }}
                        disabled={this.state.codeRequired && !this.state.phoneCode}
                      >
                        Verify SMS Code
                      </Button>
                    ) : (
                      <Button bsStyle="danger" fill type="button" onClick={() => this.updateAdmin()} style={{ width: "70%" }}>
                        Save Changes
                      </Button>
                    )}
                  </div>
                ) : (
                  <Button bsStyle="danger" pullRight fill onClick={() => this.setState({ editProfileOn: true })}>
                    <Svg name="edit" className="w-4 h-4" />
                    Edit Profile
                  </Button>
                )}
                <div className="clearfix" />
              </form>
            }
          />
          <Card
            title="Change Password"
            content={
              <div className="flex flex-col">
                <div className={this.state.editPasswordOn ? "displayblock" : "dispaynone"}>
                  <FormGroup style={{ position: "relative" }}>
                    <ControlLabel>
                      Current Password: <span className="star">*</span>
                    </ControlLabel>
                    <FormControl
                      type={this.state.oldType}
                      name="currentPassword"
                      value={this.state.currentPassword}
                      autoComplete="off"
                      onChange={(event) => this.handleCurrentPasswordChange(event)}
                      disabled={!this.state.editPasswordOn}
                      required
                    />
                    {this.state.oldType === "input" ? (
                      <img className="show-icon" src={hidePass} onClick={(e) => this.showHide(e, "old")} alt={"Hide Pass"} />
                    ) : (
                      <img className="show-icon" src={showPass} onClick={(e) => this.showHide(e, "old")} alt={"Show Pass"} />
                    )}
                    {this.state.currentPasswordError}
                  </FormGroup>
                  <FormGroup style={{ position: "relative" }}>
                    <ControlLabel>
                      New Password: <span className="star">*</span>
                    </ControlLabel>
                    <FormControl
                      type={this.state.newType}
                      name="password"
                      value={this.state.password}
                      autoComplete="off"
                      onChange={(event) => this.handlePasswordChange(event)}
                      disabled={!this.state.editPasswordOn}
                      required
                    />
                    {this.state.newType === "input" ? (
                      <img className="show-icon" src={hidePass} onClick={(e) => this.showHide(e, "new")} alt={"Hide Pass"} />
                    ) : (
                      <img className="show-icon" src={showPass} onClick={(e) => this.showHide(e, "new")} alt={"Show Pass"} />
                    )}
                    {this.state.passwordError}
                  </FormGroup>
                  <FormGroup style={{ position: "relative" }}>
                    <ControlLabel>
                      Confirm password: <span className="star">*</span>
                    </ControlLabel>
                    <FormControl
                      type={this.state.confirmType}
                      name="cfpassword"
                      value={this.state.cfpassword}
                      autoComplete="off"
                      onChange={(event) => this.handleCfPasswordChange(event)}
                      disabled={!this.state.editPasswordOn}
                      required
                    />
                    {this.state.confirmType === "input" ? (
                      <img className="show-icon" src={hidePass} onClick={(e) => this.showHide(e, "confirm")} alt={"Hide Pass"} />
                    ) : (
                      <img className="show-icon" src={showPass} onClick={(e) => this.showHide(e, "confirm")} alt={"Show Pass"} />
                    )}
                    {this.state.cfpasswordError}
                  </FormGroup>
                  {this.state.showCodeInput && (
                    <React.Fragment>
                      <div className="separator horizontal" style={{ margin: "16px 0" }}></div>
                      <FormGroup>
                        <ControlLabel>
                          SMS CODE: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                          placeholder="Enter SMS Verification Code"
                          type="text"
                          name="smsCode"
                          maxLength="6"
                          value={this.state.smsCode}
                          onChange={(event) => {
                            this.setState({ smsCode: event.target.value });
                          }}
                        />
                      </FormGroup>
                      <div className="sub-text text-xxs text-center">
                        We have sent a verification code to your phone number.
                        <br />
                        Please enter this code below to confirm your identity.
                      </div>
                    </React.Fragment>
                  )}
                </div>
                {this.state.editPasswordOn ? (
                  <div className="flex items-center gap-10" style={{ marginLeft: "auto" }}>
                    <Button bsStyle="danger" onClick={() => this.resetPassword()}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => this.handleStep()}
                      bsStyle="danger"
                      fill
                      loading={this.state.loading}
                      style={{ width: "70%" }}
                      disabled={
                        !this.state.currentPassword ||
                        !this.state.password ||
                        !this.state.cfpassword ||
                        this.state.password !== this.state.cfpassword
                      }
                    >
                      <div>{this.state.showCodeInput ? "Verify SMS Code" : "Change Password"}</div>
                    </Button>
                  </div>
                ) : (
                  <Button bsStyle="danger" pullRight fill onClick={() => this.setState({ editPasswordOn: true })}>
                    Change Password
                  </Button>
                )}
                <div className="clearfix" />
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

export default Settings;
