import React, { useState } from "react";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import moment from "moment";
import AddLocationModal from "../../Components/AutocompleteWithMap/AddLocationModal.jsx";

const BlockTimeModal = (props) => {
  const [notes, setNotes] = useState("");
  const [address, setAddress] = useState();
  const [locationModalOpen, setLocationModalOpen] = useState(false);

  const onBlockTime = () => {
    const obj = {
      notes: notes,
      address: address,
      slot: props.timeSlot,
    };

    props.onConfirm(obj);
    props.onClose();
  };

  const onUpdateAddress = (addrData) => {
    setAddress(addrData);
  };

  const formatAddress = (address) => {
    if (!address) {
      return "";
    }
    return `${address.street}, ${address.city || address.place}, ${
      address.postalCode || address.postCode
    }, ${address.province || address.region}`;
  };

  return (
    <Modal show={true} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Block Time Slot</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <Card
            content={
              <div className="flex flex-col">
                {props.timeSlot && (
                  <FormGroup>
                    <ControlLabel>Time Slot:</ControlLabel>
                    <FormControl
                      type="text"
                      name="block-notes"
                      value={`${moment(props.timeSlot.start).format("dddd, MMM D YYYY")}, ${moment(
                        props.timeSlot.start
                      ).format("h:mm a")} - ${moment(props.timeSlot.end).format("h:mm a")}`}
                      readOnly={true}
                    />
                  </FormGroup>
                )}

                <FormGroup>
                  <ControlLabel>Location:</ControlLabel>
                  <div className="flex items-center gap-12">
                    <FormControl
                      type="text"
                      readOnly={true}
                      name="block-location"
                      value={address ? formatAddress(address) : "Address not specified"}
                    />
                    <Button bsStyle="danger" fill onClick={() => setLocationModalOpen(true)}>
                      Select Address
                    </Button>
                  </div>
                </FormGroup>

                <FormGroup>
                  <ControlLabel>
                    Notes: <span className="star">*</span>
                  </ControlLabel>
                  <FormControl
                    type="textarea"
                    rows={4}
                    componentClass="textarea"
                    maxLength={500}
                    name="block-notes"
                    value={notes}
                    onChange={(event) => {
                      setNotes(event.target.value);
                    }}
                  />
                </FormGroup>

                <AddLocationModal
                  show={locationModalOpen}
                  onHide={() => setLocationModalOpen(false)}
                  address={formatAddress(address)}
                  onSelectAddress={(addr) => {}}
                  onSelectAddressData={onUpdateAddress}
                />
              </div>
            }
          />

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button bsStyle="danger" outline="true" onClick={() => props.onClose()}>
              Cancel
            </Button>

            <Button
              bsStyle="danger"
              fill
              onClick={() => onBlockTime()}
              disabled={!notes || notes.length === 0}
            >
              Block Time Slot
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BlockTimeModal;
