import React, { useState, useRef, useCallback } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Modal } from "react-bootstrap";
import MapGL, { Marker, NavigationControl } from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import LocationMarker from "../../../assets/img/appointment-location.png";
import Button from "components/CustomButton/CustomButton.jsx";

const AddLocationModal = (props) => {
  const [addressData, setAddressData] = useState(null);
  const [address, setAddress] = useState(props.address || null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [addressInput, setAddressInput] = useState("");
  const [viewport, setViewport] = useState({
    latitude: 51.0531,
    longitude: -114.0626,
    zoom: 12,
  });
  const mapRef = useRef();
  const geocoderContainerRef = useRef();
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);

  const setMarkerLocation = (result) => {
    let location = {
      latitude: result.center[1],
      longitude: result.center[0],
    };

    let place = result.context.find((c) => c.id.indexOf("place.") > -1);
    let postCode = result.context.find((c) => c.id.indexOf("postcode.") > -1);
    let region = result.context.find((c) => c.id.indexOf("region.") > -1);
    let street = `${result.address || ""} ${result.text}`;
    street = street.trim();
    let addressString = `${street}${street ? "," : ""} ${place ? place.text : ""}${
      place ? "," : ""
    } ${postCode.text}, ${region.text}`.trim();

    setAddressData({ place: place.text, postCode: postCode.text, region: region.text, street: street });
    setAddress(addressString);
    setAddressInput("");
    setLat(location.latitude);
    setLong(location.longitude);
  };

  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange]
  );

  const navControlStyle = {
    right: 10,
    top: 10,
  };

  const onConfirmAddress = () => {
    let location = {
      latitude: lat,
      longitude: long,
    };
    props.onSelectAddress(address, location, addressData);
    props.onHide();
  };

  return (
    <div>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Search Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <MapGL
              ref={mapRef}
              {...viewport}
              width="100%"
              height="460px"
              onViewportChange={handleViewportChange}
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
            >
              <NavigationControl style={navControlStyle} />
              <Geocoder
                mapRef={mapRef}
                onViewportChange={handleGeocoderViewportChange}
                containerRef={geocoderContainerRef}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
                position="top-left"
                placeholder="Search Address"
                className="overWrite"
                marker={false}
                onResult={(result) => setMarkerLocation(result.result)}
                inputValue={addressInput}
                countries="ca"
                language="en"
              />

              {lat && long ? (
                <Marker latitude={lat} longitude={long} offsetLeft={-20} offsetTop={-10}>
                  <img width={30} src={LocationMarker} alt="Map Marker" />
                </Marker>
              ) : null}
            </MapGL>
            <div className="flex flex-col gap-10" style={{ paddingTop: 20 }}>
              {<div className="sub-text font-semibold">{address ? address : "No address selected"}</div>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="danger" fill block onClick={onConfirmAddress} disabled={!address}>
            Confirm Address
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddLocationModal;
