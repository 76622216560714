import StatsCard from "../../../components/Card/StatsCard";
import React from "react";
import Svg from "components/Svg/Svg";

const DashboardAggregates = ({totalUsers, companyEarnings, totalAppointments}) => {
  return (
    <div className="grid grid-3col gap-16">
      <StatsCard
        bigIcon={<Svg style={{ width: "44px", height: "44px", color: "#0e56eb" }} name="users" />}
        statsText="Customers"
        statsValue={totalUsers}
      />
      <StatsCard
        bigIcon={<Svg style={{ width: "44px", height: "44px", color: "#1dd189" }} name="wallet" />}
        statsText="Earnings"
        statsValue={companyEarnings}
      />
      <StatsCard
        bigIcon={<Svg style={{ width: "44px", height: "44px", color: "#f5a623" }} name="calendar" />}
        statsText="Appointments"
        statsValue={totalAppointments}
      />
    </div>
  );
};

export default DashboardAggregates;
