import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FormGroup, ControlLabel } from "react-bootstrap";

const options = [
  { value: "Closed", label: "Closed" },
  { value: "12:00 AM", label: "12:00 AM" },
  { value: "12:30 AM", label: "12:30 AM" },
  { value: "1:00 AM", label: "1:00 AM" },
  { value: "1:30 AM", label: "1:30 AM" },
  { value: "2:00 AM", label: "2:00 AM" },
  { value: "2:30 AM", label: "2:30 AM" },
  { value: "3:00 AM", label: "3:00 AM" },
  { value: "3:30 AM", label: "3:30 AM" },
  { value: "4:00 AM", label: "4:00 AM" },
  { value: "4:30 AM", label: "4:30 AM" },
  { value: "5:00 AM", label: "5:00 AM" },
  { value: "5:30 AM", label: "5:30 AM" },
  { value: "6:00 AM", label: "6:00 AM" },
  { value: "6:30 AM", label: "6:30 AM" },
  { value: "7:00 AM", label: "7:00 AM" },
  { value: "7:30 AM", label: "7:30 AM" },
  { value: "8:00 AM", label: "8:00 AM" },
  { value: "8:30 AM", label: "8:30 AM" },
  { value: "9:00 AM", label: "9:00 AM" },
  { value: "9:30 AM", label: "9:30 AM" },
  { value: "10:00 AM", label: "10:00 AM" },
  { value: "10:30 AM", label: "10:30 AM" },
  { value: "11:00 AM", label: "11:00 AM" },
  { value: "11:30 AM", label: "11:30 AM" },
  { value: "12:00 PM", label: "12:00 PM" },
  { value: "12:30 PM", label: "12:30 PM" },
  { value: "1:00 PM", label: "1:00 PM" },
  { value: "1:30 PM", label: "1:30 PM" },
  { value: "2:00 PM", label: "2:00 PM" },
  { value: "2:30 PM", label: "2:30 PM" },
  { value: "3:00 PM", label: "3:00 PM" },
  { value: "3:30 PM", label: "3:30 PM" },
  { value: "4:00 PM", label: "4:00 PM" },
  { value: "4:30 PM", label: "4:30 PM" },
  { value: "5:00 PM", label: "5:00 PM" },
  { value: "5:30 PM", label: "5:30 PM" },
  { value: "6:00 PM", label: "6:00 PM" },
  { value: "6:30 PM", label: "6:30 PM" },
  { value: "7:00 PM", label: "7:00 PM" },
  { value: "7:30 PM", label: "7:30 PM" },
  { value: "8:00 PM", label: "8:00 PM" },
  { value: "8:30 PM", label: "8:30 PM" },
  { value: "9:00 PM", label: "9:00 PM" },
  { value: "9:30 PM", label: "9:30 PM" },
  { value: "10:00 PM", label: "10:00 PM" },
  { value: "10:30 PM", label: "10:30 PM" },
  { value: "11:00 PM", label: "11:00 PM" },
  { value: "11:30 PM", label: "11:30 PM" },
  { value: "11:59 PM", label: "11:59 PM" },
];

const OpenCloseTime = (props) => {
  const [openingTime, setOpeningTime] = useState(props.Open);
  const [closingTime, setClosingTime] = useState(props.Close);

  useEffect(() => {
    if (props.startTime) {
      setOpeningTime(options.find((p) => p.value === props.startTime));
    } else {
      setOpeningTime(props.Open || options[0]);
    }

    if (props.endTime) {
      setClosingTime(options.find((p) => p.value === props.endTime));
    } else {
      setClosingTime(props.Close || options[0]);
    }
  }, [props.Open, props.Close]);

  const onChange = (startTime, endTime) => {
    if (props.changeWeekTime) {
      props.changeWeekTime({
        startTime: startTime,
        endTime: endTime,
        dayOfWeek: props.Day,
      });
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="text-semibold theme-text flex items-center main-text">{props.Day}</div>
      <div className="flex items-center gap-16">
        <FormGroup className="form-group" style={{ width: "50%" }}>
          <ControlLabel>Opening Time:</ControlLabel>
          <Select
            isDisabled={props.isDisabled}
            className="react-select secondary"
            classNamePrefix="react-select"
            name="openingTime"
            value={openingTime}
            onChange={(value) => onChange(value, closingTime)}
            options={options}
          />
        </FormGroup>

        <FormGroup className="form-group" style={{ width: "50%" }}>
          <ControlLabel>Closing Time:</ControlLabel>
          <Select
            isDisabled={props.isDisabled}
            className="react-select secondary"
            classNamePrefix="react-select"
            name="closingTime"
            value={closingTime}
            onChange={(value) => onChange(openingTime, value)}
            options={options}
          />
        </FormGroup>
      </div>

      <div className="separator horizontal" style={{ marginBottom: "12px" }}></div>
    </div>
  );
};

export default OpenCloseTime;
