import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
import Svg from "components/Svg/Svg";
import routes from "routes.js";
import axios from "axios";
import { parseError } from "api/common";

var ps;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getCollapseStates(routes),
      isWindows: navigator.platform.indexOf("Win") > -1,
      width: window.innerWidth,
      unreadNotificationExist: false,
    };
  }

  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li className={this.getCollapseInitialState(prop.views) ? "active" : ""} key={key}>
            <a
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              <Svg name={prop.icon} className="w-5 h-5" />
              <p>
                {prop.name}
                <b className={this.state[prop.state] ? "caret rotate-180" : "caret"} />
              </p>
            </a>
            <Collapse in={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} className="nav-link" activeClassName="active">
            {prop.icon ? (
              <React.Fragment>
                {prop.path === "/notifications" && this.state.unreadNotificationExist && <span className="notification-badge"></span>}
                <Svg name={prop.icon} className="w-5 h-5" />
                <p>{prop.name}</p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className="sidebar-mini">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </React.Fragment>
            )}
          </NavLink>
        </li>
      );
    });
  };

  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidUpdate() {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    this.subscribeForEvents();
    this.getUnreadNotifications();
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1 && ps) {
      ps.destroy();
    }

    this.unsubscribeFromEvents();
  }

  subscribeForEvents = () => {
    window.addEventListener("notificationsRead", this.getUnreadNotifications, true);
  };

  unsubscribeFromEvents = () => {
    window.removeEventListener("notificationsRead", this.getUnreadNotifications, true);
  };

  getUnreadNotifications = () => {
    const self = this;

    axios
      .get(`${process.env.REACT_APP_API_URL}/notifications?pageSize=${1}&onlyUnread=true`)
      .then((res) => {
        self.setState({ unreadNotificationExist: res.data && res.data.items.length > 0 });
      })
      .catch((err) => {
        parseError(err);
      });
  };

  render() {
    return (
      <div className="sidebar tap-sidebar" ref="sidebarWrapper">
        <div className="logo" style={{ boxShadow: "none" }}>
          <a href="/" title="TapLabs Admin Panel">
            <div className="main-text flex flex-col items-center " style={{ marginTop: "10px" }}>
              <Svg name="taplabs-horizontal" className="taplabs-sidebar-large-icon" alt="TapLabd Logo" />
              <Svg className="taplabs-sidebar-mini-icon" name="taplabs-icon" alt="TapLabd Logo" style={{ width: "32px", height: "32px" }} />
            </div>
          </a>
        </div>
        <div className="separator horizontal" style={{ margin: "20px 10px" }}></div>
        <div className="sidebar-wrapper">
          <ul className="nav sidebar-links">
            {this.state.width <= 992 ? <AdminNavbarLinks /> : null}
            {this.createLinks(routes)}
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
