import React, { useState, useEffect } from "react";
import { Modal, FormGroup, FormControl } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import Swal from "sweetalert2";
import "react-phone-number-input/style.css";
import { parseError } from "api/common";
import { dateTimeFormat } from "api/common";
import moment from "moment";
import Loader from "views/Components/Loader/Loader";
import Svg from "components/Svg/Svg";

const PendingAccountsModal = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateState, setUpdateState] = useState({});
  const [error, setError] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setData([]);
    setError(null);
    setLoading(false);

    if (props.show) {
      getPendingAccounts();
    }
  }, [props.show]);

  const getPendingAccounts = () => {
    setLoading(true);
    setError(null);
    setData([]);

    axios
      .get(`${process.env.REACT_APP_API_URL}/account/unconfirmed`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const onConfirm = (userAccount) => {
    let confirmState = {};
    confirmState[userAccount.id] = true;

    setUpdateState(confirmState);
    setError(null);

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/account/confirm-account/${userAccount.id}`
      )
      .then((res) => {
        setData((prev) => prev.filter((a) => a.id !== userAccount.id));
        Swal.fire(
          "Account Confirmed",
          `The account for '${userAccount.email}' has been successfully confirmed. The user can try to login into TapLab now.`,
          "success"
        );
      })
      .catch((err) => {
        let error = parseError(err);
        setError(error);
      })
      .finally(() => {
        confirmState[userAccount.id] = false;
        setUpdateState(confirmState);
      });
  };

  return (
    <Modal show={props.show} onHide={() => props.onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>
          Pending User Accounts {data.length > 0 ? `(${data.length})` : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-12">
          {loading && (
            <div className="flex flex-col items-center justify-center">
              <Loader />
              <p
                className="text-xs text-gray-400 mt-6"
                style={{ color: "var(--theme-color)" }}
              >
                Loading unconfirmed accounts...
              </p>
            </div>
          )}

          <div className="flex flex-col gap-4">
            {data.length > 0 && (
              <FormGroup>
                <FormControl
                  type="text"
                  maxLength="100"
                  placeholder="Search accounts..."
                  value={query}
                  onChange={(event) => {
                    setQuery(event.target.value);
                  }}
                />
              </FormGroup>
            )}

            {!loading &&
              data.filter(
                (s) =>
                  query.length === 0 ||
                  s.email.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                  `${s.firstName.toLowerCase()} ${s.lastName.toLowerCase()}`.indexOf(
                    query.toLowerCase()
                  ) > -1
              ).length === 0 && (
                <div className="flex flex-col items-center justify-center">
                  <p
                    className="text-xs text-gray-400 mt-12"
                    style={{ color: "var(--theme-color)" }}
                  >
                    No accounts found
                  </p>
                </div>
              )}

            <div className="flex flex-col gap-4">
              <div
                className="flex flex-col gap-6"
                style={{
                  maxHeight: "400px",
                  overflowY: "auto",
                  marginRight: "-16px",
                  paddingRight: "16px",
                }}
              >
                {data
                  .filter(
                    (s) =>
                      query.length === 0 ||
                      s.email.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                      `${s.firstName.toLowerCase()} ${s.lastName.toLowerCase()}`.indexOf(
                        query.toLowerCase()
                      ) > -1
                  )
                  .map((account) => (
                    <div
                      className="flex items-center"
                      key={account.id}
                      style={{
                        border: "1px solid #eee",
                        borderRadius: "5px",
                        padding: "10px 12px",
                      }}
                    >
                      <div className="flex flex-col gap-2">
                        <span className="main-text text-semibold">
                          {account.firstName} {account.lastName}
                        </span>
                        <span
                          className="sub-text text-xs"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {account.email} · {account.phoneNumber}
                        </span>
                        <span className="sub-text text-xs">
                          Registered on{" "}
                          {moment(account.createdAt).format(dateTimeFormat)}
                        </span>
                      </div>

                      <Button
                        disabled={loading || updateState[account.id]}
                        className="ml-auto"
                        bsStyle="danger"
                        fill
                        loading={updateState[account.id]}
                        onClick={() => {
                          onConfirm(account);
                        }}
                      >
                        <Svg name="check" className="w-4 h-4" />
                        Confirm
                      </Button>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {error && (
            <div
              className="error-alert"
              style={{ gridColumn: "1 / span 2", marginTop: "12px" }}
            >
              {error}
            </div>
          )}

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button
              disabled={loading}
              bsStyle="danger"
              outline="true"
              onClick={() => props.onClose()}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PendingAccountsModal;
