import axios from "axios";

export const login = (email, password) => {
  let data = {
    email: email,
    password: password,
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/login`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const forgotPassword = (email) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/account/forgotpassword`, `"${email}"`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const verifyCode = (sessionId, userId, code) => {
  let data = {
    userId: userId,
    code: code,
    session: sessionId,
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/verify`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export function getUserProfile() {
  return new Promise(function (resolve, reject) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/information`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export const getSession = () => {
  var sessionJson = sessionStorage.getItem("session");
  if (sessionJson) {
    try {
      return JSON.parse(sessionJson);
    } catch (err) {
      return null;
    }
  }

  return null;
};

export const setSession = (sessionData) => {
  sessionStorage.setItem("session", JSON.stringify(sessionData));
  window.dispatchEvent(new CustomEvent("profileUpdated"));
};

export const getUser = () => {
  var session = getSession();
  if (session) {
    return session.user;
  }

  return null;
};

export const setUser = (userData) => {
  var session = getSession();
  if (session) {
    session.user = userData;
    sessionStorage.setItem("session", JSON.stringify(session));
    window.dispatchEvent(new CustomEvent("profileUpdated"));
  }
};

export const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
};

export const getAccessTokenFactory = () => {
  const session = getSession();
  if (session)
    return {
      accessTokenFactory() {
        return session.accessToken;
      }
    };
  return null;
};
