import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
import routes from "routes.js";

class AdminNavbar extends Component {
  getActiveRoute = (routes) => {
    let activeRoute = "TapLabs";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  mobileSidebarToggle = (e) => {
    document.documentElement.classList.toggle("nav-open");
  };
  render() {
    return (
      <nav className={`${this.props.navbar ? "navbar-fixed" : ""} navbar navbar-default flex w-full items-center`} style={{ padding: "0" }}>
        <div className="navbar-minimize">
          <button id="minimizeSidebar" className="btn btn-default btn-fill btn-round btn-icon" onClick={this.props.handleMiniClick}>
            <i className="fa fa-ellipsis-v visible-on-sidebar-regular" />
            <i className="fa fa-navicon visible-on-sidebar-mini" />
          </button>
        </div>
        <Navbar.Header>
          <Navbar.Brand>{this.getActiveRoute(routes)}</Navbar.Brand>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>
        {window.innerWidth > 992 ? (
          <Navbar.Collapse style={{ padding: "0" }} className="flex items-center">
            <AdminNavbarLinks />
          </Navbar.Collapse>
        ) : null}
      </nav>
    );
  }
}

export default AdminNavbar;
