import React, {useEffect, useState} from 'react';
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import Svg from "../../../components/Svg/Svg";
import Button from "../../../components/CustomButton/CustomButton";

export default function StripeCard ({setCardInfo}) {
    const stripe = useStripe();
    const elements = useElements();

    const [enableButton, setEnableButton] = useState(true);
    const [error, setError] = useState(null);

    const cardElementOpts = {
        style: {
            base: {
                'color': '#535353',
                'fontFamily': '"Kanit", "Roboto", sans-serif',
                'fontSmoothing': 'antialiased',
                'fontSize': '16px',
                '::placeholder': {
                    color: '#2F2F2F',
                },
            },
            invalid: {
                color: '#C33E2A',
                iconColor: '#C33E2A',
            },
            focus: {
                boxShadow: '0 0 0 0.15rem rgba(0,0,0,0.2)',
            }
        },
        hidePostalCode: true,
    };

    const getStripeToken = () => {
        if (!stripe || !elements)
            return;
        setError(null);
        const cardElement = elements.getElement(CardElement);
        setEnableButton(false);
        stripe.createToken(cardElement)
              .then(stripeToken => {
                  if (stripeToken.error) {
                      setError(<small className="text-danger">{`${stripeToken.error.message}`}</small>);
                      setEnableButton(true);
                  } else {
                      setCardInfo({
                          token: stripeToken.token.id,
                          info: {
                              brand: stripeToken.token.card.brand,
                              last4: stripeToken.token.card.last4
                          }
                      });
                  }
              });
    };

    return (
        <div className="flex flex-col gap-6">
            <CardElement
                options={cardElementOpts}
                clssName="gap-10"
                onChange={(e) => 
                    e.error ? setError(<small className="text-danger">{`${e.error.message}`}</small>) : setError(null)}
            />
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
            }}>
                {error}
                <Button
                    bsStyle="danger"
                    disabled={!enableButton}
                    fill
                    style={{ marginLeft: "auto" }}
                    onClick={() => getStripeToken()}
                >
                    <Svg name="card" className="w-4 h-4" />
                    Save Card
                </Button>
            </div>
        </div>
    );
}