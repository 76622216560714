import React, { Component } from "react";

export class StatsCard extends Component {
  render() {
    return (
      <div className={`card card-stats flex items-start ${this.props.className || ""}`} style={{ padding: "10px 16px" }}>
        <div className="icon-big text-center icon-warning">{this.props.bigIcon}</div>

        <div className="flex flex-col items-end text-right" style={{ marginLeft: "auto", alignSelf: "flex-start" }}>
          <span className="sub-text text-normal">{this.props.statsText}</span>
          <span className="main-text text-semibold" style={{ fontSize: "26px" }}>
            {this.props.statsValue}
          </span>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default StatsCard;
