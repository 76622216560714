import React, { useState, useEffect } from "react";
import { FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import Swal from "sweetalert2";
import showPass from "assets/img/show-icon.svg";
import hidePass from "assets/img/hide-icon.svg";
import "react-phone-number-input/style.css";
import { parseError } from "api/common";

const ResetPasswordPage = (props) => {
  const [email, setEmail] = useState();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [cfpassword, setCfPassword] = useState("");
  const [newType, setNewType] = useState("password");
  const [confirmType, setConfirmType] = useState("password");
  const [passwordError, setPasswordError] = useState(null);
  const [cfpasswordError, setCfPasswordError] = useState(null);

  useEffect(() => {
    let params = new URLSearchParams(props.location.search);
    let emailParam = params.get("email");
    setEmail(emailParam ? decodeURIComponent(emailParam) : "Invalid email address");
  }, []);

  const checkLowercase = (password) => {
    const pattern = /[a-z]/;
    if (pattern.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const checkSpecialCharacters = (password) => {
    const pattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);

    let lowerValid = checkLowercase(event.target.value);
    let upperValid = checkUppercase(event.target.value);
    let numberValid = checkNumber(event.target.value);
    let specialValid = checkSpecialCharacters(event.target.value);

    if (event.target.value.length < 8 || !lowerValid || !upperValid || !numberValid || !specialValid) {
      setPasswordError(
        <small className="text-danger">
          Make sure your password has at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character and at least 8
          characters long.
        </small>
      );
    } else {
      setPasswordError(null);
    }
  };

  const handleCfPasswordChange = (event) => {
    setCfPassword(event.target.value);

    if (event.target.value !== password) {
      setCfPasswordError(<small className="text-danger">Passwords do not match.</small>);
    } else {
      setCfPasswordError(null);
    }
  };

  const confirmPasswordReset = () => {
    const self = this;
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/confirmforgotpassword`, {
        email: email,
        code: code,
        password: password,
        passwordConfirmation: cfpassword,
      })
      .then((res) => {
        setLoading(false);

        Swal.fire({
          title: "Your password has been changed successfully. You can now login using your new credentials.",
          icon: "success",
          showCancelButton: false,
        }).then((result) => {
          props.history.push("/auth/login");
        });
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        Swal.fire("Password Reset Error", error, "error");
      });
  };

  return (
    <div className="flex flex-col" style={{ width: "90%", maxWidth: "400px", margin: "0 auto" }}>
      <Card
        style={{ padding: "16px 0px" }}
        className="w-full"
        title={
          <div className="text-center title" style={{ padding: "0" }}>
            Reset Password
          </div>
        }
        content={
          <div>
            <FormGroup>
              <ControlLabel>
                Email: <span className="star">*</span>
              </ControlLabel>
              <FormControl disabled={true} placeholder="Email Address" type="text" name="email" value={email} />
            </FormGroup>
            <FormGroup>
              <ControlLabel>
                Verification Code: <span className="star">*</span>
              </ControlLabel>
              <FormControl
                disabled={loading}
                placeholder="Enter Verification Code"
                type="text"
                name="verificationCode"
                maxLength="20"
                value={code}
                onChange={(event) => {
                  setCode(event.target.value);
                }}
              />
            </FormGroup>
            <FormGroup style={{ position: "relative" }}>
              <ControlLabel>
                New Password: <span className="star">*</span>
              </ControlLabel>
              <FormControl
                disabled={loading}
                type={newType}
                placeholder="New Password"
                autoComplete="off"
                value={password}
                onChange={(event) => handlePasswordChange(event)}
                required
              />
              {newType === "input" ? (
                <img
                  className="show-icon"
                  src={hidePass}
                  onClick={(e) => {
                    setNewType("password");
                  }}
                />
              ) : (
                <img
                  className="show-icon"
                  src={showPass}
                  onClick={(e) => {
                    setNewType("input");
                  }}
                />
              )}
              {passwordError}
            </FormGroup>
            <FormGroup style={{ position: "relative" }}>
              <ControlLabel>
                Confirm New Password: <span className="star">*</span>
              </ControlLabel>
              <FormControl
                disabled={loading}
                type={confirmType}
                autoComplete="off"
                placeholder="Confirm New Password"
                value={cfpassword}
                onChange={(event) => handleCfPasswordChange(event)}
                required
              />
              {confirmType === "input" ? (
                <img
                  className="show-icon"
                  src={hidePass}
                  onClick={(e) => {
                    setConfirmType("password");
                  }}
                />
              ) : (
                <img
                  className="show-icon"
                  src={showPass}
                  onClick={(e) => {
                    setConfirmType("input");
                  }}
                />
              )}
              {cfpasswordError}
            </FormGroup>
          </div>
        }
        legend={
          <div className="flex flex-col items-center">
            <Button
              fill
              type="submit"
              onClick={() => confirmPasswordReset()}
              loading={loading}
              disabled={
                !email || !password || !cfpassword || password !== cfpassword || passwordError || cfpasswordError || loading || !code
              }
            >
              Reset Password
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default ResetPasswordPage;
