import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { parseError } from "api/common";
import Svg from "components/Svg/Svg";
import Button from "components/CustomButton/CustomButton.jsx";
import Loader from "../../../views/Components/Loader/Loader.jsx";
import Timeline, { TimelineHeaders, SidebarHeader, DateHeader } from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { Link } from "react-router-dom";
import { dateFormat } from "api/common.js";

const GroupSchedule = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [weekDates, setWeekDates] = useState(null);
  const [technicianGroups, setTechnicianGroups] = useState([]);
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    getWeekSchedule(new Date());
  }, []);

  const getWeekDates = (curr) => {
    const initDate = moment(curr);
    const weekStart = initDate.clone().startOf("week");
    const weekEnd = initDate.clone().endOf("week");

    var firstDay = weekStart.toDate();
    var lastDay = weekEnd.toDate();

    return { start: firstDay, end: lastDay };
  };

  const getWeekSchedule = (date) => {
    setShifts([]);
    setTechnicianGroups([]);
    setLoading(true);
    setError(null);

    var weekDates = getWeekDates(date);
    setWeekDates(weekDates);

    const epochStart = Math.round(weekDates.start / 1000);
    const epochEnd = Math.round(weekDates.end / 1000);

    axios
      .get(`${process.env.REACT_APP_API_URL}/technicians/shifts/${epochStart}/${epochEnd}`)
      .then((res) => {
        setLoading(false);

        const techGroups = [];
        res.data.forEach((s) => {
          if (techGroups.filter((g) => g.id === s.technician.id).length == 0) {
            techGroups.push({
              id: s.technician.id,
              height: 56,
              stackItems: true,
              title: `${s.technician.firstName} ${s.technician.lastName}`,
            });
          }
        });

        setTechnicianGroups(techGroups);

        let shiftItems = res.data.map((sh) => {
          let startDate = new Date(
            sh.startTime.year,
            sh.startTime.month - 1,
            sh.startTime.day,
            sh.startTime.hour,
            sh.startTime.minute
          );
          let endDate = new Date(
            sh.endTime.year,
            sh.endTime.month - 1,
            sh.endTime.day,
            sh.endTime.hour,
            sh.endTime.minute
          );

          return {
            id: sh.id,
            group: sh.technician.id,
            title: `${moment(startDate).format("H:mm")}-${moment(endDate).format("H:mm")}`,
            start_time: moment(startDate),
            end_time: moment(endDate),
            itemProps: {
              style: {
                background: "var(--theme-dark-color)",
                color: "white",
                fontSize: "10px",
                border: "none",
                borderRadius: "4px",
                overflow: "hidden",
                lineHeight: "14px",
              },
            },
          };
        });

        setShifts(shiftItems);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const nextWeek = () => {
    getWeekSchedule(moment(weekDates.start).add(1, "week"));
  };

  const prevWeek = () => {
    getWeekSchedule(moment(weekDates.start).add(-1, "week"));
  };

  return (
    <div className="flex flex-col gap-12" style={{ minHeight: "600px" }}>
      <div className="flex items-center">
        <Button
          className="pagination-btn btn-fill mr-auto"
          disabled={loading}
          onClick={() => prevWeek()}
          bsStyle="danger"
          fill
        >
          <Svg name="chevron-left" className="w-6 h-6" />
        </Button>
        {weekDates && (
          <div className="text-semibold text-xxl ml-auto mr-auto">
            {moment(weekDates.start).format(dateFormat)} -{" "}
            {moment(weekDates.end).format(dateFormat)}
          </div>
        )}
        <Button
          className="pagination-btn btn-fill ml-auto"
          disabled={loading}
          onClick={() => nextWeek()}
          bsStyle="danger"
          fill
        >
          <Svg name="chevron-right" className="w-6 h-6" />
        </Button>
      </div>

      {loading && (
        <div className="flex flex-col items-center justify-center" style={{ margin: "32px auto" }}>
          <Loader />
          <p className="text-xs text-gray-400 mt-6" style={{ color: "var(--theme-color)" }}>
            Loading Schedule...
          </p>
        </div>
      )}

      {error && (
        <div
          className="error-alert"
          style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
        >
          {error}
        </div>
      )}

      {!loading && !error && shifts.length > 0 && (
        <Timeline
          groupRenderer={({ group }) => {
            return (
              <Link
                to={`/admin/lab-techs-details/${encodeURIComponent(group.id)}`}
                title="View Account"
              >
                {group.title}
              </Link>
            );
          }}
          groups={technicianGroups}
          items={shifts}
          defaultTimeStart={moment(weekDates.start)}
          defaultTimeEnd={moment(weekDates.end)}
          stackItems={true}
          canResize={false}
          canMove={false}
          canChangeGroup={false}
          lineHeight={56}
          sidebarWidth={180}
          onTimeChange={(visibleStart, visibleEnd, updateScrollCanvas) => {
            updateScrollCanvas(moment(weekDates.start).valueOf(), moment(weekDates.end).valueOf());
          }}
        >
          <TimelineHeaders>
            <SidebarHeader style={{ background: "var(--theme-dark-color)" }}>
              {({ getRootProps }) => {
                var intProps = getRootProps();
                intProps.style.display = "flex";
                intProps.style.alignItems = "center";
                intProps.style.justifyContent = "center";
                intProps.style.textAlign = "center";
                intProps.style.color = "white";
                intProps.style.fontWeight = "500";
                intProps.style.background = "var(--theme-dark-color)";
                return <div {...intProps}>Technician</div>;
              }}
            </SidebarHeader>
            <DateHeader
              height={48}
              unit="day"
              style={{ color: "white", background: "var(--theme-dark-color)", fontWeight: "500" }}
              intervalRenderer={({ getIntervalProps, intervalContext, data }) => {
                var intProps = getIntervalProps();
                intProps.style.top = "50%";
                intProps.style.transform = "translateY(-50%)";
                intProps.style.textAlign = "center";
                intProps.style.fontSize = "13px";
                return (
                  <div {...intProps}>
                    <div>{intervalContext.interval.startTime.format(dateFormat)}</div>
                  </div>
                );
              }}
            />
          </TimelineHeaders>
        </Timeline>
      )}

      {!loading && !error && shifts.length === 0 && (
        <div
          className="sub-text text-xl flex flex-col items-center justify-center"
          style={{ margin: "25% auto" }}
        >
          No scheduled shifts...
        </div>
      )}
    </div>
  );
};

export default GroupSchedule;
