import React, { useState, useEffect } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { parseError } from "api/common.js";
import axios from "axios";
import Swal from "sweetalert2";
import "react-quill/dist/quill.snow.css";
import QuillResize from "quill-resize-module";
import Quill from "quill";
import HtmlEditor from "components/HtmlEditor/HtmlEditor";
import Loader from "../../../views/Components/Loader/Loader.jsx";

Quill.register("modules/imageResize", QuillResize);

const Size = Quill.import("attributors/style/size");
Size.whitelist = ["16px", "10px", "12px", "14px", "18px", "20px"];
Quill.register(Size, true);

const PlatformEditor = (props) => {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    initializeEditor();
    getContent();
  }, []);

  const initializeEditor = () => {
    let paths = props.location.pathname.split("/");
    let currentPath = paths[paths.length - 1];

    switch (currentPath) {
      case "about":
        setTitle("About The App");
        break;
      case "privacy-policy":
        setTitle("Privacy Policy");
        break;
      case "terms":
        setTitle("Terms Of Service");
        break;
    }
  };

  const getContentPath = () => {
    let paths = props.location.pathname.split("/");
    let currentPath = paths[paths.length - 1];

    switch (currentPath) {
      case "about":
        return "abouttheapp";
      case "privacy-policy":
        return "privacypolicy";
      case "terms":
        return "termsofservice";
    }
  };

  const getContent = () => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/platform/${getContentPath()}`)
      .then((res) => {
        setLoading(false);
        setContent(res.data || "");
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        Swal.fire("Content Error", error, "error");
      });
  };

  const updateContent = () => {
    setLoading(true);

    axios
      .put(`${process.env.REACT_APP_API_URL}/platform/${getContentPath()}`, { content: content })
      .then((res) => {
        setLoading(false);
        setContent(res.data);
        Swal.fire({
          title: "Content Update",
          text: "Content has been successfully updated",
          icon: "success",
          showCancelButton: false,
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Update Error", error, "error");
      });
  };

  return (
    <div className="main-content system-settings">
      <Card
        content={
          <div className="flex flex-col gap-16">
            <div className="flex items-start gap-16">
              <strong className="text-xxl">{title}</strong>
              <Button loading={loading} disabled={loading} className="ml-auto" bsStyle="danger" onClick={() => updateContent()}>
                Save Changes
              </Button>
            </div>

            {loading && (
              <div className="flex flex-col items-center justify-center">
                <Loader />
                <p className="text-xs text-gray-400 mt-6" style={{ color: "var(--theme-color)" }}>
                  Loading Content...
                </p>
              </div>
            )}

            {!loading && (
              <div className="quill-html-container">
                <HtmlEditor value={content} onChange={(val) => setContent(val)} />
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default PlatformEditor;
