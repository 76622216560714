import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal, Alert, Grid, Row, Col, FormControl, FormGroup, ControlLabel, Form, Label } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import { parseError } from "api/common";

const AddNoteModal = (props) => {
  const [noteText, setNoteText] = useState(props.user ? props.user.adminNotes : "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setNoteText(props.user ? props.user.adminNotes : "");
  }, [props.user]);

  const saveNotes = () => {
    setLoading(true);
    setError(null);

    axios
      .put(`${process.env.REACT_APP_API_URL}/account/${props.user.id}/notes`, { notes: noteText })
      .then((res) => {
        setLoading(false);
        props.onUpdate(res.data);
        props.onClose();

        Swal.fire({
          title: "The customer's notes have been updated succesfully.",
          icon: "success",
          showCancelButton: false,
        });
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  return (
    <Modal show={props.show} onHide={() => props.onClose()} centered size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Customer Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <FormGroup>
            <FormControl
              type="textarea"
              maxLength={500}
              componentClass="textarea"
              rows={5}
              value={noteText}
              onChange={(event) => {
                setNoteText(event.target.value);
              }}
            />
          </FormGroup>

          {error && (
            <div className="error-alert" style={{ gridColumn: "1 / span 2", marginTop: "12px" }}>
              {error}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-center gap-10">
          <Button outline="true" style={{ marginLeft: "auto" }} onClick={() => props.onClose()} disabled={loading}>
            Close
          </Button>
          <Button
            bsStyle="danger"
            loading={loading}
            disabled={loading || (props.user && props.user.adminNotes == noteText) || (props.user && !props.user.adminNotes && !noteText)}
            fill
            onClick={() => saveNotes()}
          >
            Save Notes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNoteModal;
