import React, { useState, useRef, useCallback } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MapGL, { Marker, NavigationControl } from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import LocationMarker from "../../../assets/img/appointment-location.png";

const AddLocationModal = ({onSelectAddress}) => {
    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);
    const [addressInput, setAddressInput] = useState("");
    const [viewport, setViewport] = useState({
        latitude: 51.0531,
        longitude: -114.0626,
        zoom: 12,
    });
    const mapRef = useRef();
    const geocoderContainerRef = useRef();
    const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);

    const setMarkerLocation = (result) => {
        let location = {
            latitude: result.center[1],
            longitude: result.center[0],
        };

        let place = result.context.find((c) => c.id.indexOf("place.") > -1);
        let postCode = result.context.find((c) => c.id.indexOf("postcode.") > -1);
        let region = result.context.find((c) => c.id.indexOf("region.") > -1);
        let street = `${result.address || ""} ${result.text}`;
        street = street.trim();
        let addressString = `${street}${street ? "," : ""} ${place ? place.text : ""}${
            place ? "," : ""
        } ${postCode.text}, ${region.text}`.trim();
        setLat(location.latitude);
        setLong(location.longitude);
        let addressData = {
            city: place.text,
            postalCode: postCode.text,
            province: region.text,
            street: street
        };
        onSelectAddress(addressString, location, addressData);
    };

    const handleGeocoderViewportChange = useCallback(
        (newViewport) => {
            const geocoderDefaultOverrides = { transitionDuration: 1000 };

            return handleViewportChange({
                ...newViewport,
                ...geocoderDefaultOverrides,
            });
        },
        [handleViewportChange]
    );

    const navControlStyle = {
        right: 10,
        top: 10,
    };

    return (
        <div>
            <MapGL
                ref={mapRef}
                {...viewport}
                width="100%"
                height="460px"
                onViewportChange={handleViewportChange}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
            >
                <NavigationControl style={navControlStyle} />
                <Geocoder

                    mapRef={mapRef}
                    onViewportChange={handleGeocoderViewportChange}
                    containerRef={geocoderContainerRef}
                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
                    position="top-left"
                    placeholder="Search Address"
                    className="overWrite"
                    marker={false}
                    onResult={(result) => setMarkerLocation(result.result)}
                    inputValue={addressInput}
                    countries="ca"
                    language="en"
                />

                {lat && long ? (
                    <Marker latitude={lat} longitude={long} offsetLeft={-20} offsetTop={-10}>
                        <img width={30} src={LocationMarker} alt="Map Marker" />
                    </Marker>
                ) : null}
            </MapGL>
        </div>
    );
};

export default AddLocationModal;
