import React, { useState, useEffect } from "react";
import moment from "moment";
import Datetime from "react-datetime";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import Swal from "sweetalert2";
import ReactTable from "react-table";
import Loader from "../../../views/Components/Loader/Loader.jsx";
import Svg from "components/Svg/Svg.jsx";
import { Link } from "react-router-dom";
import { dateFormat, parseError, renderDatePickerInput } from "api/common";

const today = moment();

const TechStatisticsView = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [statData, setStatData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var weekDates = getWeekDates(new Date());
    setStartDate(weekDates.start);
    setEndDate(weekDates.end);

    if (props.techId) {
      getStatistics(weekDates.start, weekDates.end);
    }
  }, [props.techId]);

  const getWeekDates = (curr) => {
    const initDate = moment(curr);
    const monthStart = initDate.clone().startOf("month");
    const monthEnd = initDate.clone().endOf("month");

    var firstDay = monthStart.toDate();
    var lastDay = monthEnd.toDate();

    return { start: firstDay, end: lastDay };
  };

  const disableFutureDt = (current) => {
    return current.isBefore(today);
  };

  const changeStartDate = (event) => {
    setStartDate(event.toDate());
  };

  const changeEndDate = (event) => {
    setEndDate(event.toDate());
  };

  const getStatistics = (start, end) => {
    const self = this;
    setLoading(true);
    setStatData(null);

    const data = {
      technicianId: props.techId,
      from: {
        year: start.getFullYear(),
        month: start.getMonth() + 1,
        day: start.getDate(),
      },
      to: {
        year: end.getFullYear(),
        month: end.getMonth() + 1,
        day: end.getDate(),
      },
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/technicians/statistics`, data)
      .then((res) => {
        setLoading(false);
        setStatData(res.data);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        Swal.fire("Statistics Error", error, "error");
      });
  };

  const formatCommissionStatus = (status) => {
    var badgeColor = "primary";
    var statusName = status;

    switch (status) {
      case "not_paid":
        badgeColor = "info";
        statusName = "Unpaid";
        break;
      case "paid":
        badgeColor = "success";
        statusName = "Paid";
        break;
      case "payout_not_allowed":
        badgeColor = "warning";
        statusName = "Not Allowed";
        break;
    }

    return (
      <div style={{ padding: "2px 10px" }} className={`badge ${badgeColor}`}>
        {statusName}
      </div>
    );
  };

  const formatDateOnly = (inDate) => {
    if (!inDate) {
      return "--";
    }

    let dateString = moment(
      `${inDate.day.toString()}/${inDate.month}/${inDate.year}`,
      "D/M/YYYY"
    ).format(dateFormat);

    return dateString;
  };

  const formatAppointmentLink = (item) => {
    return (
      <Link
        to={`/admin/appointments/${encodeURIComponent(item.appointmentCode)}`}
        title="View Appointment"
      >
        {`${item.appointmentCode} ${item.type === "tip" ? "(Tip)" : ""}`}
      </Link>
    );
  };

  const mapCommissionItem = (item) => {
    return {
      id: item.id,
      status: formatCommissionStatus(item.status),
      date: formatDateOnly(item.date),
      amount: `$${new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        signDisplay: "never",
      }).format(item.amount > 0 ? item.amount / 100 : 0)}`,
      appointmentCode: formatAppointmentLink(item),
    };
  };

  const formatHoursPeriod = (start, end) => {
    if (!start || !end) {
      return "--";
    }

    let startTime = moment(
      `${start.hour.toString().padStart(2, "0")}/${start.minute.toString().padStart(2, "0")}`,
      "H:mm"
    );
    let endTime = moment(
      `${end.hour.toString().padStart(2, "0")}/${end.minute.toString().padStart(2, "0")}`,
      "H:mm"
    );
    let dateString = `${moment(startTime).format("h:mm A")} - ${moment(endTime).format("h:mm A")}`;
    return dateString;
  };

  const caluclateWorkedHours = (logs) => {
    if (!logs || logs.length === 0) {
      return "--";
    }

    let selectIntervals = logs
      .filter((s) => s.startedAt && s.endedAt)
      .map((l) => {
        let start = moment(
          `${l.startedAt.day.toString().padStart(2, "0")}/${l.startedAt.month
            .toString()
            .padStart(2, "0")}/${l.startedAt.year} ${l.startedAt.hour
            .toString()
            .padStart(2, "0")}/${l.startedAt.minute.toString().padStart(2, "0")}`,
          "DD/MM/YYYY H:mm"
        );
        let end = moment(
          `${l.endedAt.day.toString().padStart(2, "0")}/${l.endedAt.month
            .toString()
            .padStart(2, "0")}/${l.endedAt.year} ${l.endedAt.hour
            .toString()
            .padStart(2, "0")}/${l.endedAt.minute.toString().padStart(2, "0")}`,
          "DD/MM/YYYY H:mm"
        );

        return end.diff(start, "seconds", true);
      });

    let secondsLogged = selectIntervals.reduce((prev, curr) => prev + curr, 0);
    if (secondsLogged > 0) {
      return secondsToDhms(secondsLogged);
    }

    return "--";
  };

  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds % 3600) / 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " h " : " h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " m " : " m ") : "";
    return hDisplay + mDisplay;
  }

  const mapShiftItem = (item) => {
    return {
      id: item.id,
      date: formatDateOnly(item.startTime),
      hours: formatHoursPeriod(item.startTime, item.endTime),
      worked: caluclateWorkedHours(item.log),
    };
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-end gap-12">
        <div className="flex flex-col gap-6">
          <span className="text-xxs text-bold">
            FROM: <span className="star">*</span>
          </span>
          <Datetime
            dateFormat={dateFormat}
            timeFormat={false}
            renderInput={renderDatePickerInput}
            inputProps={{ placeholder: "", clearable: false }}
            isValidDate={disableFutureDt}
            value={startDate}
            closeOnSelect={true}
            onChange={changeStartDate}
          />
        </div>

        <div className="flex flex-col gap-6">
          <span className="text-xxs text-bold">
            TO: <span className="star">*</span>
          </span>
          <Datetime
            dateFormat={dateFormat}
            renderInput={renderDatePickerInput}
            closeOnSelect={true}
            timeFormat={false}
            inputProps={{ placeholder: "", clearable: false }}
            isValidDate={disableFutureDt}
            value={endDate}
            onChange={changeEndDate}
          />
        </div>

        <Button
          loading={loading}
          bsStyle="danger"
          style={{ marginBottom: "2px" }}
          fill
          onClick={() => getStatistics(startDate, endDate)}
          disabled={!startDate || !endDate || loading}
        >
          Get Statistics
        </Button>
      </div>

      <div className="flex flex-col gap-16" style={{ marginTop: "24px" }}>
        <div className="separator horizontal"></div>

        {loading && (
          <div className="flex flex-col items-center justify-center">
            <Loader />
            <p className="text-xs text-gray-400 mt-6" style={{ color: "var(--theme-color)" }}>
              Loading Details...
            </p>
          </div>
        )}

        {statData && !loading && (
          <React.Fragment>
            <div className="grid grid-3col gap-16" style={{ paddingLeft: "12px" }}>
              <div className="flex flex-col gap-4">
                <span className="text-semibold text-xxs">COMMISSIONS</span>
                <div className="flex items-start gap-10">
                  <div className="flex flex-col items-end text-right">
                    <span className="text-semibold text-xxl main-text">
                      $
                      {new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(statData.commissions > 0 ? statData.commissions / 100 : 0)}
                    </span>
                    <span className="text-xxs sub-text">Total</span>
                  </div>
                  <span className="text-semibold text-xxl main-text">/</span>
                  <div className="flex flex-col items-end text-right">
                    <span className="text-semibold text-xxl theme-text">
                      $
                      {new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(
                        statData.unpaidCommissions > 0 ? statData.unpaidCommissions / 100 : 0
                      )}
                    </span>
                    <span className="text-xxs sub-text">Unpaid</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <span className="text-semibold text-xxs">TIPS</span>
                <div className="flex items-start gap-10">
                  <div className="flex flex-col items-end text-right">
                    <span className="text-semibold text-xxl main-text">
                      $
                      {new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(statData.tips > 0 ? statData.tips / 100 : 0)}
                    </span>
                    <span className="text-xxs sub-text">Total</span>
                  </div>
                  <span className="text-semibold text-xxl main-text">/</span>
                  <div className="flex flex-col items-end text-right">
                    <span className="text-semibold text-xxl theme-text">
                      $
                      {new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(statData.unpaidTips > 0 ? statData.unpaidTips / 100 : 0)}
                    </span>
                    <span className="text-xxs sub-text">Unpaid</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <span className="text-semibold text-xxs">HOURS WORKED</span>
                <p className="text-semibold text-xxl main-text">
                  {secondsToDhms(statData.secondsWorked)}
                </p>
              </div>
            </div>

            <div className="separator horizontal" style={{ margin: "-6px 0" }}></div>

            <div
              className="grid grid-2col items-start gap-12"
              style={{ paddingLeft: "12px", paddingTop: "12px" }}
            >
              <div className="flex flex-col gap-12">
                <span className="text-semibold text-xxs theme-text">COMMISSIONS & TIPS</span>
                <ReactTable
                  noDataText={"No commissions earned..."}
                  data={(statData.commissionsAndTips || []).map((s) => mapCommissionItem(s))}
                  previousText={<Svg name="chevron-left" className="w-6 h-6" />}
                  nextText={<Svg name="chevron-right" className="w-6 h-6" />}
                  columns={[
                    {
                      Header: "Amount",
                      accessor: "amount",
                      sortable: false,
                      maxWidth: 100,
                    },
                    {
                      Header: "Date",
                      accessor: "date",
                      sortable: false,
                      maxWidth: 100,
                    },
                    {
                      Header: "Appointment",
                      accessor: "appointmentCode",
                      sortable: false,
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                      sortable: false,
                      filterable: false,
                      maxWidth: 106,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                />
              </div>

              <div className="flex flex-col gap-12">
                <span className="text-semibold text-xxs theme-text">SHIFTS</span>
                <ReactTable
                  noDataText={"There are no shifts in this range..."}
                  data={(statData.shifts || []).map((s) => mapShiftItem(s))}
                  previousText={<Svg name="chevron-left" className="w-6 h-6" />}
                  nextText={<Svg name="chevron-right" className="w-6 h-6" />}
                  columns={[
                    {
                      Header: "Date",
                      accessor: "date",
                      sortable: false,
                      maxWidth: 100,
                    },
                    {
                      Header: "Hours",
                      accessor: "hours",
                      sortable: false,
                    },
                    {
                      Header: "Logged Hours",
                      accessor: "worked",
                      sortable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default TechStatisticsView;
