import React, { useEffect, useState } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import { parseError } from "../../../api/common";
import Svg from "../../../components/Svg/Svg";
import Swal from "sweetalert2";

const Feedback = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState("");
  const [previousPageToken, setPreviousPageToken] = useState("");
  const [pageSize] = useState(12);

  useEffect(() => {
    getData();
  }, []);

  const getData = (pageToken = "", isBackward = false) => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/feedbacks?pageSize=${pageSize}&paginationToken=${pageToken}&isBackward=${isBackward}`
      )
      .then((res) => {
        setPreviousPageToken(res.data.previousPageToken);
        setNextPageToken(res.data.nextPageToken);
        setLoading(false);
        setError(null);
        setData(
          res.data.items.map((item) => {
            return {
              id: item.id,
              note: item.note,
              subject: item.category.name,
              actions: (
                <div className="flex items-center gap-6">
                  <Button
                    bsStyle="danger"
                    outline="true"
                    title="Delete Feedback"
                    style={{ padding: "7px 10px" }}
                    onClick={() => deleteFeedback(item.category.id, item.id)}
                  >
                    <Svg name="trash" className="w-4 h-4" />
                  </Button>
                </div>
              ),
            };
          })
        );
      })
      .catch((e) => {
        setLoading(false);
        setError(parseError(e));
      });
  };

  const deleteFeedback = (categoryId, id) => {
    Swal.fire({
      title: "Delete Feedback",
      text: "Are you sure you want to delete this feedback entry?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Feedback",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/feedbacks/${categoryId}/${id}`)
          .then(() => {
            Swal.fire({
              title: "Feedback entry has been deleted successfully.",
              icon: "success",
              showCancelButton: false,
            });

            getData();
          })
          .catch((err) => {
            let error = parseError(err);
            Swal.fire("Delete Error", error, "error");
          });
      }
    });
  };

  return (
    <div className="main-content feedback flex flex-col">
      <Card
        title="Feedback Reports"
        content={
          <div className={"flex flex-col gap-16"}>
            <ReactTable
              loading={loading}
              loadingText={"Loading Feedback..."}
              noDataText={error ? error : loading ? "" : "No feedback reports found..."}
              sortable={false}
              filterable={false}
              data={data}
              columns={[
                {
                  Header: "Subject",
                  accessor: "subject",
                  minWidth: 300,
                  maxWidth: 300,
                  style: { whiteSpace: "unset" },
                },
                {
                  Header: "Note",
                  accessor: "note",
                  style: { whiteSpace: "unset" },
                },
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  minWidth: 60,
                  maxWidth: 60,
                },
              ]}
              defaultPageSize={pageSize}
              showPaginationBottom={false}
              className="-striped -highlight"
            />
            <div style={{ marginLeft: "auto" }} className="flex items-center">
              <Button
                className="pagination-btn left btn-fill"
                disabled={previousPageToken === "" || loading}
                onClick={() => getData(previousPageToken, true)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-left" className="w-6 h-6" />
              </Button>
              <Button
                className="pagination-btn right btn-fill"
                disabled={nextPageToken === "" || loading}
                onClick={() => getData(nextPageToken, false)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-right" className="w-6 h-6" />
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Feedback;
