import React, { useState, useEffect } from "react";
import { FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import DatesListControl from "../DropOffLocations/DatesListControl/DatesListControl.jsx";
import OpenCloseTime from "../DropOffLocations/OpenCloseTime.jsx";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import Loader from "../../../views/Components/Loader/Loader.jsx";
import { parseError } from "api/common.js";
import TimePicker from "react-time-picker";

const HoursOperation = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [centreId, setCentreId] = useState(null);
  const [centreData, setCentreData] = useState(null);
  const [hoursOfOperations, setHoursOfOperation] = useState([
    {
      dayOfWeek: "Sunday",
      start: "Closed",
      end: "Closed",
    },
    {
      dayOfWeek: "Monday",
      start: "Closed",
      end: "Closed",
    },
    {
      dayOfWeek: "Tuesday",
      start: "Closed",
      end: "Closed",
    },
    {
      dayOfWeek: "Wednesday",
      start: "Closed",
      end: "Closed",
    },
    {
      dayOfWeek: "Thursday",
      start: "Closed",
      end: "Closed",
    },
    {
      dayOfWeek: "Friday",
      start: "Closed",
      end: "Closed",
    },
    {
      dayOfWeek: "Saturday",
      start: "Closed",
      end: "Closed",
    },
  ]);
  const [closureDates, setClosureDates] = useState([]);
  const [asapInterval, setAsapInterval] = useState("");
  const [scheduledInterval, setScheduledInterval] = useState("");

  useEffect(() => {
    loadServiceCentre();
  }, []);

  const loadServiceCentre = () => {
    setLoading(true);
    setError(null);
    setCentreData(null);
    setCentreId(null);

    axios
      .get(`${process.env.REACT_APP_API_URL}/service-centres`)
      .then((res) => {
        let cId = res.data.items[0].id;
        setCentreId(cId);

        axios
          .get(`${process.env.REACT_APP_API_URL}/service-centres/${cId}`)
          .then((scRes) => {
            setLoading(false);
            let centre = scRes.data;
            prepareData(centre);
          })
          .catch((scErr) => {
            let error = parseError(scErr);
            setLoading(false);
            setError(error);
          });
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const onChangeHoursOfOperation = (data) => {
    setHoursOfOperation((list) =>
      list.map((h) => {
        if (h.dayOfWeek === data.dayOfWeek) {
          return {
            dayOfWeek: h.dayOfWeek,
            start: data.startTime,
            end: data.endTime,
          };
        }
        return h;
      })
    );
  };

  const prepareData = (centre) => {
    let hopList = hoursOfOperations.map((hop) => {
      var scItem = centre.hoursOfOperations.find((hh) => hh.dayOfWeek === hop.dayOfWeek);
      if (scItem) {
        let start = moment(scItem.startTime.hour + ":" + scItem.startTime.minute, ["h:mm A"]);
        let end = moment(scItem.endTime.hour + ":" + scItem.endTime.minute, ["h:mm A"]);

        return {
          dayOfWeek: hop.dayOfWeek,
          start: { value: start.format("h:mm A"), label: start.format("h:mm A") },
          end: { value: end.format("h:mm A"), label: end.format("h:mm A") },
        };
      }

      return {
        dayOfWeek: hop.dayOfWeek,
        start: { value: "Closed", label: "Closed" },
        end: { value: "Closed", label: "Closed" },
      };
    });
    setHoursOfOperation(hopList);
    setCentreData(centre);
    setClosureDates(
      centre.closureDates.map((item) => {
        return {
          date: moment(
            `${item.date.year}-${item.date.month.toString().padStart(2, "0")}-${item.date.day.toString().padStart(2, "0")}`,
            "YYYY-MM-DD"
          ).toDate(),
          text: item.reason,
        };
      })
    );
    setAsapInterval(centre.asapIntervalBeforeClosure);
    setScheduledInterval(centre.scheduledIntervalBeforeClosure);
  };

  const onSaveChanges = () => {
    setError(null);
    setLoading(true);

    const obj = {
      address: centreData.address,
      location: centreData.location,
      name: centreData.name,
      id: centreData.id,
      asapIntervalBeforeClosure: asapInterval,
      scheduledIntervalBeforeClosure: scheduledInterval,
      hoursOfOperations: hoursOfOperations
        .filter((s) => s.start.value !== "Closed" && s.end.value !== "Closed")
        .map((item) => {
          let start = moment(item.start.value, ["h:mm A"]);
          let end = moment(item.end.value, ["h:mm A"]);

          return {
            dayOfWeek: item.dayOfWeek,
            startTime: {
              hour: parseInt(start.format("H")),
              minute: parseInt(start.format("m")),
            },
            endTime: {
              hour: parseInt(end.format("H")),
              minute: parseInt(end.format("m")),
            },
          };
        }),
      closureDates: closureDates.map((cDate) => {
        return {
          date: {
            year: cDate.date.getFullYear(),
            month: cDate.date.getMonth() + 1,
            day: cDate.date.getDate(),
          },
          reason: cDate.text,
        };
      }),
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/service-centres/${centreId}`, obj)
      .then((res) => {
        setLoading(false);
        let centre = res.data;
        Swal.fire("Saved Successfully", "All changes have been successfully saved.", "success");
        prepareData(centre);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Save Error", error, "error");
      });
  };

  return (
    <div className="main-content flex flex-col gap-24">
      {loading && !centreData && (
        <div className="flex flex-col items-center justify-center">
          <Loader />
          <p className="text-xs text-gray-400 mt-6" style={{ color: "var(--theme-color)" }}>
            Loading Service Centre Details...
          </p>
        </div>
      )}

      {error && (
        <div className="error-alert" style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}>
          {error}
        </div>
      )}

      {centreData && (
        <div className="grid grid-2col gap-16">
          <Card
            className="col-2span"
            title={centreData.name}
            content={
              <div className="flex flex-col gap-16">
                <div className="main-text text-normal">{centreData.description || "No description..."}</div>
                <div className="flex flex-col gap-4" style={{ paddingLeft: 0 }}>
                  <span className="text-semibold text-xxs">ADDRESS:</span>
                  {`${centreData.address.street}, ${centreData.address.city}, ${centreData.address.postalCode}, ${centreData.address.province}`}
                </div>
              </div>
            }
          />

          <Card
            title="Hours of operation"
            content={
              <div>
                {hoursOfOperations.map((item, key) => (
                  <OpenCloseTime
                    isDisabled={loading}
                    key={key}
                    changeWeekTime={(data) => onChangeHoursOfOperation(data)}
                    Day={item.dayOfWeek}
                    Open={item.start}
                    Close={item.end}
                  />
                ))}
              </div>
            }
          />

          <div className="flex flex-col gap-24">
            <Card
              title="Closure Dates"
              content={
                <div>
                  <DatesListControl
                    disabled={loading}
                    items={closureDates}
                    onChange={(items) => {
                      setClosureDates(items);
                    }}
                    textLabel="REASON"
                  />
                </div>
              }
            />

            <Card
              title="Interval Before Closure"
              content={
                <div className="closure-interval">
                  <FormGroup>
                    <ControlLabel>
                      ASAP interval before closure: <span className="star">*</span>
                    </ControlLabel>
                    <TimePicker
                      disableClock
                      clearIcon=""
                      value={asapInterval}
                      hourPlaceholder="Hour"
                      minutePlaceholder="Minute"
                      locale="ca-CA"
                      maxDetail="second"
                      onChange={(asapInterval) => setAsapInterval(asapInterval)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>
                      Scheduled interval before closure: <span className="star">*</span>
                    </ControlLabel>
                    <TimePicker
                      style={{ outerWidth: "100%" }}
                      disableClock
                      clearIcon=""
                      value={scheduledInterval}
                      hourPlaceholder="Hour"
                      minutePlaceholder="Minute"
                      locale="ca-CA"
                      maxDetail="second"
                      onChange={(scheduledInterval) => setScheduledInterval(scheduledInterval)}
                    />
                  </FormGroup>
                </div>
              }
            />

            <Button
              disabled={loading}
              loading={loading}
              bsStyle="danger"
              fill
              style={{ marginLeft: "auto" }}
              onClick={() => onSaveChanges()}
            >
              Save Changes
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HoursOperation;
