import React, { useState, useEffect } from "react";
import BigCalendar from "react-big-calendar";
import Loader from "views/Components/Loader/Loader";
import moment from "moment";
import Swal from "sweetalert2";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import BlockTimeModal from "./BlockTimeModal.jsx";
import { parseError, truncate } from "api/common";

const TechSchedule = (props) => {
  const localizer = BigCalendar.momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [alert, setAlert] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState();

  useEffect(() => {
    getWeekSchedule(new Date());
  }, []);

  useEffect(() => {
    getWeekSchedule(currentDate || new Date());
  }, [props.isTechEnabled]);

  const getWeekDates = (curr) => {
    const initDate = moment(curr);
    const weekStart = initDate.clone().startOf("week");
    const weekEnd = initDate.clone().endOf("week");

    var firstDay = weekStart.toDate();
    var lastDay = weekEnd.toDate();

    return { start: firstDay, end: lastDay };
  };

  const getWeekSchedule = (date) => {
    setSelectedEvent(null);
    setEvents([]);
    setLoading(true);

    var weekDates = getWeekDates(date);
    const epochStart = Math.round(weekDates.start / 1000);
    const epochEnd = Math.round(weekDates.end / 1000);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/technicians/${props.techId}/shifts/${epochStart}/${epochEnd}?pageSize=7`
      )
      .then((res) => {
        setLoading(false);
        setEvents([]);
        setEvents(
          res.data.items.flatMap((item) => {
            let calendarItems = [];

            let shiftItem = {
              type: "shift",
              shiftId: item.id,
              title: "Shift",
              start: new Date(
                item.startTime.year,
                item.startTime.month - 1,
                item.startTime.day,
                item.startTime.hour,
                item.startTime.minute
              ),
              end: new Date(
                item.endTime.year,
                item.endTime.month - 1,
                item.endTime.day,
                item.endTime.hour,
                item.endTime.minute
              ),
              allDay: false,
              color: "default",
            };

            calendarItems.push(shiftItem);

            if (item.blockedSlots && item.blockedSlots.length > 0) {
              item.blockedSlots.forEach((bs) => {
                calendarItems.push({
                  type: "block",
                  shiftId: bs.id,
                  title: truncate(bs.notes, 60),
                  start: new Date(
                    item.startTime.year,
                    item.startTime.month - 1,
                    item.startTime.day,
                    bs.startTime.hour,
                    bs.startTime.minute
                  ),
                  end: new Date(
                    item.endTime.year,
                    item.endTime.month - 1,
                    item.endTime.day,
                    bs.endTime.hour,
                    bs.endTime.minute
                  ),
                  allDay: false,
                  color: "blocked",
                });
              });
            }

            return calendarItems;
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Schedule Error", error, "error");
      });
  };

  const onDatesChanged = (date) => {
    setCurrentDate(date);
    getWeekSchedule(date);
  };

  const onSelectEvent = (event) => {
    if (!props.isTechEnabled) {
      alertTechDisabled();
      return;
    }

    if (event.type === "shift") {
      Swal.fire({
        title: "Technician's Schedule",
        showCancelButton: true,
        confirmButtonColor: "var(--theme-dark-color)",
        confirmButtonText: "Remove Shift",
        style: { display: "block", marginTop: "-100px" },
        text: `Do you want to remove this shift for ${props.techName} on (${moment(
          event.start
        ).format("dddd, MMM D YYYY")}, ${moment(event.start).format("h:mm a")} - ${moment(
          event.end
        ).format("h:mm a")})?`,
      }).then((result) => {
        if (result.isConfirmed) {
          onRemoveShift(event);
        } else {
          setAlert(null);
          setSelectedEvent(null);
        }
      });
    } else if (event.type === "block") {
      Swal.fire({
        title: "Technician's Schedule",
        showCancelButton: true,
        confirmButtonColor: "var(--theme-dark-color)",
        confirmButtonText: "Unblock Time Slot",
        style: { display: "block", marginTop: "-100px" },
        text: `Do you want to unblock the selected time slot for ${props.techName} on (${moment(
          event.start
        ).format("dddd, MMM D YYYY")}, ${moment(event.start).format("h:mm a")} - ${moment(
          event.end
        ).format(
          "h:mm a"
        )})? The selected time slot will become available for appointment bookings.`,
      }).then((result) => {
        if (result.isConfirmed) {
          onUnblockTimeSlot(event);
        } else {
          setAlert(null);
          setSelectedEvent(null);
        }
      });
    }
  };

  const onNewEventCreated = (slotInfo) => {
    if (slotInfo.start < new Date()) {
      Swal.fire(
        "Technician's Schedule",
        "Cannot create shifts or block time in the past.",
        "warning"
      );
      return;
    }

    if (!props.isTechEnabled) {
      alertTechDisabled();
      return;
    }

    Swal.fire({
      title: "Technician's Schedule",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonColor: "var(--theme-dark-color)",
      confirmButtonText: "Create Shift",
      denyButtonText: "Block Time",
      denyButtonColor: "var(--secondary-color)",
      style: { display: "block", marginTop: "-100px" },
      text: `What would you like to do with the selected time slot (${moment(slotInfo.start).format(
        "dddd, MMM D YYYY"
      )}, ${moment(slotInfo.start).format("h:mm a")} - ${moment(slotInfo.end).format("h:mm a")})?`,
    }).then((result) => {
      if (result.isConfirmed) {
        onSaveNewShift(slotInfo);
      } else if (result.isDenied) {
        setSelectedSlot(slotInfo);
        setBlockModalOpen(true);
      } else {
        setSelectedEvent(null);
        setBlockModalOpen(false);
        setSelectedSlot();
      }
    });
  };

  const onRemoveShift = (pEvent) => {
    setLoading(true);
    setAlert(null);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/technicians/shifts/${pEvent.shiftId}`)
      .then(() => {
        setEvents((ev) => ev.filter((s) => s.shiftId !== pEvent.shiftId));
        setSelectedEvent(null);

        Swal.fire({
          title: "Shift has been successfully removed.",
          icon: "success",
          showCancelButton: false,
          confirmBtnText: "OK",
        });
      })
      .catch((err) => {
        let error = parseError(err);
        Swal.fire("Shift Error", error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSaveNewShift = (slotInfo) => {
    const year = slotInfo.start.getFullYear();
    const month = slotInfo.start.getMonth() + 1;
    const day = slotInfo.start.getDate();
    const startHour = slotInfo.start.getHours();
    const startMin = slotInfo.start.getMinutes();
    const endHour = slotInfo.end.getHours();
    const endMin = slotInfo.end.getMinutes();

    const data = {
      technicianId: props.techId,
      date: {
        year: year,
        month: month,
        day: day,
      },
      startTime: {
        hour: startHour,
        minute: startMin,
      },
      endTime: {
        hour: endHour,
        minute: endMin,
      },
    };

    setAlert(null);
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/technicians/shifts`, data)
      .then((_) => {
        Swal.fire({
          title: `New shift has been created and assigned to ${props.techName}.`,
          icon: "success",
          showCancelButton: false,
        });
        getWeekSchedule(currentDate || new Date());
      })
      .catch((err) => {
        let error = parseError(err);
        Swal.fire("Shift Error", error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onBlockTimeSlot = (data) => {
    const year = data.slot.start.getFullYear();
    const month = data.slot.start.getMonth() + 1;
    const day = data.slot.start.getDate();

    const startHour = data.slot.start.getHours();
    const startMin = data.slot.start.getMinutes();
    const endHour = data.slot.end.getHours();
    const endMin = data.slot.end.getMinutes();

    const blockData = {
      technicianId: props.techId,
      notes: data.notes,
      address: data.address
        ? {
            province: data.address.province,
            city: data.address.city,
            street: data.address.street,
            postalCode: data.address.postalCode,
          }
        : null,
      location: data.address
        ? {
            latitude: data.address.latitude,
            longitude: data.address.longitude,
          }
        : null,
      date: {
        year: year,
        month: month,
        day: day,
      },
      startTime: {
        hour: startHour,
        minute: startMin,
      },
      endTime: {
        hour: endHour,
        minute: endMin,
      },
    };

    setAlert(null);
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/technicians/schedule/block`, blockData)
      .then((res) => {
        Swal.fire({
          title: `The time slot has been blocked successfully.`,
          icon: "success",
          showCancelButton: false,
        });
        getWeekSchedule(currentDate || new Date());
      })
      .catch((err) => {
        let error = parseError(err);
        Swal.fire("Shift Error", error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onUnblockTimeSlot = (pEvent) => {
    setLoading(true);
    setAlert(null);

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/technicians/${props.techId}/schedule/block/${pEvent.shiftId}`
      )
      .then(() => {
        setEvents((ev) => ev.filter((s) => s.shiftId !== pEvent.shiftId));
        setSelectedEvent(null);

        Swal.fire({
          title: "Time slot has been successfully unblocked.",
          icon: "success",
          showCancelButton: false,
          confirmBtnText: "OK",
        });
      })
      .catch((err) => {
        let error = parseError(err);
        Swal.fire("Time Slot Error", error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const eventColors = (event) => {
    var backgroundColor = "rbc-event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };

  const alertTechDisabled = () => {
    Swal.fire({
      title: "Please enable the technician's account in order to be able to manage their shifts.",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: "OK",
    });
  };

  const formats = {
    dateFormat: "D",
    dayFormat: (date, culture, localizer) => localizer.format(date, "ddd D", culture),

    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, "MMMM D", culture) + " - " + localizer.format(end, "MMMM D", culture),
  };

  return (
    <React.Fragment>
      {alert}
      <Card
        className="card-calendar"
        style={{ height: "810px", padding: 0 }}
        contentStyle={{
          position: "relative",
          height: "100%",
          padding: 0,
          margin: "0",
        }}
        content={
          <React.Fragment>
            {loading && (
              <div
                style={{
                  position: "absolute",
                  background: "rgba(255, 255, 255, 0.8)",
                  width: "100%",
                  height: "100%",
                  zIndex: 10,
                }}
              >
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ height: "100%" }}
                >
                  <Loader />
                  <p className="text-xs text-gray-400 mt-6" style={{ color: "var(--theme-color)" }}>
                    Please wait...
                  </p>
                </div>
              </div>
            )}
            <BigCalendar
              formats={formats}
              selectable
              selectedEvent={selectedEvent}
              localizer={localizer}
              events={events}
              defaultView="week"
              step={15}
              timeslots={4}
              scrollToTime={new Date(1970, 1, 1, 6)}
              defaultDate={new Date()}
              onSelectEvent={(event) => onSelectEvent(event)}
              views={["week"]}
              onNavigate={onDatesChanged}
              onSelectSlot={
                props.isTechEnabled ? (slotInfo) => onNewEventCreated(slotInfo) : alertTechDisabled
              }
              eventPropGetter={eventColors}
            />
          </React.Fragment>
        }
      />

      {blockModalOpen && (
        <BlockTimeModal
          timeSlot={selectedSlot}
          onConfirm={onBlockTimeSlot}
          onClose={() => setBlockModalOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

export default TechSchedule;
