import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { NavDropdown, MenuItem } from "react-bootstrap";
import Svg from "../../components/Svg/Svg.jsx";
import { logout, getUser } from "api/auth";

class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: true,
      userData: getUser(),
    };
  }
  onLogout = () => {
    logout();
    this.props.history.push("/auth/login");
  };

  render() {
    return (
      <div className="nav flex items-center">
        <NavDropdown
          style={{ height: "44px" }}
          direction="down right"
          id="user-profile-dropdown"
          title={
            <div className="flex items-center gap-12" style={{ height: "44px" }} style={{ margin: "-8px 0px -12px 0px" }}>
              <div className="flex flex-col text-right">
                <span className="main-text text-semibold text-xxs">
                  {this.state.userData.firstName} {this.state.userData.lastName}
                </span>
                <span className="sub-text text-regular text-xxs text-nowrap">{this.state.userData.email}</span>
              </div>
              <Svg name="profile" className="flex-shrink-0" style={{ width: "40px", height: "40px" }} />
              <Svg name="chevron-down" className="flex-shrink-0" style={{ width: "20px", height: "20px", transform: "rotate(180deg)" }} />
            </div>
          }
          noCaret
        >
          <MenuItem style={{ width: "240px" }} title="Logout from Account" onClick={() => this.onLogout()}>
            <div className="main-text flex items-center gap-4">
              <Svg name="logout" className="w-5 h-5 sub-text" />
              Logout
            </div>
          </MenuItem>
        </NavDropdown>
      </div>
    );
  }
}
export default withRouter(HeaderLinks);
