import React, { Component } from "react";
import { FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import Swal from "sweetalert2";
import ReactTable from "react-table";
import { parseError } from "api/common.js";
import Svg from "components/Svg/Svg.jsx";

class Admins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      disableBtn: false,
      emailErrMsg: "",
      data: [],
      nextPageToken: "",
      loading: true,
    };
  }

  componentDidMount() {
    this.getAdmins();
  }

  getAdmins = (pageToken = null, isBackward = null) => {
    const self = this;
    this.setState({ loading: true, data: [] });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/admin/all?pageSize=${10}&paginationToken=${pageToken || ""}&isBackward=${
          isBackward ? "true" : "false"
        }`
      )
      .then((res) => {
        let paginationToken = res.data.nextPageToken;
        isBackward = true;
        if (isBackward) {
          paginationToken = res.data.previousPageToken;
        }
        self.setState({
          loading: false,
          nextPageToken: res.data.nextPageToken,
          previousPageToken: res.data.previousPageToken,
          data: res.data.items.map((prop, key) => {
            return {
              id: prop.id,
              picture: prop.picture,
              firstName: prop.firstName,
              lastName: prop.lastName,
              email: prop.email,
              phone: prop.phone,
              actions: (
                <div className="actions-right">
                  <Button
                    onClick={() => self.deleteAdmin(prop)}
                    title="Delete Admin Account"
                    bsStyle="danger"
                    outline="true"
                    style={{ padding: "7px 10px" }}
                  >
                    <Svg name="trash" className="w-4 h-4" />
                  </Button>
                </div>
              ),
            };
          }),
        });
      })
      .catch((err) => {
        let error = parseError(err);
        self.setState({ loading: false });
        Swal.fire("Admins Error", error, "error");
      });
  };

  deleteAdmin = (admin) => {
    Swal.fire({
      title: `Are you sure you want to delete this admin account (${admin.firstName} ${admin.lastName})?`,
      text: "You won't be able to revert this.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: `Delete ${admin.firstName} ${admin.lastName}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ loading: true });

        axios
          .delete(`${process.env.REACT_APP_API_URL}/Admin/${admin.id}`)
          .then((res) => {
            this.setState({ loading: false });
            this.getAdmins();

            Swal.fire({
              title: `${admin.firstName} ${admin.lastName} account has been deleted`,
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
            if (err.response && err.response.status === 403) {
              Swal.fire({
                text: "Sorry, you do not have permission to delete admin accounts.",
                icon: "error",
              });
            } else {
              let error = parseError(err);
              Swal.fire("Delete Error", error, "error");
            }
          });
      }
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  handleEmailChange = (e) => {
    const email = e.target.value;
    const emailValid = this.validateEmail(email);
    this.setState({ validEmail: emailValid });

    if (!emailValid) {
      this.setState({ emailErrMsg: "Email is required and format should be john@doe.com." });
    } else this.setState({ emailErrMsg: "" });
  };

  sendInvite = () => {
    this.setState({ disableBtn: true });
    const vm = this;
    const obj = {
      email: vm.state.email,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/Admin/Invite`, obj)
      .then((res) => {
        this.setState({ disableBtn: false });
        Swal.fire({
          title: "Invite sent",
          icon: "success",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.setState({ email: "" });
            window.location.reload();
          }
        });
      })
      .catch((error) => {
        this.setState({ disableBtn: false });
        Swal.fire(error.response.data.message, error.response.data.errorList[0]).then((result) => {
          window.location.reload();
        });
      });
  };

  render() {
    return (
      <div className="main-content users flex flex-col gap-24">
        <Card
          style={{ maxWidth: "460px" }}
          title="Send An Invitation To A New Admin"
          content={
            <div className="flex w-full items-center gap-12">
              <FormGroup style={{ width: "100%" }}>
                <ControlLabel>Email address</ControlLabel>
                <FormControl
                  placeholder="Email"
                  type="email"
                  onChange={this.onChange}
                  onBlur={this.handleEmailChange}
                  name="email"
                  required
                />
                <span className="text-danger">{this.state.emailErrMsg}</span>
              </FormGroup>
              <Button
                style={{ marginTop: "8px", height: "38px" }}
                type="submit"
                onClick={this.sendInvite}
                bsStyle="danger"
                fill
                disabled={!this.state.email || this.state.disableBtn || this.state.emailErrMsg}
              >
                Send Invite
              </Button>
            </div>
          }
        />

        <Card
          title="Admin Accounts"
          content={
            <div className="flex flex-col gap-12">
              <ReactTable
                loading={this.state.loading}
                loadingText="Loading accounts..."
                noDataText={this.state.error ? this.state.error : this.state.loading ? "" : "No accounts found..."}
                data={this.state.data}
                filterable
                defaultFilterMethod={this.filterMethod}
                columns={[
                  {
                    Header: "First Name",
                    accessor: "firstName",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Last Name",
                    accessor: "lastName",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Phone",
                    accessor: "phone",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Email",
                    accessor: "email",
                    sortable: false,
                    filterable: false,
                    minWidth: 250,
                    style: { whiteSpace: "unset" },
                  },
                  {
                    Header: "",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    maxWidth: 60,
                    className: "actions-right",
                  },
                ]}
                defaultPageSize={10}
                showPaginationBottom={false}
                className="-striped -highlight"
              />
              <div style={{ marginLeft: "auto" }} className="flex items-center">
                <Button
                  className="pagination-btn left btn-fill"
                  disabled={!this.state.previousPageToken || this.state.loading}
                  onClick={() => this.getAdmins(this.state.previousPageToken, true)}
                  bsStyle="default"
                  fill
                >
                  <Svg name="chevron-left" className="w-6 h-6" />
                </Button>
                <Button
                  className="pagination-btn right btn-fill"
                  disabled={!this.state.nextPageToken || this.state.loading}
                  onClick={() => this.getAdmins(this.state.nextPageToken, false)}
                  bsStyle="default"
                  fill
                >
                  <Svg name="chevron-right" className="w-6 h-6" />
                </Button>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default Admins;
