import React, { useState, useEffect } from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import moment from "moment";
import Datetime from "react-datetime";
import Select from "react-select";
import Swal from "sweetalert2";
import Switch from "react-switch";
import ReactTable from "react-table";
import axios from "axios";
import { parseError } from "api/common";
import NumberFormat from "react-number-format";
import Svg from "components/Svg/Svg";
import { dateFormat } from "api/common";
import { dateTimeFormat } from "api/common";

const EditPromocode = (props) => {
  const [codeId, setCodeId] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [value, setValue] = useState(1);
  const [expireAt, setExpiration] = useState(null);
  const [type, setType] = useState({ value: "fixed", label: "Fixed" });
  const [maxRedemptions, setRedemptions] = useState(1);
  const [loading, setLoading] = useState(false);
  const [types] = useState([
    { label: "Fixed", value: "fixed" },
    { label: "Percentage", value: "percentage" },
  ]);
  const [active, setActive] = useState(false);
  const [dataHistory, setDataHistory] = useState([]);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [pageSize] = useState(10);

  const disablePastDt = (current) => {
    return current.isAfter(moment().add(-1, "days"));
  };

  useEffect(() => {
    const id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    setCodeId(id);
  }, []);

  useEffect(() => {
    if (codeId) {
      getDiscountCode(codeId);
    }
  }, [codeId]);

  const getDiscountCode = (id) => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/discounts/${encodeURIComponent(id)}`)
      .then((res) => {
        setName(res.data.name);
        setCode(res.data.code);
        setType(types.find((t) => t.value === res.data.type));
        setActive(res.data.active);
        setExpiration(
          moment(
            `${res.data.expireAt.month}/${res.data.expireAt.day}/${res.data.expireAt.year}`,
            "M/D/YYYY"
          ).toDate()
        );
        setRedemptions(res.data.maxRedemptions || 1);

        if (res.data.type == "fixed") {
          setValue(parseFloat(res.data.value / 100));
        } else if (res.data.type == "percentage") {
          setValue(parseInt(res.data.value));
        }

        getDiscountHistory();
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Promo Code Error", error, "error");
      });
  };

  const getDiscountHistory = (pageToken = null, isBackward = null) => {
    setLoading(true);
    setDataHistory([]);

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/discounts/${codeId}/history?pageSize=${pageSize}&paginationToken=${
          pageToken || ""
        }&isBackward=${isBackward ? "true" : "false"}`
      )
      .then((res) => {
        setLoading(false);
        setNextPageToken(res.data.nextPageToken);
        setPreviousPageToken(res.data.previousPageToken);

        setDataHistory(
          res.data.items.map((prop) => {
            return {
              id: prop.id,
              usedAt: formatDate(prop.usedAt),
              patientName: `${prop.patient.firstName} ${prop.patient.lastName}`,
              email: prop.patient.email,
              appointment: (
                <Link to={`/admin/appointments/${prop.appointmentId}`} title="View Appointment">
                  {prop.appointmentId}
                </Link>
              ),
              amount: `$${new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                signDisplay: "never",
              }).format(prop.amount != 0 ? prop.amount / 100.0 : 0)}`,
            };
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Promo History Error", error, "error");
      });
  };

  const changeDate = (event) => {
    setExpiration(event.toDate());
  };

  const updateDiscount = () => {
    setLoading(true);

    let priceValue = value;
    if (type.value === "fixed") {
      priceValue = priceValue * 100;
    }

    const data = {
      name: name,
      code: code,
      value: priceValue,
      expireAt: {
        year: expireAt.getFullYear(),
        month: expireAt.getMonth() + 1,
        day: expireAt.getDate(),
      },

      type: type.value,
      maxRedemptions: maxRedemptions,
      active: active,
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/discounts/${codeId}`, data)
      .then((res) => {
        setLoading(false);
        Swal.fire({
          title: "Promo Code Has Been Updated",
          icon: "success",
          showCancelButton: false,
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Promo Code Error", error, "error");
      });
  };

  const generateCode = async () => {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_API_URL}/discounts/generate-code`)
      .then((res) => {
        setLoading(false);
        setCode(res.data.code);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Generate Error", error, "error");
      });
  };

  const formatDate = (inDate) => {
    if (!inDate) {
      return "--";
    }
    let utcIsoString = `${inDate.year}-${inDate.month.toString().padStart(2, "0")}-${inDate.day
      .toString()
      .padStart(2, "0")}T${inDate.hour.toString().padStart(2, "0")}:${inDate.minute
      .toString()
      .padStart(2, "0")}:00.000Z`;

    let utcDate = moment(utcIsoString);
    return utcDate.format(dateTimeFormat);
  };

  const toggleIsActive = () => {
    setActive(!active);
  };

  return (
    <div className="main-content promo">
      <Button
        simple
        icon
        style={{ padding: "0", marginBottom: "12px", marginRight: "auto" }}
        onClick={() => {
          props.history.go(-1);
        }}
      >
        <Svg name="chevron-left" className="w-6 h-6" />
        <span className="text-bold">Back To List</span>
      </Button>
      <div className="flex flex-col">
        <Card
          title="Promo Code Details"
          content={
            <div className="flex flex-col">
              <div className="grid grid-2col gap-6">
                <div className="switch">
                  <FormGroup>
                    <Switch
                      disabled={loading}
                      onChange={toggleIsActive}
                      checked={active}
                      offColor="#E6E6E6"
                      onColor="#ffc6c9"
                      onHandleColor="#EA1B27"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={40}
                    />
                    <ControlLabel>IS ACTIVE (ALLOWED TO REDEEM)</ControlLabel>
                  </FormGroup>
                </div>

                <FormGroup style={{ gridColumn: "1 / span 2" }}>
                  <ControlLabel>Promo Name:</ControlLabel>
                  <FormControl
                    disabled={loading}
                    required
                    type="text"
                    name="name"
                    placeholder="Promo Code Name (only visible to admins)"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                </FormGroup>
                <div className="flex items-center gap-10" style={{ gridColumn: "1 / span 2" }}>
                  <FormGroup className="w-full">
                    <ControlLabel>Code:</ControlLabel>
                    <FormControl
                      disabled={loading}
                      required
                      type="text"
                      name="code"
                      placeholder="Code"
                      value={code}
                      onChange={(event) => {
                        setCode(event.target.value);
                      }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel></ControlLabel>
                    <Button
                      disabled={loading}
                      style={{ width: "100%", marginTop: "3px" }}
                      title="Generate Random Unique Code"
                      fill
                      bsStyle="danger"
                      onClick={generateCode}
                      disabled={loading}
                    >
                      <Svg className="w-4 h-4" name="refresh" />
                      Generate Code
                    </Button>
                  </FormGroup>
                </div>

                <FormGroup>
                  <ControlLabel>
                    Type: <span className="star">*</span>
                  </ControlLabel>
                  <Select
                    isDisabled={loading}
                    name="type-select"
                    isClearable={false}
                    className="react-select react-select-icon"
                    isSearchable={true}
                    placeholder="Select Type"
                    isMulti={false}
                    value={type}
                    onChange={(opt) => {
                      setType(opt);
                      if (opt.value === "percentage") {
                        setValue(parseInt(value));
                      }
                    }}
                    options={types}
                    formatGroupLabel={(data) => (
                      <div className="flex items-center text-theme font-semibold">{data.label}</div>
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Value in {type.value === "percentage" ? "%" : "$"}:</ControlLabel>
                  <div className="flex items-center">
                    <NumberFormat
                      value={value}
                      defaultValue={0}
                      decimalScale={type.value === "percentage" ? 0 : 2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      allowEmptyFormatting={true}
                      suffix={type.value === "percentage" ? "%" : ""}
                      prefix={type.value === "fixed" ? "$" : ""}
                      className="form-control"
                      inputMode="numeric"
                      placeholder="Discount Value"
                      isAllowed={({ floatValue }) => {
                        return (
                          floatValue == undefined ||
                          floatValue <= (type.value === "percentage" ? 100 : 9999999.99)
                        );
                      }}
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setValue(
                          value
                            ? type.value === "percentage"
                              ? parseInt(value)
                              : parseFloat(value)
                            : 0
                        );
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Maximum Redemptions:</ControlLabel>
                  <FormControl
                    required
                    disabled={loading}
                    type="number"
                    name="maxRedemptions"
                    placeholder="Maximum Redemptions"
                    min={0}
                    max={999999999}
                    value={maxRedemptions}
                    onChange={(event) => {
                      setRedemptions(parseInt(event.target.value));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    Expiry: <span className="star">*</span>
                  </ControlLabel>
                  <Datetime
                    dateFormat={dateFormat}
                    disabled={loading}
                    timeFormat={false}
                    inputProps={{ placeholder: "Select Expiration Date" }}
                    isValidDate={disablePastDt}
                    value={expireAt}
                    onChange={changeDate}
                    closeOnSelect={true}
                  />
                </FormGroup>
              </div>

              <Button
                loading={loading}
                style={{ marginLeft: "auto" }}
                disabled={
                  loading ||
                  !name ||
                  !expireAt ||
                  !value ||
                  !maxRedemptions ||
                  !type ||
                  !type.value ||
                  !code
                }
                bsStyle="danger"
                fill
                onClick={() => updateDiscount()}
              >
                Save Changes
              </Button>
            </div>
          }
        />

        <Card
          title="Redemption History"
          content={
            <div className="flex flex-col">
              <ReactTable
                noDataText={"No redemptions found..."}
                data={dataHistory}
                filterable
                columns={[
                  {
                    Header: "Redeemed By",
                    accessor: "patientName",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Email",
                    accessor: "email",
                    sortable: false,
                    filterable: false,
                    style: { whiteSpace: "unset" },
                  },
                  {
                    Header: "Redeemed On",
                    accessor: "usedAt",
                    sortable: false,
                    filterable: false,
                    maxWidth: 150,
                  },
                  {
                    Header: "Discount",
                    accessor: "amount",
                    sortable: false,
                    filterable: false,
                    maxWidth: 110,
                  },
                  {
                    Header: "Appointment",
                    accessor: "appointment",
                    sortable: false,
                    filterable: false,
                    maxWidth: 130,
                  },
                ]}
                defaultPageSize={pageSize}
                showPaginationBottom={false}
                className="-striped -highlight"
              />
              <div className="flex items-center" style={{ marginLeft: "auto" }}>
                <Button
                  className="pagination-btn left btn-fill"
                  disabled={!previousPageToken || loading}
                  onClick={() => getDiscountHistory(previousPageToken, true)}
                  bsStyle="default"
                  fill
                >
                  <Svg name="chevron-left" className="w-6 h-6" />
                </Button>
                <Button
                  className="pagination-btn right btn-fill"
                  disabled={!nextPageToken || loading}
                  onClick={() => getDiscountHistory(nextPageToken, false)}
                  bsStyle="default"
                  fill
                >
                  <Svg name="chevron-right" className="w-6 h-6" />
                </Button>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default EditPromocode;
