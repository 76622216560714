import React, { useState } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import { parseError } from "api/common.js";
import Svg from "components/Svg/Svg.jsx";
import Swal from "sweetalert2";
import PlaceholderImage from "../../../assets/img/placeholder.svg";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import { useEffect } from "react";

function SortableServiceItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="service-drag-container"
    >
      <div className="service-card">
        <img
          src={
            props.service.picture && props.service.picture.url
              ? props.service.picture.url
              : PlaceholderImage
          }
          alt={props.service.title || ""}
        />
        <div className="flex flex-col gap-2 overflow-hidden">
          <span className="service-title">{props.service.title}</span>
          <span className="service-price">{`$${new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            signDisplay: "never",
          }).format(props.service.price > 0 ? props.service.price / 100.0 : 0)}`}</span>
        </div>

        <div className="flex flex-col items-center gap-4 ml-auto">
          <Button
            bsStyle="default"
            outline="true"
            style={{ padding: "2px 4px" }}
            title="View or Edit Service"
            onClick={() => {
              if (props.onEdit) {
                props.onEdit(props.service);
              }
            }}
          >
            <Svg name="edit" className="w-4 h-4" />
          </Button>
          <Button
            outline="true"
            bsStyle="danger"
            title="Delete Service"
            style={{ padding: "2px 4px" }}
            onClick={() => {
              if (props.onDelete) {
                props.onDelete(props.service);
              }
            }}
          >
            <Svg name="trash" className="w-4 h-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}

const ServicesGridView = (props) => {
  const [data, setData] = useState(props.services || []);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  useEffect(() => {
    setData(props.services || []);
  }, [props.services]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setData((items) => {
        const oldIndex = items.findIndex((s) => s.id === active.id);
        const newIndex = items.findIndex((s) => s.id === over.id);

        let orderedList = arrayMove(items, oldIndex, newIndex);
        updateOrder(orderedList);
        return orderedList;
      });
    }
  };

  const updateOrder = (services) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/services/folders/${props.folder.id}/services`, {
        services: Object.fromEntries(services.map((s) => [`SE#${s.id}`, services.indexOf(s) + 1])),
      })
      .then(() => {})
      .catch((err) => {
        let error = parseError(err);
        Swal.fire("Order Update Error", error, "error");
      });
  };

  return (
    <div
      className="services-grid"
      style={{
        margin: "0 16px",
        marginTop: "-8px",
        border: "1px solid #efefef",
        background: "#fdfdfd",
        padding: "16px",
        paddingTop: "24px",
        borderRadius: "5px",
      }}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToParentElement]}
      >
        <SortableContext items={data} strategy={rectSortingStrategy}>
          {data.map((service) => (
            <SortableServiceItem
              key={service.id}
              id={service.id}
              service={service}
              onEdit={(svc) => props.onEdit(svc)}
              onDelete={(svc) => props.onDelete(svc)}
              index={data.findIndex((s) => s.id === service.id) + 1}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default ServicesGridView;
