import React, {useState, useEffect, useRef} from "react";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import Card from "components/Card/Card.jsx";
import Select from 'react-select';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactDatetime from "react-datetime";
import axios from "axios";
import moment from "moment";
import {dateFormat, parseError} from "api/common.js";
import "react-phone-number-input/style.css";
import SelectLocationModal from "./SelectLocationModal";
import StripeCard from "./StripeHelper";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import Svg from "../../../components/Svg/Svg";
import Swal from "sweetalert2";
import CreditCardModel from "../../../components/Card/CreditCardModel";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_URL);

const AddUserModal = ({ show, onClose }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [gender, setGender] = useState({ value: "" });
    const [genders, setGenders] = useState([]);
    const [address, setAddress] = useState("");
    const [addressData, setAddressData] = useState(null);
    const [location, setLocation] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showCardModal, setShowCardModal] = useState(false);
    const [stripeToken, setStripeToken] = useState(null);
    const [cardModel, setCardModel] = useState(null);

    const handleEmailChange = (event) => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = re.test(event.target.value) === true;
        if (!isValid && event.target.value) {
            setEmailError(
                <small className="text-danger">Invalid email address format. Please make sure that you are using valid email address.</small>
            );
        } else {
            setEmailError(null);
        }
    };

    const onSelectAddress = (address, location, addressData) => {
        setAddress(address);
        setLocation(location);
        setAddressData(addressData);
    }

    const setCardInfo = (card) => {
        setStripeToken(card.token);
        setCardModel({
            brand: card.info.brand,
            last4: card.info.last4
        });
        setShowCardModal(false);
    }

    const onCreateAccount = () => {
        setLoading(true);
        setError(null);
        const creationRequest = {
            email: email,
            phone: phone,
            firstName: firstName,
            lastName: lastName,
            gender: gender.value,
            dateOfBirth: {
                year: dateOfBirth.getFullYear(),
                month: dateOfBirth.getMonth() + 1,
                day: dateOfBirth.getDate()
            },
            address: addressData,
            location: location,
            stripeToken: stripeToken
        };
        axios.post(`${process.env.REACT_APP_API_URL}/account/addpatient`, creationRequest)
            .then(res => {
                setLoading(false);
                Swal.fire({
                    title: "The patient's account has been created.",
                    icon: "success",
                    showCancelButton: false,
                }).then(result => {
                    if (result.isConfirmed)
                        onClose();
                });
            })
            .catch((err) => {
                let error = parseError(err);
                setLoading(false);
                setError(error);
            });
    };

    useEffect(() => {
        if (show) {
            axios.get(`${process.env.REACT_APP_API_URL}/account/genders`)
                .then((res) => {
                    setGenders(res.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setFirstName("");
            setLastName("");
            setEmail("");
            setEmailError("");
            setPhone("");
            setPhoneError("");
            setGender({ value: "" });
            setAddress("");
            setAddressData(null);
            setLocation(null);
            setDateOfBirth(null);
            setError(null);
            setLoading(false);
            setShowCardModal(false);
            setStripeToken(null);
            setCardModel(null);
        }
    }, [show]);

    return (
        <Modal show={show} onHide={() => onClose()} backdrop="static" >
            <Modal.Header closeButton>
                <Modal.Title>
                    Create Patient Account
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="flex flex-col">
                    <Card
                        content={
                            <>
                                <div className="grid gap-6" style={{ gridTemplateColumns: "repeat(2,minmax(0,1fr))", gridColumnGap: "16px" }}>
                                    <FormGroup>
                                        <ControlLabel>
                                            First Name: <span className="star">*</span>
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            maxLength="32"
                                            name="firstName"
                                            value={firstName}
                                            onChange={(event) => {
                                                setFirstName(event.target.value.replace(/[^a-z\-\' ]/gi, ""));
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>
                                            Last Name: <span className="star">*</span>
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            maxLength="32"
                                            name="lastName"
                                            value={lastName}
                                            onChange={(event) => {
                                                setLastName(event.target.value.replace(/[^a-z\-\' ]/gi, ""));
                                            }}
                                        />
                                    </FormGroup>
                                    <div className="form-group phone-input">
                                        <ControlLabel>
                                            Phone Number: <span className="star">*</span>
                                        </ControlLabel>
                                        <PhoneInput
                                            maxLength="16"
                                            max="10"
                                            defaultCountry="CA"
                                            extension="true"
                                            value={phone}
                                            onChange={(value) => {
                                                setPhone(value);
                                                if (value && !isPossiblePhoneNumber(value)) {
                                                    setPhoneError(<small className="text-danger">A valid Canadian or US phone number is required.</small>);
                                                } else {
                                                    setPhoneError(null);
                                                }
                                            }}
                                            required
                                        />
                                        {phoneError}
                                    </div>
                                    <FormGroup>
                                        <ControlLabel>
                                            Email address: <span className="star">*</span>
                                        </ControlLabel>
                                        <FormControl
                                            maxLength="100"
                                            type="text"
                                            name="email"
                                            onChange={(event) => setEmail(event.target.value)}
                                            onBlur={(event) => handleEmailChange(event)}
                                            required
                                        />
                                        {emailError}
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>
                                            Gender: <span className="star">*</span>
                                        </ControlLabel>
                                        <Select
                                            name="gender-select"
                                            isClearable={false}
                                            className="react-select react-select-icon"
                                            isSearchable={true}
                                            placeholder="Select Gender"
                                            noOptionsMessage={() => "No Genders Loaded"}
                                            isMulti={false}
                                            value={gender}
                                            onChange={(opt) => {
                                                setGender(opt);
                                            }}
                                            options={genders.map((s) => {
                                                return { label: s.value, value: s.key };
                                            })}
                                            styles={{
                                                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                                menu: (provided) => ({ ...provided, zIndex: 9999 })
                                            }}
                                            formatGroupLabel={(data) => <div className="flex items-center text-theme font-semibold">{data.label}</div>}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>
                                            Date of Birth: <span className="star">*</span>
                                        </ControlLabel>
                                        <ReactDatetime
                                            dateFormat={dateFormat}
                                            inputProps={{
                                                required: true,
                                                placeholder: "Select Date",
                                                onKeyDown: (ev) => ev.preventDefault()
                                            }}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={e => {
                                                setDateOfBirth(null);
                                                setDateOfBirth(moment(e).toDate())
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                                <FormGroup className="gap-16">
                                    <ControlLabel>
                                        <div>Card Information: <span className="star">*</span></div>
                                    </ControlLabel>
                                    {
                                        showCardModal &&
                                        <Elements stripe={stripePromise} >
                                            <StripeCard setCardInfo={setCardInfo}/>
                                        </Elements>
                                    }
                                    {
                                        cardModel &&
                                        <CreditCardModel data={{
                                            brand: "card",
                                            last4: cardModel.last4
                                        }} />
                                    }
                                    {
                                        !showCardModal &&
                                        <Button
                                            bsStyle="danger"
                                            fill
                                            style={{ marginLeft: "auto" }}
                                            onClick={() => {
                                                setShowCardModal(true);
                                            }}
                                        >
                                            <Svg name="card" className="w-4 h-4" />
                                            {cardModel ? "Update Card" : "Add Card"}
                                        </Button>
                                    }
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>
                                        Address: <span className="star">*</span>
                                    </ControlLabel>
                                    {<div>{address ? address : "No address selected"}</div>}
                                    <SelectLocationModal onSelectAddress={onSelectAddress} />
                                </FormGroup>
                            </>
                        }
                    />

                    {error && (
                        <div className="error-alert" style={{ gridColumn: "1 / span 2", marginTop: "12px" }}>
                            {error}
                        </div>
                    )}

                    <div className="flex items-center gap-10" style={{ marginLeft: "auto", marginTop: "16px" }}>
                        <Button disabled={loading} bsStyle="danger" outline="true" onClick={() => onClose()}>
                            Cancel
                        </Button>

                        <Button
                            loading={loading}
                            bsStyle="danger"
                            fill
                            onClick={() => onCreateAccount()}
                            disabled={
                                !firstName || !lastName || !email || !phone || !gender || !dateOfBirth || !address || !stripeToken || phoneError || emailError || loading
                            }
                        >
                            Create Account
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddUserModal;