import React, { useEffect, useState } from "react";
import moment from "moment";
import StatsCard from "../../../components/Card/StatsCard";
import ReactDatetime from "react-datetime";
import Svg from "components/Svg/Svg";
import ReactTable from "react-table";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import { dateFormat, parseError, renderDatePickerInput } from "../../../api/common";
import Loader from "../../Components/Loader/Loader";
import Card from "components/Card/Card";

const Dashboard = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [earningsData, setEarningsData] = useState(null);
  const [fromDate, setFromDate] = useState(moment().startOf("month").toDate());
  const [toDate, setToDate] = useState(moment().endOf("month").toDate());

  const getEarnings = () => {
    setEarningsData(null);
    setLoading(true);
    setError(null);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/earnings?from=${moment(fromDate)
          .startOf("day")
          .toISOString()}&to=${moment(toDate).endOf("day").toISOString()}`
      )
      .then((res) => {
        setLoading(false);
        setEarningsData(res.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(parseError(error));
      });
  };

  useEffect(() => {
    getEarnings();
  }, []);

  var isValidFromDate = (current) => {
    return toDate ? current.isSameOrBefore(toDate) : current.isBefore(moment());
  };

  var isValidEndDate = (current) => {
    return fromDate ? current.isSameOrAfter(fromDate) : current.isBefore(moment());
  };

  return (
    <div className="main-content dashboard">
      <div className="flex flex-col gap-16">
        <Card
          title="Company Earnings Report"
          content={
            <div className="flex flex-col gap-12">
              <div className="flex items-center gap-16">
                <div className="flex items-center gap-10">
                  <ReactDatetime
                    dateFormat={dateFormat}
                    value={fromDate}
                    renderInput={renderDatePickerInput}
                    closeOnSelect={true}
                    inputProps={{
                      disabled: loading,
                      placeholder: "From Date",
                      clearable: false,
                    }}
                    timeFormat={false}
                    isValidDate={isValidFromDate}
                    onChange={(e) => setFromDate(e ? moment(e).toDate() : null)}
                  />

                  <ReactDatetime
                    dateFormat={dateFormat}
                    value={toDate}
                    closeOnSelect={true}
                    renderInput={renderDatePickerInput}
                    inputProps={{
                      disabled: loading,
                      placeholder: "To Date",
                      clearable: false,
                    }}
                    timeFormat={false}
                    isValidDate={isValidEndDate}
                    onChange={(e) => setToDate(e ? moment(e).toDate() : null)}
                  />
                </div>

                <Button
                  disabled={loading || !fromDate || !toDate}
                  loading={loading}
                  style={{ height: "38px", marginLeft: "-6px" }}
                  bsStyle="danger"
                  fill
                  onClick={() => getEarnings()}
                >
                  Load Earnings
                </Button>
              </div>

              <span className="sub-text text-xxs">
                You can request an earnings report for a custom period of time, but the periods’s
                duration cannot be more than 6 months.
              </span>
            </div>
          }
        />

        {loading && (
          <div className="flex flex-col items-center justify-center">
            <Loader />
            <p className="text-xs text-gray-400 mt-6" style={{ color: "var(--theme-color)" }}>
              Loading earnings details...
            </p>
          </div>
        )}
        {error && (
          <div
            className="error-alert"
            style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
          >
            {error}
          </div>
        )}

        {!loading && !error && earningsData && (
          <React.Fragment>
            <div className="grid grid-3col gap-16">
              <StatsCard
                className="col-3span"
                bigIcon={
                  <Svg
                    style={{ width: "44px", height: "44px", color: "var(--theme-color)" }}
                    name="receipt"
                  />
                }
                statsText="Balance"
                statsValue={`$${new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  signDisplay: "never",
                }).format(earningsData.balanceAmount > 0 ? earningsData.balanceAmount / 100 : 0)}`}
              >
                <div className="flex items-center gap-24 text-right" style={{ marginTop: "6px" }}>
                  <div className="flex flex-col">
                    <span className="text-semibold theme-text">
                      {`$${new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(
                        earningsData.unpaidCommissions + earningsData.unpaidTips > 0
                          ? (earningsData.unpaidCommissions + earningsData.unpaidTips) / 100
                          : 0
                      )}`}
                    </span>
                    <span className="sub-text text-xxs">Pending Liability</span>
                  </div>
                </div>

                <span style={{ marginTop: "12px" }} className="sub-text text-xxs">
                  The displayed amounts do not include any payment processing fees from Stripe.
                </span>
              </StatsCard>

              <StatsCard
                bigIcon={
                  <Svg style={{ width: "44px", height: "44px", color: "#0e56eb" }} name="card" />
                }
                statsText="Payments"
                statsValue={`$${new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  signDisplay: "never",
                }).format(
                  earningsData.totalPaymentsAmount > 0 ? earningsData.totalPaymentsAmount / 100 : 0
                )}`}
              >
                <div className="flex items-start gap-24 text-right" style={{ marginTop: "6px" }}>
                  <div className="flex flex-col">
                    <span className="text-semibold theme-text">
                      {`$${new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(
                        earningsData.refundedAmount > 0 ? earningsData.refundedAmount / 100 : 0
                      )}`}
                    </span>
                    <span className="sub-text text-xxs">Refunded</span>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-semibold success-text">
                      {`$${new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(
                        earningsData.cancellationFeesAmount > 0
                          ? earningsData.cancellationFeesAmount / 100
                          : 0
                      )}`}
                    </span>
                    <span className="sub-text text-xxs">Cancellation Fees</span>
                  </div>
                </div>
              </StatsCard>

              <StatsCard
                bigIcon={
                  <Svg style={{ width: "44px", height: "44px", color: "#f49057" }} name="users" />
                }
                statsText="Techs' Commissions"
                statsValue={`$${new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  signDisplay: "never",
                }).format(
                  earningsData.totalCommissions > 0 ? earningsData.totalCommissions / 100 : 0
                )}`}
              >
                <div className="flex items-start gap-24 text-right" style={{ marginTop: "6px" }}>
                  <div className="flex flex-col">
                    <span className="text-semibold theme-text">
                      {`$${new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(
                        earningsData.paidCommissions > 0 ? earningsData.paidCommissions / 100 : 0
                      )}`}
                    </span>
                    <span className="sub-text text-xxs">Paid</span>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-semibold success-text">
                      {`$${new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(
                        earningsData.unpaidCommissions > 0
                          ? earningsData.unpaidCommissions / 100
                          : 0
                      )}`}
                    </span>
                    <span className="sub-text text-xxs">Unpaid</span>
                  </div>
                </div>
              </StatsCard>

              <StatsCard
                bigIcon={
                  <Svg style={{ width: "44px", height: "44px", color: "#20b251" }} name="cash" />
                }
                statsText="Techs' Tips"
                statsValue={`$${new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  signDisplay: "never",
                }).format(earningsData.totalTips > 0 ? earningsData.totalTips / 100 : 0)}`}
              >
                <div className="flex items-start gap-24 text-right" style={{ marginTop: "6px" }}>
                  <div className="flex flex-col">
                    <span className="text-semibold theme-text">
                      {`$${new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(earningsData.paidTips > 0 ? earningsData.paidTips / 100 : 0)}`}
                    </span>
                    <span className="sub-text text-xxs">Paid</span>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-semibold success-text">
                      {`$${new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(earningsData.unpaidTips > 0 ? earningsData.unpaidTips / 100 : 0)}`}
                    </span>
                    <span className="sub-text text-xxs">Unpaid</span>
                  </div>
                </div>
              </StatsCard>
            </div>

            <Card
              title="Services Earnings"
              className="flex flex-col"
              content={
                <ReactTable
                  loading={loading}
                  loadingText="Loading services data..."
                  noDataText={error ? error : loading ? "" : "No services data found..."}
                  data={earningsData.services.map((s) => {
                    return {
                      name: s.title,
                      amount: `$${new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: "never",
                      }).format(s.totalPayments > 0 ? s.totalPayments / 100 : 0)}`,
                      count: s.totalCount,
                    };
                  })}
                  columns={[
                    {
                      Header: "Service",
                      accessor: "name",
                      sortable: false,
                    },
                    {
                      Header: "Total Payments",
                      accessor: "amount",
                      sortable: false,
                      maxWidth: 160,
                    },
                    {
                      Header: "Total Requests",
                      accessor: "count",
                      sortable: false,
                      maxWidth: 140,
                    },
                  ]}
                  defaultPageSize={
                    earningsData.services.length > 0 ? earningsData.services.length : 10
                  }
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              }
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
