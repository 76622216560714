import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";
import showPass from "../../assets/img/show-icon.svg";
import hidePass from "../../assets/img/hide-icon.svg";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";
// import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      alert: "",
      email: "",
      validEmail: true,
      emailErrMsg: "",
      newType: 'password',
      confirmType: 'password',
      password: "",
      cfpassword: "",
      passwordError: null,
      cfpasswordError: null,
    };
  }
  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  

  handleEmailChange = (e) => {
    const email = e.target.value;
    const emailValid = this.validateEmail(email);
    this.setState({ validEmail: emailValid });
    
    if (!emailValid) {
      this.setState({ emailErrMsg: 'Email is required and format should be john@doe.com.' });
    } else this.setState({ emailErrMsg: '' });
  }

  login = () => {
    window.location.href = "/admin/dashboard";
  }
  // showHide = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.setState({type: this.state.type === 'input' ? 'password' : 'input'});
  // }
   showHide = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    let newPassType = 'password';
    if(type === 'old'){
      if(this.state.oldType === 'password'){
          newPassType = 'input'
      }
      this.setState({oldType: newPassType});
    }else if(type === 'new'){
      if(this.state.newType === 'password'){
          newPassType = 'input'
      }
      this.setState({newType: newPassType});
    }else if(type === 'confirm'){
      if(this.state.confirmType === 'password'){
          newPassType = 'input'
      }
      this.setState({confirmType: newPassType});
    }
  }
   // Check for an uppercase character
   checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
        this.setState({ uppercaseValid: true });
      } else {
        this.setState({ uppercaseValid: false });
    }
  }
  // Check for a number
  checkNumber = (password) => {
      const pattern = /[0-9]/;
      if (pattern.test(password)) {
        this.setState({ numberValid: true });
      } else {
        this.setState({ numberValid: false });
      }
  }
  handlePasswordChange(event) {
    this.checkUppercase(event.target.value);
    this.checkNumber(event.target.value);
    this.setState({
      password: event.target.value
    });
    event.target.value.length < 6 || !this.state.uppercaseValid || !this.state.numberValid
      ? this.setState({
          passwordError: (
            <small className="text-danger">
              You must enter a password of at least 6 characters, 1 uppercase and 1 number.
            </small>
          )
        })
      : this.setState({ passwordError: null });
  }
  handleCfPasswordChange(event) {
    this.setState({
      cfpassword: event.target.value
    });
    event.target.value !== this.state.password
      ? this.setState({
          cfpasswordError: (
            <small className="text-danger">Passwords do not match.</small>
          )
        })
      : this.setState({ cfpasswordError: null });
  }
  render() {
    return (
      <Grid>
        <Row>
          <Col className="forgot-password" md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Change Password"
                content={
                  <div>
                   
                    <FormGroup style={{position: "relative"}}>
                        <ControlLabel>
                          New Password: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                          type={this.state.newType}
                          name="password"
                          value={this.state.password}
                          autoComplete="off"
                          onChange={event => this.handlePasswordChange(event)}
                          // disabled= {!this.state.editPasswordOn}
                          required
                        />
                        {this.state.newType === 'input'  ? 
                          <img className="show-icon" src={hidePass} onClick={(e) => this.showHide(e, 'new')} alt={"Show Password"}/> :
                          <img className="show-icon" src={showPass} onClick={(e) => this.showHide(e, 'new')} alt={"Hide Password"}/>
                        }
                        {this.state.passwordError}
                    </FormGroup>
                    <FormGroup style={{position: "relative"}}>
                        <ControlLabel>
                          Confirm password: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                          type={this.state.confirmType}
                          name="cfpassword"
                          value={this.state.cfpassword}
                          autoComplete="off"
                          onChange={event => this.handleCfPasswordChange(event)}
                          // disabled= {!this.state.editPasswordOn}
                          required
                          />
                          {this.state.confirmType === 'input'  ? 
                            <img className="show-icon" src={hidePass} onClick={(e) => this.showHide(e, 'confirm')} alt={"Hide Password"} /> :
                            <img className="show-icon" src={showPass} onClick={(e) => this.showHide(e, 'confirm')} alt={"Show Password"}/>
                          }
                        {this.state.cfpasswordError}
                    </FormGroup>
                  </div>
                }
                legend={
                  <div>
                    <Button 
                      onClick={this.login}
                      bsStyle="danger" fill wd
                      disabled={ !this.state.password || !this.state.cfpassword || this.state.password !== this.state.cfpassword || !this.state.uppercaseValid || !this.state.numberValid }
                    >
                      Change Password and Login
                    </Button>
                  </div>
                }
                ftTextCenter               
                />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default ChangePasswordPage;
