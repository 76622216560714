import React, { useState, useEffect } from "react";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import axios from "axios";
import Swal from "sweetalert2";
import "react-phone-number-input/style.css";
import { parseError } from "api/common";

const VerificationModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [codeType, setCodeType] = useState("phone");

  useEffect(() => {
    setEmail("");
    setPhone("");
    setError(null);
    setLoading(false);
    setEmailError(null);
    setPhoneError(null);
  }, [props.show]);

  const handleEmailChange = (event) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = re.test(event.target.value) === true;
    if (!isValid && event.target.value) {
      setEmailError(
        <small className="text-danger">
          Invalid email address format. Please make sure that you are using valid email address.
        </small>
      );
    } else {
      setEmailError(null);
    }
  };

  const onSendCode = () => {
    setLoading(true);
    setError(null);

    var codeUrl =
      codeType === "email"
        ? `${process.env.REACT_APP_API_URL}/account/resendverificationemail`
        : `${process.env.REACT_APP_API_URL}/account/sms/${encodeURIComponent(phone)}`;

    axios
      .post(codeUrl, codeType === "email" ? { email: email } : undefined)
      .then((res) => {
        setLoading(false);

        Swal.fire({
          title: "The verification code has been sent to user.",
          icon: "success",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed && props.onConfirm) {
            props.onClose();
          }
        });
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  return (
    <Modal show={props.show} onHide={() => props.onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Re-Send Verification Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-12">
          <Card
            content={
              <div className="flex flex-col gap-12">
                <div className="flex item-center gap-12">
                  <input
                    type="radio"
                    name="code-type-radio-group"
                    checked={codeType === "phone"}
                    onChange={(e) => setCodeType(e.target.checked ? "phone" : "email")}
                  />
                  <span>Phone Verification</span>
                </div>
                <div className="form-group phone-input">
                  <ControlLabel>Phone Number:</ControlLabel>
                  <PhoneInput
                    disabled={codeType === "email"}
                    maxLength="16"
                    max="10"
                    defaultCountry="CA"
                    extension="true"
                    value={phone}
                    onChange={(value) => {
                      setPhone(value);
                      if (value && !isPossiblePhoneNumber(value)) {
                        setPhoneError(
                          <small className="text-danger">
                            A valid Canadian phone number is required.
                          </small>
                        );
                      } else {
                        setPhoneError(null);
                      }
                    }}
                    required
                  />
                  {phoneError}
                </div>
              </div>
            }
          />

          <Card
            content={
              <div className="flex flex-col gap-12">
                <div className="flex item-center gap-12">
                  <input
                    type="radio"
                    name="code-type-radio-group"
                    checked={codeType === "email"}
                    onChange={(e) => setCodeType(e.target.checked ? "email" : "phone")}
                  />
                  <span>Email Verification</span>
                </div>

                <FormGroup>
                  <ControlLabel>Email address:</ControlLabel>
                  <FormControl
                    disabled={codeType === "phone"}
                    maxLength="100"
                    type="text"
                    name="email"
                    onChange={(event) => setEmail(event.target.value)}
                    onBlur={(event) => handleEmailChange(event)}
                    required
                  />
                  {emailError}
                </FormGroup>

                <span className="sub-text" style={{ marginTop: "-12px" }}>
                  Please note, that if the user's email is already verified they won't receive
                  verification email.
                </span>
              </div>
            }
          />

          {error && (
            <div className="error-alert" style={{ gridColumn: "1 / span 2", marginTop: "12px" }}>
              {error}
            </div>
          )}

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button
              disabled={loading}
              bsStyle="danger"
              outline="true"
              onClick={() => props.onClose()}
            >
              Cancel
            </Button>

            <Button
              loading={loading}
              bsStyle="danger"
              fill
              onClick={() => onSendCode()}
              disabled={
                (!email && codeType === "email") ||
                (!phone && codeType === "phone") ||
                phoneError ||
                emailError ||
                loading
              }
            >
              Send Code
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VerificationModal;
