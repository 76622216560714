import React, { useState, useRef, useCallback, useEffect } from "react";
import MapGL, { NavigationControl, Layer, Source } from "@urbica/react-map-gl";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import { getBoundsForPoints } from "../../../api/common.js";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function ZonesMap(props) {
  const mapRef = useRef();
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);
  const [viewport, setViewport] = useState({
    latitude: 51.0447,
    longitude: -114.0719,
    zoom: 9,
  });
  const navControlStyle = {
    right: 10,
    top: 10,
  };

  useEffect(() => {
    let points = (props.locations || [])
      .flatMap((s) => s.coordinates)
      .map((coord) => {
        return {
          longitude: coord[0],
          latitude: coord[1],
        };
      });

    try {
      if (points.length !== 0) {
        let centre = getBoundsForPoints(points, props.height || 400);
        setViewport({
          latitude: centre.latitude,
          longitude: centre.longitude,
          zoom: (centre.zoom > 13 ? 13 : centre.zoom) || props.zoom,
          width: "100%",
          height: `${props.height || 400}px`,
        });
      }
    } catch (mErr) {
      console.log(mErr);
    }
  }, [props.locations]);

  return (
    <MapGL
      ref={mapRef}
      {...viewport}
      style={{ width: "100%", height: `${props.height || 400}px` }}
      mapStyle="mapbox://styles/mapbox/light-v10"
      boxSelect={false}
      accessToken={process.env.REACT_APP_MAPBOX_API_KEY}
      onViewportChange={handleViewportChange}
    >
      <Source
        id="route"
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: (props.locations || []).map((feature) => {
            return {
              type: "Feature",
              properties: {},
              geometry: {
                coordinates: [feature.coordinates],
                type: "Polygon",
              },
            };
          }),
        }}
      />
      <Layer id="route" type="fill" source="route" paint={{ "fill-color": "#ed1b24", "fill-opacity": 0.18 }} />

      <Source
        id="selected-zone-source"
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                coordinates: [props.selected ? props.selected.coordinates : []],
                type: "Polygon",
              },
            },
          ],
        }}
      />
      <Layer id="selected-zone-layer" type="fill" source="selected-zone-source" paint={{ "fill-color": "#ed1b24", "fill-opacity": 0.5 }} />

      <NavigationControl style={navControlStyle} />
    </MapGL>
  );
}
