import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ReactDatetime from "react-datetime";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import Select from "react-select";
import Svg from "components/Svg/Svg.jsx";
import { Link } from "react-router-dom";
import { FormControl } from "react-bootstrap";
import Rating from "react-rating";
import { parseError, getAppointmentStatusName } from "api/common.js";
import { debounce } from "debounce";
import { dateTimeFormat } from "api/common";

const UserReviews = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [pageSize] = useState(props.pageSize || 12);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
  }, []);

  const getReviews = (pageToken = null, isBackward = null) => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(null);
    setData([]);

    let baseUrl = `${process.env.REACT_APP_API_URL}/account/reviews`;
    if (props.techId) {
      baseUrl = `${process.env.REACT_APP_API_URL}/technicians/${props.techId}/reviews`;
    }

    axios
      .get(
        `${baseUrl}?pageSize=${pageSize}&paginationToken=${pageToken || ""}&isBackward=${
          isBackward ? "true" : "false"
        }`
      )
      .then((res) => {
        setNextPageToken(res.data.nextPageToken);
        setPreviousPageToken(res.data.previousPageToken);
        setData(mapItems(res.data.items));
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const fetchData = () => {
    getReviews();
  };

  const mapItems = (items) => {
    return items.map((prop) => {
      return mapReview(prop);
    });
  };

  const mapReview = (prop) => {
    return {
      id: prop.id,
      date: formatDate(prop.createdAt),
      rating: (
        <Rating
          style={{ marginLeft: "4px" }}
          initialRating={prop.rate}
          emptySymbol="fa fa-star-o text-danger"
          fullSymbol="fa fa-star text-danger"
          readonly
        />
      ),
      note: prop.note,
      reviewer: formatReviewer(prop.reviewer),
    };
  };

  const formatDate = (inDate) => {
    if (!inDate) {
      return "--";
    }

    let dateString = moment(
      `${inDate.day}/${inDate.month}/${inDate.year} ${inDate.hour}:${inDate.minute}`,
      "D/M/YYYY H:mm"
    ).format(dateTimeFormat);

    return dateString;
  };

  const formatReviewer = (person) => {
    if (props.techId) {
      return (
        <Link to={`/admin/users/${encodeURIComponent(person.id)}`} title="View Account">
          {person.firstName} {person.lastName}
        </Link>
      );
    } else {
      return (
        <Link to={`/admin/lab-tech-details/${encodeURIComponent(person.id)}`} title="View Account">
          {person.firstName} {person.lastName}
        </Link>
      );
    }
  };

  return (
    <div className="flex flex-col gap-16">
      <ReactTable
        loading={loading}
        loadingText="Loading reviews..."
        noDataText={error || "No reviews found..."}
        data={data}
        onFetchData={debounce(fetchData, 800)}
        columns={[
          {
            Header: "Date",
            accessor: "date",
            sortable: false,
            maxWidth: 160,
          },
          {
            Header: "Rating",
            accessor: "rating",
            sortable: false,
            maxWidth: 130,
          },
          {
            Header: "Reviewer",
            accessor: "reviewer",
            sortable: false,
            maxWidth: 160,
          },
          {
            Header: "Review",
            accessor: "note",
            sortable: false,
            style: { textOverflow: "clip", whiteSpace: "pre-line" },
          },
        ]}
        defaultPageSize={pageSize}
        showPaginationBottom={false}
        className="-striped -highlight"
      />
      <div className="flex items-center" style={{ marginLeft: "auto" }}>
        <Button
          className="pagination-btn left btn-fill"
          disabled={!previousPageToken || loading}
          onClick={() => getReviews(previousPageToken, true)}
          bsStyle="default"
          fill
        >
          <Svg name="chevron-left" className="w-6 h-6" />
        </Button>
        <Button
          className="pagination-btn right btn-fill"
          disabled={!nextPageToken || loading}
          onClick={() => getReviews(nextPageToken, false)}
          bsStyle="default"
          fill
        >
          <Svg name="chevron-right" className="w-6 h-6" />
        </Button>
      </div>
    </div>
  );
};

export default UserReviews;
