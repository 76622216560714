import React, { useState, useEffect } from "react";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import { parseError } from "api/common";

const NotesModal = (props) => {
  const [notes, setNotes] = useState(props.notes || "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setNotes(props.notes || "");
    setError(null);
    setLoading(false);
  }, [props.show, props.notes, props.appointmentId]);

  const onSaveNotes = () => {
    setError(null);
    setLoading(true);

    axios
      .patch(`${process.env.REACT_APP_API_URL}/appointments/${props.appointmentId}/notes`, {
        notes: notes || "",
      })
      .then((res) => {
        if (props.onConfirm) {
          props.onConfirm(notes || "");
        }

        setLoading(false);
        props.onHide();
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Appointment Admin Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <Card
            content={
              <div className="flex flex-col gap-16">
                <FormGroup>
                  <ControlLabel>Notes</ControlLabel>
                  <FormControl
                    type="textarea"
                    maxLength={500}
                    componentClass="textarea"
                    rows={8}
                    value={notes}
                    onChange={(event) => {
                      setNotes(event.target.value);
                    }}
                  />
                </FormGroup>

                {error && (
                  <div className="error-alert" style={{ padding: "0.6rem" }}>
                    {error}
                  </div>
                )}
              </div>
            }
          />

          <div className="flex items-center gap-10" style={{ marginLeft: "auto", marginTop: "16px" }}>
            <Button disabled={loading} bsStyle="danger" outline="true" onClick={() => props.onHide()}>
              Close
            </Button>

            <Button loading={loading} bsStyle="danger" fill onClick={() => onSaveNotes()} disabled={loading}>
              Save Notes
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NotesModal;
