import React, { useEffect, useState } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTable from "react-table";
import Swal from "sweetalert2";
import Svg from "components/Svg/Svg.jsx";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import { parseError } from "api/common.js";
import moment from "moment";
import { dateTimeFormat } from "api/common";

const Notifications = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [pageSize] = useState(12);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.addEventListener("notificationsRead", reloadNotifications, true);
    window.addEventListener("newNotification", reloadNotifications, true);
    getNotifications();

    return function cleanup() {
      window.removeEventListener("notificationsRead", reloadNotifications, true);
      window.removeEventListener("newNotification", reloadNotifications, true);
    };
  }, []);

  const reloadNotifications = () => {
    getNotifications();
  };

  const getNotifications = (pageToken = null, isBackward = null) => {
    setLoading(true);
    setError(null);
    setData([]);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/notifications?pageSize=${pageSize}&paginationToken=${
          pageToken || ""
        }&isBackward=${isBackward ? "true" : "false"}`
      )
      .then((res) => {
        setNextPageToken(res.data.nextPageToken);
        setPreviousPageToken(res.data.previousPageToken);
        setData(res.data.items);
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const mapItems = (items) => {
    return items.map((prop) => {
      return mapNotification(prop);
    });
  };

  const getEpochDate = (epoch) => {
    var date = new Date(0);
    date.setUTCSeconds(epoch);

    return date;
  };

  const mapNotification = (prop) => {
    let actionTitle = "View Appointment";
    let actionRoute = "";

    switch (prop.type) {
      case "lowTechnicianRating":
        actionTitle = "View Technician";
        actionRoute = `/admin/lab-techs-details/${encodeURIComponent(prop.data.TechnicianId)}`;
        break;
      case "appointmentPaymentIssue":
      case "appointmentPreAuthIssue":
        actionTitle = "View Appointment";
        actionRoute = `/admin/appointments/${encodeURIComponent(prop.data.AppointmentId)}`;
        break;
    }

    return {
      id: prop.id,
      text: prop.body,
      date: moment(getEpochDate(prop.createdAtEpoch)).format(dateTimeFormat),
      type: prop.type,
      read: prop.isRead ? null : (
        <div
          style={{
            background: "var(--theme-color)",
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            flexShrink: "0",
            margin: "0 auto",
          }}
        ></div>
      ),
      data: prop.data,
      status: prop.isRead,
      actions: (
        <div className="flex items-center gap-6">
          <Button
            disabled={actionLoading || loading}
            title={actionTitle}
            bsStyle="danger"
            outline="true"
            style={{ padding: "7px 10px", marginLeft: "auto" }}
            onClick={() => {
              axios
                .put(`${process.env.REACT_APP_API_URL}/notifications/read`, { ids: [prop.id] })
                .then(() => {
                  window.dispatchEvent(new CustomEvent("notificationsRead"));
                })
                .catch((err) => {
                  parseError(err);
                });

              props.history.push(actionRoute);
            }}
          >
            <Svg name="eye" className="w-4 h-4" />
          </Button>
          {!prop.isRead && (
            <Button
              disabled={prop.isRead || loading || actionLoading}
              onClick={() => markAsRead(prop.id)}
              title="Mark As Read"
              bsStyle="danger"
              outline="true"
              style={{ padding: "7px 10px" }}
            >
              <Svg name="check" className="w-4 h-4" />
            </Button>
          )}
        </div>
      ),
    };
  };

  const markAsRead = (id) => {
    setActionLoading(true);

    axios
      .put(`${process.env.REACT_APP_API_URL}/notifications/read`, { ids: [id] })
      .then((res) => {
        setActionLoading(false);
        setData((items) =>
          items.map((s) => {
            if (s.id === id) {
              let item = JSON.parse(JSON.stringify(s));
              item.isRead = true;
              return item;
            }
            return s;
          })
        );
        window.dispatchEvent(new CustomEvent("notificationsRead"));
      })
      .catch((err) => {
        setActionLoading(false);
        let error = parseError(err);
        Swal.fire("Notifications Error", error, "error");
      });
  };

  const markAllAsRead = (id) => {
    setLoading(true);
    let unreadIds = data.filter((s) => !s.isRead).map((s) => s.id);

    axios
      .put(`${process.env.REACT_APP_API_URL}/notifications/read`, { ids: unreadIds })
      .then((res) => {
        setLoading(false);
        setData((items) =>
          items.map((s) => {
            if (unreadIds.includes(s.id)) {
              let item = JSON.parse(JSON.stringify(s));
              item.isRead = true;
              return item;
            }
            return s;
          })
        );
        window.dispatchEvent(new CustomEvent("notificationsRead"));
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Notifications Error", error, "error");
      });
  };

  return (
    <div className="main-content flex flex-col" style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-center w-full">
            <div className="title">Notifications</div>
            <div style={{ marginLeft: "auto" }}>
              <Button
                disabled={data.filter((s) => !s.isRead).length === 0 || actionLoading || loading}
                loading={loading}
                style={{ marginLeft: "auto" }}
                bsStyle="danger"
                fill
                onClick={() => {
                  markAllAsRead();
                }}
              >
                <Svg name="check" className="w-4 h-4" />
                Mark All As Read
              </Button>
            </div>
          </div>
        }
        content={
          <div className="flex flex-col gap-16">
            <ReactTable
              loading={loading}
              loadingText="Loading notifications..."
              noDataText={error || "No notifications found..."}
              data={mapItems(data)}
              columns={[
                {
                  Header: "",
                  accessor: "read",
                  sortable: false,
                  filterable: false,
                  maxWidth: 24,
                },
                {
                  Header: "Text",
                  accessor: "text",
                  sortable: false,
                  filterable: false,
                  style: { whiteSpace: "unset" },
                },
                {
                  Header: "Date",
                  accessor: "date",
                  sortable: false,
                  filterable: false,
                  maxWidth: 160,
                },
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  maxWidth: 100,
                  resizable: false,
                },
              ]}
              showPaginationBottom={false}
              defaultPageSize={pageSize}
              className="-striped -highlight"
            />
            <div className="flex items-center" style={{ marginLeft: "auto" }}>
              <Button
                className="pagination-btn left btn-fill"
                disabled={!previousPageToken || loading}
                onClick={() => getNotifications(previousPageToken, true)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-left" className="w-6 h-6" />
              </Button>
              <Button
                className="pagination-btn right btn-fill"
                disabled={!nextPageToken || loading}
                onClick={() => getNotifications(nextPageToken, false)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-right" className="w-6 h-6" />
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Notifications;
