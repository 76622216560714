import React, { useState } from "react";
import moment from "moment";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Svg from "components/Svg/Svg";
import ReactDatetime from "react-datetime";
import { dateFormat, renderDatePickerInput } from "../../../../api/common.js";

const DateListItemView = (props) => {
  const [text, setText] = useState(props.text || "");
  const [date, setDate] = useState(props.date || null);

  var isValidDate = function (current) {
    return current.isAfter(moment());
  };

  const handleBirthDayChange = (e) => {
    const selDate = moment(e).toDate();
    setDate(selDate);

    if (props.onChange) {
      props.onChange(
        {
          date: selDate,
          text: text,
        },
        props.index
      );
    }
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);

    if (props.onChange) {
      props.onChange(
        {
          date: date,
          text: newText,
        },
        props.index
      );
    }
  };

  return (
    <div className="flex items-center gap-10">
      <FormGroup style={{ width: "50%" }}>
        <ControlLabel>{props.textLabel || "DESCRIPTION"}</ControlLabel>
        <FormControl
          disabled={props.disabled || false}
          type="text"
          maxLength="150"
          name="date-text-name"
          value={text}
          onChange={handleTextChange}
        />
      </FormGroup>
      <FormGroup style={{ width: "40%" }}>
        <ControlLabel>DATE:</ControlLabel>
        <ReactDatetime
          value={date}
          dateFormat={dateFormat}
          closeOnSelect={true}
          renderInput={renderDatePickerInput}
          inputProps={{
            required: true,
            placeholder: "Select Date",
            disabled: props.disabled || false,
            clearable: false,
          }}
          timeFormat={false}
          isValidDate={isValidDate}
          onChange={handleBirthDayChange}
        />
      </FormGroup>

      <Button
        bsStyle="danger"
        disabled={props.disabled || false}
        style={{ marginLeft: "auto", marginTop: "7px", padding: "8px 10px" }}
        title="Delete Item"
        onClick={props.onDelete}
        type="button"
      >
        <Svg name="trash" className="w-4 h-4" />
      </Button>
    </div>
  );
};

export default DateListItemView;
