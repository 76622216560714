import React, { useState } from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Svg from "components/Svg/Svg";

const TextListItemView = (props) => {
  const [text, setText] = useState(props.text || "");

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);

    if (props.onChange) {
      props.onChange(
        {
          id: props.id,
          text: newText,
        },
        props.index
      );
    }
  };

  return (
    <div className="flex items-center gap-10">
      <FormGroup style={{ width: "100%" }}>
        <ControlLabel>{props.textLabel || "DESCRIPTION"}</ControlLabel>
        <FormControl
          disabled={props.disabled || false}
          type="text"
          maxLength="150"
          name="list-text-name"
          value={text}
          onChange={handleTextChange}
        />
      </FormGroup>

      <Button
        bsStyle="danger"
        disabled={props.disabled || false}
        style={{ marginLeft: "auto", marginTop: "7px", padding: "8px 10px" }}
        title="Delete Item"
        onClick={props.onDelete}
        type="button"
      >
        <Svg name="trash" className="w-4 h-4" />
      </Button>
    </div>
  );
};

export default TextListItemView;
