import React, { useState } from "react";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import AddNoteModal from "./AddNoteModal.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import { parseError } from "api/common.js";
import { debounce } from "debounce";
import Svg from "components/Svg/Svg.jsx";
import VerificationModal from "./VerificationModal.jsx";
import AddUserModal from "./AddUserModal.jsx";
import PendingAccountsModal from "./PendingAccountsModal.jsx";

const Users = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [pageSize] = useState(12);
  const [error, setError] = useState(null);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [pendingModalOpen, setPendingModalOpen] = useState(false);

  const getUsers = (pageToken = null, isBackward = null) => {
    setLoading(true);
    setError(null);
    setData([]);

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/account?pageSize=${pageSize}&paginationToken=${
          pageToken || ""
        }&isBackward=${isBackward ? "true" : "false"}`
      )
      .then((res) => {
        setNextPageToken(res.data.nextPageToken);
        setPreviousPageToken(res.data.previousPageToken);
        setData(mapItems(res.data.items));
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const onSearch = (filter) => {
    setLoading(true);
    setData([]);
    setError(null);

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/search`, filter)
      .then((res) => {
        setData(mapItems(res.data));
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const getFilters = (filter) => {
    var dataFilter = {};

    filter.forEach((f) => {
      dataFilter[f.id] = f.value;
    });

    return dataFilter;
  };

  const fetchData = (state) => {
    if (state.filtered.length > 0) {
      let dataFilter = getFilters(state.filtered);
      onSearch(dataFilter);
    } else {
      getUsers();
    }
  };

  const mapItems = (items) => {
    return items.map((prop) => {
      return mapUser(prop);
    });
  };

  const mapUser = (prop) => {
    return {
      id: prop.id,
      firstName: prop.firstName,
      lastName: prop.lastName,
      email: prop.email,
      phone: prop.phoneNumber,
      status: (
        <div
          className={`badge ${prop.status === "enabled" ? "success" : "error"}`}
        >
          {prop.status}
        </div>
      ),
      address: formatAddress(prop.address),
      adminNotes: prop.adminNotes,
      actions: (
        <div className="flex items-center gap-6">
          <Button
            bsStyle="danger"
            outline="true"
            style={{ padding: "7px 10px" }}
            title="View or Edit Note"
            onClick={() => {
              setSelectedUser(prop);
              setShowNoteModal(true);
            }}
          >
            <Svg name="edit" className="w-4 h-4" />
          </Button>
          <Button
            bsStyle="danger"
            outline="true"
            title="Book Appointment"
            style={{ padding: "7px 10px" }}
            onClick={() =>
              props.history.push(`/admin/appointment/create?patient=${prop.id}`)
            }
          >
            <Svg name="calendar-plus" className="w-4 h-4" />
          </Button>
          <Button
            bsStyle="danger"
            outline="true"
            title="View Patients Info"
            style={{ padding: "7px 10px" }}
            onClick={() => props.history.push(`/admin/users/${prop.id}`)}
          >
            <Svg name="eye" className="w-4 h-4" />
          </Button>
        </div>
      ),
    };
  };

  const formatAddress = (address) => {
    if (!address) {
      return "";
    }
    return `${address.city}, ${address.province}`;
  };

  const bypassFilter = (filter, row, column) => {
    return true;
  };

  return (
    <div className="main-content flex flex-col" style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-center w-full">
            <div className="title">Customers</div>
            <div style={{ marginLeft: "auto" }} className="flex gap-6">
              <Button
                style={{ marginLeft: "auto" }}
                bsStyle="danger"
                onClick={() => {
                  setVerificationModalOpen(true);
                }}
              >
                <Svg name="user-check" className="w-4 h-4" />
                Re-Send Code
              </Button>
              <Button
                style={{ marginLeft: "auto" }}
                bsStyle="danger"
                onClick={() => {
                  setPendingModalOpen(true);
                }}
              >
                <Svg name="users" className="w-4 h-4" />
                Pending Users
              </Button>
              <Button
                style={{ marginLeft: "auto" }}
                bsStyle="danger"
                fill
                onClick={() => {
                  setAddUserModalOpen(true);
                }}
              >
                <Svg name="user-check" className="w-4 h-4" />
                New Account
              </Button>
            </div>
          </div>
        }
        content={
          <div className="flex flex-col gap-16">
            <ReactTable
              loading={loading}
              loadingText="Loading accounts..."
              noDataText={error ? error : loading ? "" : "No accounts found..."}
              data={data}
              onFetchData={debounce(fetchData, 800)}
              defaultFilterMethod={bypassFilter}
              filterable
              columns={[
                {
                  Header: "First Name",
                  accessor: "firstName",
                  sortable: false,
                  filterable: true,
                },
                {
                  Header: "Last Name",
                  accessor: "lastName",
                  sortable: false,
                  filterable: true,
                },
                {
                  Header: "Phone",
                  accessor: "phone",
                  sortable: false,
                  filterable: true,
                  maxWidth: 140,
                },
                {
                  Header: "Email",
                  accessor: "email",
                  sortable: false,
                  filterable: true,
                },
                {
                  Header: "Address",
                  accessor: "address",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "Status",
                  accessor: "status",
                  sortable: false,
                  filterable: false,
                  maxWidth: 86,
                },
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  minWidth: 140,
                  maxWidth: 140,
                },
              ]}
              defaultPageSize={pageSize}
              showPaginationBottom={false}
              className="-striped -highlight"
            />
            <div className="flex items-center" style={{ marginLeft: "auto" }}>
              <Button
                className="pagination-btn left btn-fill"
                disabled={!previousPageToken || loading}
                onClick={() => getUsers(previousPageToken, true)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-left" className="w-6 h-6" />
              </Button>
              <Button
                className="pagination-btn right btn-fill"
                disabled={!nextPageToken || loading}
                onClick={() => getUsers(nextPageToken, false)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-right" className="w-6 h-6" />
              </Button>
            </div>
          </div>
        }
      />

      <AddNoteModal
        show={showNoteModal}
        user={selectedUser}
        onUpdate={(updUser) => {
          setData((prev) =>
            prev.map((u) => {
              if (u.id === updUser.id) {
                return mapUser(updUser);
              }

              return u;
            })
          );
        }}
        onClose={() => {
          setShowNoteModal(false);
          setSelectedUser(null);
        }}
      />

      <VerificationModal
        show={verificationModalOpen}
        onClose={() => {
          setVerificationModalOpen(false);
        }}
      />
      <AddUserModal
        show={addUserModalOpen}
        onClose={() => setAddUserModalOpen(false)}
      />
      <PendingAccountsModal
        show={pendingModalOpen}
        onClose={() => setPendingModalOpen(false)}
      />
    </div>
  );
};

export default Users;
