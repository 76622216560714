import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import MembersTable from "../CorporateUsers/MembersTable.jsx";
import "react-phone-number-input/style.css";

const SelectMemberModal = (props) => {
  const [selectedUser, setSelectedUser] = useState(null);

  const onSelectMember = (member) => {
    setSelectedUser(member);
  };

  const onConfirmSelection = () => {
    if (props.onConfirm && selectedUser) {
      let user = selectedUser;
      user.memberId = user.id;
      props.onConfirm(user);
    }
  };

  return (
    <Modal show={props.show} onHide={() => props.onHide()} dialogClassName="wide">
      <Modal.Header closeButton>
        <Modal.Title>{props.mode === "search" ? "Select User Account" : "Patient's Information"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          {props.show && props.clientId && <MembersTable onSelected={onSelectMember} selectable={true} clientId={props.clientId} />}

          <div className="flex items-center gap-10" style={{ marginLeft: "auto", marginTop: "16px" }}>
            <Button bsStyle="danger" outline="true" onClick={() => props.onHide()}>
              Cancel
            </Button>

            <Button
              bsStyle="danger"
              fill
              onClick={() => onConfirmSelection()}
              disabled={!selectedUser || (props.patients && props.patients.filter((p) => p.memberId === selectedUser.id).length > 0)}
            >
              Select Member
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SelectMemberModal;
