import React, { useEffect, useState } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTable from "react-table";
import Swal from "sweetalert2";
import ZonesMap from "./ZonesMap.jsx";
import Svg from "components/Svg/Svg.jsx";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import { parseError } from "api/common.js";

const ServiceLocations = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [highlightedZone, setHighlightedZone] = useState(null);

  useEffect(() => {
    getServiceLocations();
  }, []);

  const getServiceLocations = () => {
    setLoading(true);
    setError(null);
    setLocations([]);

    axios
      .get(`${process.env.REACT_APP_API_URL}/zones/detailed`)
      .then((res) => {
        if (res.data.length > pageSize) {
          setPageSize(res.data.length);
        }
        setLoading(false);
        setLocations(res.data);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const deleteServiceLocation = async (zone) => {
    Swal.fire({
      title: "",
      text: `Are you sure you want to delete the '${zone.name}' service location?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Location",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/zones/${zone.id}`)
          .then((res) => {
            getServiceLocations();

            Swal.fire({
              title: "Service location has been successfully deleted.",
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            setLoading(false);
            Swal.fire({
              title: "Delete Error",
              text: error,
              icon: "error",
              showCancelButton: false,
            });
          });
      }
    });
  };

  return (
    <div className="main-content flex flex-col" style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-center">
            <div className="title">Service Locations</div>
            <Button
              style={{ marginLeft: "auto" }}
              bsStyle="danger"
              fill
              onClick={() => {
                props.history.push("/admin/service-locations/new");
              }}
            >
              <Svg name="plus" className="w-4 h-4" />
              New Location
            </Button>
          </div>
        }
        content={
          <div className="flex flex-col gap-16">
            <Card content={<ZonesMap locations={locations} selected={highlightedZone} height={460} />} contentStyle={{ padding: "0" }} />

            <ReactTable
              loading={loading}
              loadingText="Loading service locations..."
              noDataText={error ? error : loading ? "" : "No service locations found..."}
              getTrGroupProps={(state, rowInfo) => {
                return {
                  onMouseEnter: () => {
                    if (rowInfo && rowInfo.original) {
                      setHighlightedZone({
                        id: rowInfo.original.id,
                        coordinates: rowInfo.original.coordinates,
                      });
                    }
                  },
                  onMouseLeave: () => {
                    setHighlightedZone(null);
                  },
                };
              }}
              data={locations.map((prop) => {
                return {
                  id: prop.id,
                  name: prop.name,
                  coordinates: prop.coordinates,
                  actions: (
                    <div className="flex items-center gap-6">
                      <Button
                        title="View/Edit Details"
                        bsStyle="danger"
                        outline="true"
                        style={{ padding: "7px 10px" }}
                        onClick={() => props.history.push(`/admin/service-locations/${prop.id}`)}
                      >
                        <Svg name="edit" className="w-4 h-4" />
                      </Button>
                      <Button
                        onClick={() => deleteServiceLocation(prop)}
                        title="Delete Service Location"
                        bsStyle="danger"
                        outline="true"
                        style={{ padding: "7px 10px" }}
                      >
                        <Svg name="trash" className="w-4 h-4" />
                      </Button>
                    </div>
                  ),
                };
              })}
              columns={[
                {
                  Header: "Service Location",
                  accessor: "name",
                },
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  maxWidth: 100,
                  minWidth: 100,
                },
              ]}
              defaultPageSize={pageSize}
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </div>
        }
      />
    </div>
  );
};

export default ServiceLocations;
