import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BigCalendar from "react-big-calendar";
import Loader from "views/Components/Loader/Loader";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import { parseError } from "api/common";
import { truncate } from "@sentry/utils";

const AppointmentsSchedule = (props) => {
  const history = useHistory();
  const localizer = BigCalendar.momentLocalizer(moment);
  const [appointments, setAppointments] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [colorsMap, setColorsMap] = useState();
  const [showBlockedSlotsColor, setShowBlockedSlotsColor] = useState(false);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const min = new Date();
  const max = new Date();
  min.setHours(7, 0, 0);
  max.setHours(20, 0, 0);

  const [minTime] = useState(min);
  const [maxTime] = useState(max);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      getServices();
    }, 1500);
  }, []);

  const getServices = () => {
    if (colorsMap) {
      return;
    }

    setColorsMap();

    axios
      .get(`${process.env.REACT_APP_API_URL}/services`)
      .then((res) => {
        let map = {};
        res.data
          .filter((s) => s.color)
          .forEach((s) => {
            map[`SE#${s.id}`] = { color: s.color, title: s.title };
          });
        setColorsMap(map);
      })
      .catch((err) => {
        parseError(err);
      })
      .finally(() => {
        getWeekSchedule(new Date());
      });
  };

  const getWeekDates = (curr) => {
    const initDate = moment(curr);
    const weekStart = initDate.clone().startOf("week");
    const weekEnd = initDate.clone().endOf("week");

    var firstDay = weekStart.toDate();
    var lastDay = weekEnd.toDate();

    return { start: firstDay, end: lastDay };
  };

  const getWeekSchedule = (date) => {
    setSelectedEvent(null);
    setLoading(true);
    setAppointments([]);

    var weekDates = getWeekDates(date);

    const filters = {
      patientId: null,
      technicianId: null,
      code: null,
      firstName: null,
      lastName: null,
      from: {
        year: weekDates.start.getFullYear(),
        month: weekDates.start.getMonth() + 1,
        day: weekDates.start.getDate(),
      },
      to: {
        year: weekDates.end.getFullYear(),
        month: weekDates.end.getMonth() + 1,
        day: weekDates.end.getDate(),
      },
      status: [
        "technician_assigned",
        "technician_in_transit",
        "technician_at_location",
        "started",
        "ended",
        "completed",
      ],
      pageSize: 999,
      paginationToken: null,
      isBackward: false,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/appointments/search`, filters)
      .then((res) => {
        setLoading(false);
        setAppointments([]);

        let appointmentsList = res.data.items.map((item) => {
          return {
            appointmentId: item.id,
            app: item,
            title: `${item.patient.firstName} ${item.patient.lastName}`,
            start: new Date(
              item.startTime.year,
              item.startTime.month - 1,
              item.startTime.day,
              item.startTime.hour,
              item.startTime.minute
            ),
            end: new Date(
              item.endTime.year,
              item.endTime.month - 1,
              item.endTime.day,
              item.endTime.hour,
              item.endTime.minute
            ),
            allDay: false,
            color: "default",
          };
        });

        const epochStart = Math.round(weekDates.start / 1000);
        const epochEnd = Math.round(weekDates.end / 1000);
        getBlockedSlots(epochStart, epochEnd, appointmentsList);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Calendar Error", error, "error");
      });
  };

  const getBlockedSlots = (epochStart, epochEnd, appointmentsList) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/technicians/schedule/block/${epochStart}/${epochEnd}`)
      .then((res) => {
        if (res.data.length === 0) {
          return;
        }

        var blocks = res.data.flatMap((item) => {
          var blockItems = item.blockedSlots.map((slot) => {
            return {
              appointmentId: slot.id,
              block: slot,
              tech: item.technician,
              title: truncate(slot.notes),
              start: new Date(
                slot.date.year,
                slot.date.month - 1,
                slot.date.day,
                slot.startTime.hour,
                slot.startTime.minute
              ),
              end: new Date(
                slot.date.year,
                slot.date.month - 1,
                slot.date.day,
                slot.endTime.hour,
                slot.endTime.minute
              ),
              allDay: false,
              color: "default",
            };
          });

          return blockItems;
        });

        if (blocks.length > 0) {
          setShowBlockedSlotsColor(true);
        }

        let resultList = appointmentsList.concat(blocks);
        console.log(resultList);
        setAppointments(resultList);
      })
      .catch((err) => {
        setAppointments(appointmentsList);
        let error = parseError(err);
        Swal.fire("Calendar Error", error, "error");
      });
  };

  const onDatesChanged = (date) => {
    getWeekSchedule(date);
  };

  const formatAddress = (address) => {
    if (!address) {
      return "";
    }
    return `${address.street}, ${address.city || address.place}, ${
      address.postalCode || address.postCode
    }, ${address.province || address.region}`;
  };

  const onSelectEvent = (event) => {
    if (event.app) {
      setAlert(
        <SweetAlert
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title={`Appointment #${event.appointmentId}`}
          onConfirm={() => {
            history.push(`/admin/appointments/${event.appointmentId}`);
          }}
          onCancel={() => {
            setAlert(null);
            setSelectedEvent(null);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="View Appointment"
        >
          <div>
            Appointment for{" "}
            <strong>{`${event.app.patient.firstName} ${event.app.patient.lastName}`}</strong> on{" "}
            {moment(event.start).format("dddd MMM D, YYYY")} at{" "}
            {moment(event.start).format("h:mm A")} - {moment(event.end).format("h:mm A")}.
            <br />
            {event.app.technician
              ? ` Assigned to ${event.app.technician.firstName} ${event.app.technician.lastName}.`
              : " Not assigned to a technician yet."}
          </div>
        </SweetAlert>
      );
    } else if (event.block) {
      setAlert(
        <SweetAlert
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title={`Blocked Time Slot`}
          onConfirm={() => {
            history.push(`/admin/lab-techs-details/${event.tech.id}`);
          }}
          onCancel={() => {
            setAlert(null);
            setSelectedEvent(null);
          }}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="View Technician"
        >
          <div>
            Time slot is blocked for{" "}
            <strong>{`${event.tech.firstName} ${event.tech.lastName}`}</strong> on{" "}
            {moment(event.start).format("dddd MMM D, YYYY")} at{" "}
            {moment(event.start).format("h:mm A")} - {moment(event.end).format("h:mm A")}.
            <hr className="separator horizontal" />
            <strong>Notes:</strong> {event.block.notes} <br />
            <strong>Address:</strong>{" "}
            {event.block.address ? formatAddress(event.block.address) : "--"}
          </div>
        </SweetAlert>
      );
    }
  };

  const eventColors = (event) => {
    let evData = {
      className: "rbc-event-red",
    };

    if (event.app) {
      let appColor = "";
      const appServices = event.app.appointmentPatients.flatMap((a) => a.appointmentItems);
      if (appServices && appServices.length > 0 && colorsMap) {
        const svcId = appServices[0].id;
        appColor = colorsMap[svcId] ? colorsMap[svcId].color : "";
      }

      if (appColor) {
        evData.style = {
          backgroundColor: appColor,
        };
      }
    } else if (event.block) {
      evData.className = "rbc-event-blocked";
    }

    return evData;
  };

  const formats = {
    dateFormat: "D",
    dayFormat: (date, culture, localizer) => localizer.format(date, "ddd D", culture),

    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, "MMMM D", culture) + " - " + localizer.format(end, "MMMM D", culture),
  };

  return (
    <React.Fragment>
      {alert}
      <Card
        className="card-calendar appointments-calendar"
        style={{ height: "800px", padding: 0 }}
        contentStyle={{
          position: "relative",
          height: "100%",
          padding: 0,
          margin: "0 -1px",
        }}
        content={
          <React.Fragment>
            {loading && (
              <div
                style={{
                  position: "absolute",
                  background: "rgba(255, 255, 255, 0.8)",
                  width: "100%",
                  height: "100%",
                  zIndex: 10,
                }}
              >
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ height: "100%" }}
                >
                  <Loader />
                  <p className="text-xs text-gray-400 mt-6" style={{ color: "var(--theme-color)" }}>
                    Loading appointments...
                  </p>
                </div>
              </div>
            )}

            <BigCalendar
              formats={formats}
              min={minTime}
              max={maxTime}
              selectable
              selectedEvent={selectedEvent}
              localizer={localizer}
              events={appointments}
              defaultView="week"
              step={15}
              timeslots={4}
              scrollToTime={new Date(1970, 1, 1, 6)}
              defaultDate={new Date()}
              onSelectEvent={(event) => onSelectEvent(event)}
              views={["week", "day", "month"]}
              onNavigate={onDatesChanged}
              onSelecting={(slot) => false}
              onSelectSlot={() => {}}
              eventPropGetter={eventColors}
            />
          </React.Fragment>
        }
      />

      {colorsMap && (
        <div className="appointment-colors-legend">
          {Object.keys(colorsMap)
            .filter((s) => colorsMap.hasOwnProperty(s))
            .map((s) => (
              <div key={s} className="flex items-center gap-6">
                <div
                  className="appointment-legend-color"
                  style={{ backgroundColor: colorsMap[s].color }}
                ></div>
                <div className="appointment-color-title">{colorsMap[s].title}</div>
              </div>
            ))}

          {showBlockedSlotsColor && (
            <div className="flex items-center gap-6">
              <div className="appointment-legend-color blocked-slots"></div>
              <div className="appointment-color-title">Blocked Time Slots</div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default AppointmentsSchedule;
