import React, { useState } from "react";
import { FormControl, Modal, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { parseError } from "api/common.js";
import axios from "axios";

export default function AdminMultiInvite({ show, setShow }) {
	const handleClose = () => setShow(false);
	const [emailCounter, setEmailCounter] = useState(1);
	const [emails, setEmails] = useState([""]);
	const [emailError, setEmailError] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	let { id } = useParams();

	const handleAddEmailAddress = (e) => {
		e.preventDefault();
		setEmailCounter(emailCounter + 1);
		setEmails([...emails, ""]);
	};

	const handleRemoveEmailAddress = (e, index) => {
		e.preventDefault();
		// remove the indexed added admin email

		setEmailCounter(emailCounter - 1);
		// index needs to -1 to remove the corresponding value
		let temp = [...emails];
		temp.splice(index, 1);
		setEmails(temp);
	};

	const handleEmailChange = (value, index) => {
		let temp = [...emails];
		temp[index] = value;
		setEmails(temp);
	};

	function handleEmailOnBlur() {
		var re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		let noError = true;
		emails.forEach((email) => {
			if (re.test(email) === false) {
				noError = false;
			}
		});
		if (!noError) {
			setEmailError(
				<small className="text-danger">
					Email is required and format should be <i>john@doe.com</i>.
				</small>
			);
		} else {
			setEmailError(null);
		}

		if (error) {
			setError(null);
		}

		return noError;
	}

	const handleSubmit = () => {
		let noError = handleEmailOnBlur();
		setLoading(true);
		if (noError) {
			axios
				.post(
					`${process.env.REACT_APP_API_URL}/corporateclients/${id}/multiinvite`,
					{ emails }
				)
				.then((res) => {
					Swal.fire(
						"Corporate Client",
						"Corporate account created successfully, an email has been sent to the specified email addresses to complete registration",
						"success"
					);
					handleClose();
				})
				.catch((err) => {
					let errorParsed = parseError(err);
					setError(errorParsed);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return (
		<>
			<Modal show={show}>
				<Modal.Header>
					<Modal.Title>Invite More Admins</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{Array.from(Array(emailCounter)).map((c, index) => {
						return (
							<div className="flex" key={index} style={{ marginBottom: "4px" }}>
								<FormControl
									// disabled={loading}
									type="text"
									name="email"
									onChange={(event) => {
										handleEmailChange(event.target.value, index);
										if (error) {
											setError(null);
										}
									}}
									onBlur={() => handleEmailOnBlur()}
									required
									value={emails[index]}
									className=""
								/>
								{index === emailCounter - 1 && (
									<Button
										disabled={emailError !== null || emails.includes("")}
										variant="outline-secondary"
										id="button-addon2"
										onClick={(e) => handleAddEmailAddress(e)}
									>
										+
									</Button>
								)}
								{emailCounter > 1 && (
									<Button
										variant="outline-secondary"
										id="button-addon2"
										onClick={(e) => handleRemoveEmailAddress(e, index)}
									>
										-
									</Button>
								)}
							</div>
						);
					})}

					{emailError}
					{error && (
						<div
							className="error-alert"
							style={{ padding: "0.6rem", margin: "12px 0" }}
						>
							{error}
						</div>
					)}
					<div className="flex justify-center gap-4" style={{ marginTop: "12px" }}>
						<Button bsStyle="danger" onClick={handleClose}>
							Close
						</Button>
						<Button
							bsStyle="primary"
							onClick={handleSubmit}
							disabled={
								emailError !== null || emails.includes("") || loading || error !== null
							}
						>
							Invite More Admins
						</Button>
					</div>
				</Modal.Body>
				{/* <Modal.Footer></Modal.Footer> */}
			</Modal>
		</>
	);
}
