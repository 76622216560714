import React, { useEffect, useState } from "react";
import axios from "axios";
import { FormGroup, ControlLabel, FormControl, Grid, Row, Col } from "react-bootstrap";
import Switch from "react-switch";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import moment from "moment";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import AddPersonModal from "./AddPersonModal.jsx";
import ServiceListModal from "./SelectServiceModal.jsx";
import SelectedServiceModal from "./SelectedServiceModal.jsx";
import AddLocationModal from "./AddLocationModal.jsx";
import Svg from "../../../components/Svg/Svg";
import SelectMemberModal from "./SelectMemberModal.jsx";
import { parseError } from "api/common.js";
import "react-phone-number-input/style.css";
import "react-day-picker/lib/style.css";
import { dateFormat } from "api/common.js";

const CreateAppointment = (props) => {
  const [people, setPeople] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [address, setAddress] = useState(null);
  const [addressString, setAddressString] = useState(null);
  const [location, setLocation] = useState(null);
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);
  const [serviceListModalOpen, setServiceListModalOpen] = useState(false);
  const [serviceDetailsModalOpen, setServiceDetailsModalOpen] = useState(false);
  const [addPersonModalOpen, setAddPersonModalOpen] = useState(false);
  const [appointmentType, setAppointmentType] = useState("asap");
  const [discountCode, setDiscountCode] = useState("");
  const [notes, setNotes] = useState("");
  const [adminNotes, setAdminNotes] = useState("");
  const [genders, setGenders] = useState([]);
  const [costData, setCostData] = useState(null);
  const [costLoading, setCostLoading] = useState(false);
  const [appointmentLoading, setAppointmentLoading] = useState(false);
  const [appointmentError, setAppointmentError] = useState(null);
  const [appointmentData, setAppointmentData] = useState(null);
  const [patient, setPatient] = useState(null);
  const [userSelectMode, setUserSelectMode] = useState("new");
  const [scheduledDate, setScheduledDate] = useState(new Date());
  const [scheduledTime, setScheduledTime] = useState(null);
  const [availabilities, setAvailabilities] = useState([]);
  const [availabilityLoading, setAvailabilityLoading] = useState(false);
  const [availabilityText, setAvailabilityText] = useState("--");
  const [corporateData, setCorporateData] = useState(null);
  const [memberModalOpen, setMemberModalOpen] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/account/genders`)
      .then((res) => {
        setGenders(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    let searchParams = new URLSearchParams(props.location.search);
    const appointmentId = searchParams.get("id");

    if (!appointmentId) {
      let patientId = searchParams.get("patient");
      if (patientId) {
        loadPatientDetails(patientId);
      }
    } else {
      preloadAppointment(appointmentId);
    }
  }, []);

  useEffect(() => {
    calculateCost();
    checkAvailability();
  }, [people, appointmentType, address, discountCode, scheduledDate]);

  useEffect(() => {
    if (patient && patient.corporateClientId) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/corporateclients/${encodeURIComponent(
            patient.corporateClientId
          )}`
        )
        .then((res) => {
          setCorporateData(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [patient]);

  const loadPatientDetails = (patientId, updateAddress = true) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/account/${patientId}`)
      .then((res) => {
        setPatient({
          id: res.data.id,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          phone: res.data.phoneNumber,
          email: res.data.email,
          dob: moment(
            `${res.data.dateOfBirth.day}/${res.data.dateOfBirth.month}/${res.data.dateOfBirth.year}`,
            "D/M/YYYY"
          ).toDate(),
          gender: { value: res.data.gender },
          corporateClientId: res.data.corporateClientId,
        });

        if (updateAddress) {
          let addr = {
            place: res.data.address.city,
            postCode: res.data.address.postalCode,
            region: res.data.address.province,
            street: res.data.address.street,
          };
          let addressString = `${addr.street}, ${addr.place}, ${addr.postCode}, ${addr.region}`;
          onAddressSelected(addressString, res.data.location, addr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const preloadAppointment = (appId) => {
    setAppointmentData(null);
    setAppointmentLoading(true);
    setAppointmentError(null);

    axios
      .get(`${process.env.REACT_APP_API_URL}/appointments/${appId}`)
      .then((res) => {
        setAppointmentLoading(false);
        setAppointmentData(res.data);
        setupAppointmentData(res.data);
      })
      .catch((err) => {
        setAppointmentLoading(false);
        let error = parseError(err);
        setAppointmentError(error);
      });
  };

  const setupAppointmentData = (data) => {
    setAppointmentType(data.type);
    loadPatientDetails(data.patient.id, false);
    let patients = data.appointmentPatients.map((patient, ind) => {
      return {
        id: `${ind}`,
        email: patient.email,
        firstName: patient.firstName,
        lastName: patient.lastName,
        gender: { value: patient.gender },
        phone: patient.phoneNumber,
        dob: moment(
          `${patient.dateOfBirth.day}/${patient.dateOfBirth.month}/${patient.dateOfBirth.year}`,
          "D/M/YYYY"
        ).toDate(),
        services: patient.appointmentItems.map((aItem) => {
          return {
            key: aItem.key,
            id: aItem.service.id,
            title: aItem.service.title,
            price: aItem.service.price,
            questionnaire: aItem.service.questionnaire,
            requisitionFormFile: aItem.service.requisitionFormId,
            insuranceFormFile: aItem.service.insuranceFormId,
          };
        }),
      };
    });
    setDiscountCode(data.discountCodeUsed);
    setPeople(patients);
    setScheduledDate(
      moment(
        `${data.startTime.day}/${data.startTime.month}/${data.startTime.year}`,
        "D/M/YYYY"
      ).toDate()
    );

    let appTime = {
      value: moment(`${data.startTime.hour}:${data.startTime.minute}`, ["h:mm A"]).format("h:mm A"),
      label: moment(`${data.startTime.hour}:${data.startTime.minute}`, ["h:mm A"]).format("h:mm A"),
    };
    setAvailabilities([
      {
        hour: data.startTime.hour,
        minute: data.startTime.minute,
      },
    ]);
    setScheduledTime(appTime);
    setAddressString(
      `${data.address.street}, ${data.address.city}, ${data.address.postalCode}, ${data.address.province}`
    );
    setLocation(data.location);
    setAddress(data.address);
    setCostData(data.cost);
    setNotes(data.patientNotes || "");
    setAdminNotes(data.adminNotes || "");
  };

  const removePerson = (person) => {
    setPeople((people) => people.filter((p) => p !== person));
  };

  const removeService = (person, service) => {
    setPeople((list) =>
      list.map((p) => {
        if (p.id === person.id) {
          p.services = p.services.filter((s) => s.id !== service.id);
        }
        return p;
      })
    );
  };

  const onAddressSelected = (addrString, coordinates, addressData) => {
    setAddressString(addrString);
    setLocation(coordinates);
    setAddress(addressData);
  };

  const onAddOwnerAsPatient = () => {
    if (!patient) {
      return;
    }

    onPersonAdded(patient);
  };

  const onMemberAdded = (person) => {
    person.id = `${people.length + 1}`;
    person.services = person.services || [];

    setPeople((people) => [...people, person]);

    setMemberModalOpen(false);
    setSelectedPerson(null);
  };

  const onPersonAdded = (person) => {
    if (userSelectMode === "search") {
      if (patient) {
        setPeople((list) => list.filter((f) => f.id !== patient.id));
      }

      setPatient({
        id: person.id,
        firstName: person.firstName,
        lastName: person.lastName,
        phone: person.phoneNumber,
        email: person.email,
        dob: moment(
          `${person.dateOfBirth.day}/${person.dateOfBirth.month}/${person.dateOfBirth.year}`,
          "D/M/YYYY"
        ).toDate(),
        gender: { value: person.gender },
        corporateClientId: person.corporateClientId,
      });

      let addr = {
        place: person.address.city,
        postCode: person.address.postalCode,
        region: person.address.province,
        street: person.address.street,
      };
      let addressString = `${addr.street}, ${addr.place}, ${addr.postCode}, ${addr.region}`;
      onAddressSelected(addressString, person.location, addr);
    } else {
      person.services = person.services || [];

      if (!person.id) {
        person.id = `${people.length + 1}`;
        setPeople((people) => [...people, person]);
      } else {
        let existingPerson = people.find((p) => p.id === person.id);
        if (existingPerson) {
          setPeople((people) =>
            people.map((p) => {
              if (p.id === existingPerson.id) {
                existingPerson.email = person.email;
                existingPerson.firstName = person.firstName;
                existingPerson.lastName = person.lastName;
                existingPerson.gender = person.gender;
                existingPerson.phone = person.phone;
                existingPerson.dob = person.dob;
                return existingPerson;
              }
              return p;
            })
          );
        } else {
          setPeople((people) => [...people, person]);
        }
      }
    }

    setAddPersonModalOpen(false);
    setUserSelectMode("new");
    setSelectedPerson(null);
  };

  const onAddPersonServices = (person) => {
    setSelectedPerson(person);
    setServiceListModalOpen(true);
  };

  const onServicesSelected = (service) => {
    setSelectedService(service);
    setServiceListModalOpen(false);
    setServiceDetailsModalOpen(true);
  };

  const onServiceAddComplete = (service) => {
    let person = selectedPerson;
    if (person.services.find((s) => s.id === service.id)) {
      person.services = [...person.services.filter((s) => s.id !== service.id), service];
    } else {
      person.services.push(service);
    }

    setPeople((list) =>
      list.map((p) => {
        if (p.id === person.id) {
          return person;
        }
        return p;
      })
    );
    setSelectedService(null);
    setSelectedPerson(null);
    setServiceDetailsModalOpen(false);
  };

  const updateAppointmentType = (type) => {
    setAppointmentType(type);
    setScheduledTime(null);
    setScheduledDate(new Date());
  };

  const checkAvailability = () => {
    if (appointmentData != null) {
      return;
    }

    if (availabilityLoading) {
      setTimeout(() => {
        checkAvailability();
      }, 1500);
      return;
    }

    if (
      !scheduledDate ||
      !location ||
      people.length === 0 ||
      people.filter((s) => !s.services || s.services.length === 0).length > 0
    ) {
      setAvailabilities([]);
      setAppointmentError(null);
      setCostData(null);
      setAvailabilityText("--");
      return;
    }

    let appServices = people.flatMap((s) => s.services || []);
    let serviceCounts = appServices.filter(distinctValue).map((svc) => {
      return {
        serviceId: svc.id,
        count: appServices.filter((s) => s.id == svc.id).length,
      };
    });

    setAvailabilities([]);
    setAvailabilityLoading(true);
    setAvailabilityText("Checking availability...");

    axios
      .post(`${process.env.REACT_APP_API_URL}/appointments/availabilities`, {
        latitude: location.latitude,
        longitude: location.longitude,
        date: {
          year: scheduledDate.getFullYear(),
          month: scheduledDate.getMonth() + 1,
          day: scheduledDate.getDate(),
        },
        requestedServices: serviceCounts,
      })
      .then((res) => {
        setAvailabilityLoading(false);

        let today = new Date();
        let froms =
          res.data.date.day === today.getDate()
            ? res.data.froms.filter(
                (f) =>
                  f.hour > today.getHours() ||
                  (f.hour === today.getHours() && f.minute > today.getMinutes() + 15)
              )
            : res.data.froms;
        setAvailabilities(froms);
        if (froms.length > 0) {
          setAvailabilityText(
            `First available slot at ${froms[0].hour}:${
              froms[0].minute < 10 ? froms[0].minute.toString().padStart(2, "0") : froms[0].minute
            }`
          );
        } else {
          setAvailabilityText("No time slots available...");
        }
      })
      .catch((err) => {
        let error = parseError(err);
        setAvailabilityLoading(false);
        setAvailabilityText("No time slots available...");
      });
  };

  const calculateCost = () => {
    if (costLoading) {
      setTimeout(() => {
        calculateCost();
      }, 1500);
      return;
    }

    if (
      !patient ||
      !address ||
      !location ||
      people.length === 0 ||
      people.filter((s) => !s.services || s.services.length === 0).length > 0
    ) {
      setAppointmentError(null);
      setCostData(null);
      return;
    }

    let appServices = people.flatMap((s) => s.services || []);
    let serviceCounts = appServices.filter(distinctValue).map((svc) => {
      return {
        serviceId: svc.id,
        count: appServices.filter((s) => s.id == svc.id).length,
      };
    });

    setCostLoading(true);
    setAppointmentError(null);

    axios
      .post(`${process.env.REACT_APP_API_URL}/appointments/calculate-cost`, {
        province: address.region || address.province,
        patientId: patient.id,
        discountCode: discountCode,
        appointmentCode: appointmentData ? appointmentData.id : null,
        services: serviceCounts,
      })
      .then((res) => {
        setCostLoading(false);
        setCostData(res.data);
      })
      .catch((err) => {
        let error = parseError(err);
        setAppointmentError(error);
        setCostData(null);
        setCostLoading(false);
      });
  };

  const distinctValue = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const isValidDate = function (current) {
    return current.isAfter(moment().add(-1, "days"));
  };

  const createAppointment = async () => {
    if (!patient || !address || !location || !scheduledDate || people.length === 0) {
      return;
    }

    setAppointmentError(null);
    setAppointmentLoading(true);

    let peopleList = people;
    let uploadCompleted = false;

    try {
      for (const person of peopleList) {
        for (const pService of person.services) {
          if (!pService.requisitionFormFile && !pService.insuranceFormFile) {
            continue;
          }

          if (pService.requisitionFormFile) {
            if (typeof pService.requisitionFormFile === "string") {
              pService.requisitionFormFileId = pService.requisitionFormFile;
            } else {
              const file = pService.requisitionFormFile;
              let formData = new FormData();
              formData.append("file", file, file.name);

              let fileRes = await axios.post(
                `${process.env.REACT_APP_API_URL}/file/upload/requisitionform`,
                formData,
                {
                  headers: { "Content-Type": "multipart/form-data" },
                }
              );

              pService.requisitionFormFileId = fileRes.data;
            }
          }

          if (pService.insuranceFormFile) {
            if (typeof pService.insuranceFormFile === "string") {
              pService.insuranceFormFileId = pService.insuranceFormFile;
            } else {
              const file = pService.insuranceFormFile;
              let formData = new FormData();
              formData.append("file", file, file.name);

              let fileRes = await axios.post(
                `${process.env.REACT_APP_API_URL}/file/upload/insuranceform`,
                formData,
                {
                  headers: { "Content-Type": "multipart/form-data" },
                }
              );

              pService.insuranceFormFileId = fileRes.data;
            }
          }
        }
      }

      uploadCompleted = true;
    } catch (err) {
      let error = parseError(err);
      setAppointmentError(error);
      setAppointmentLoading(false);
    }

    if (uploadCompleted) {
      try {
        // Construct patient's list
        var patients = peopleList.map((person) => {
          return {
            firstName: person.firstName,
            lastName: person.lastName,
            email: person.email,
            phoneNumber: person.phone,
            dateOfBirth: {
              year: person.dob.getFullYear(),
              month: person.dob.getMonth() + 1,
              day: person.dob.getDate(),
            },
            gender: person.gender.value,
            appointmentItems: person.services.map((pSvc) => {
              return {
                key: pSvc.key || null,
                serviceId: pSvc.id,
                questionnaire: pSvc.questionnaire.map((q) => {
                  return {
                    id: q.id,
                    completed: q.completed,
                  };
                }),
                requisitionFormFileId: pSvc.requisitionFormFileId,
                insuranceFormFileId: pSvc.insuranceFormFileId,
              };
            }),
          };
        });

        // Construct appointment data
        let appData = {
          appointmentPatients: patients,
          address: {
            province: address.region || address.province,
            city: address.place || address.city,
            street: address.street,
            postalCode: address.postCode || address.postalCode,
          },
          latitude: location.latitude,
          longitude: location.longitude,
          discountCode: discountCode || null,
          notes: notes,
          adminNotes: adminNotes,
          patientId: patient.id,
          type: appointmentType,
          startTime:
            appointmentType === "asap"
              ? null
              : {
                  year: scheduledDate.getFullYear(),
                  month: scheduledDate.getMonth() + 1,
                  day: scheduledDate.getDate(),
                  hour: parseInt(scheduledTime.value.split(":")[0]),
                  minute: parseInt(scheduledTime.value.split(":")[1]),
                },
        };

        let appRes =
          appointmentData != null
            ? await axios.put(
                `${process.env.REACT_APP_API_URL}/appointments/${appointmentData.id}`,
                appData
              )
            : await axios.post(`${process.env.REACT_APP_API_URL}/appointments`, appData);

        let appId = appRes.data.id;
        props.history.push(`/admin/appointments/${appId}`);
      } catch (err) {
        let error = parseError(err);
        setAppointmentError(error);
        setAppointmentLoading(false);
      }
    }
  };

  return (
    <div className="main-content create-appt flex gap-16 flex-col">
      <Button
        simple
        icon
        style={{ padding: "0", marginBottom: "0px", marginRight: "auto" }}
        onClick={() => {
          props.history.go(-1);
        }}
      >
        <Svg name="chevron-left" className="w-6 h-6" />
        <span className="text-bold">Go Back</span>
      </Button>

      <Grid fluid className="w-full">
        <Row>
          <Col md={8}>
            <div className="flex flex-col gap-16">
              <Card
                title={
                  <div className="flex items-center">
                    <div className="title">Booking On Behalf Of</div>
                    {!appointmentData && !appointmentLoading && (
                      <Button
                        bsStyle="danger"
                        fill
                        disabled={appointmentLoading}
                        style={{ marginLeft: "auto" }}
                        onClick={() => {
                          setUserSelectMode("search");
                          setAddPersonModalOpen(true);
                        }}
                      >
                        <Svg name="user-plus" className="w-4 h-4" />
                        Select User
                      </Button>
                    )}
                  </div>
                }
                content={
                  <div className="flex flex-col gap-24">
                    {!patient && <div className="sub-text text-regular">No user selected</div>}

                    {patient && (
                      <div
                        style={{
                          padding: "8px 12px",
                          border: "1px solid var(--border-color)",
                          borderRadius: "6px",
                        }}
                        className="flex flex-col gap-12"
                      >
                        <div className="flex items-start">
                          <div className="flex flex-col gap-4">
                            <div className="text-semibold text-lg">
                              {patient.firstName} {patient.lastName}
                              {patient.corporateClientId ? (
                                <React.Fragment>
                                  {" • "}
                                  <div className="badge success">Corporate Client</div>
                                </React.Fragment>
                              ) : null}
                            </div>
                            <div className="sub-text text-xs">
                              {patient.email}
                              {" • "}
                              {patient.phone}
                            </div>
                            {!patient.corporateClientId && (
                              <div className="sub-text text-xs">
                                {moment(patient.dob).format(dateFormat)}
                                {" • "}
                                {genders.find((g) => g.key == patient.gender.value)
                                  ? genders.find((g) => g.key == patient.gender.value).value
                                  : ""}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                }
              />

              <Card
                title={
                  <div className="flex items-center">
                    <div className="title">Address</div>
                    <Button
                      disabled={appointmentLoading}
                      bsStyle="danger"
                      style={{ marginLeft: "auto" }}
                      fill
                      onClick={() => setAddLocationModalOpen(true)}
                    >
                      <Svg name="pin" className="w-4 h-4" />
                      {addressString ? "Edit Address" : "Add Address"}
                    </Button>
                  </div>
                }
                content={
                  <div className="flex flex-col">
                    {address && (
                      <div className="main-text text-xl text-semibold">{addressString}</div>
                    )}
                    {!address && <div className="sub-text text-regular">No address provided</div>}
                  </div>
                }
              />

              <Card
                title={
                  <div className="flex items-center">
                    <div className="title">Patients</div>
                    <div style={{ marginLeft: "auto" }} className="flex items-center gap-10">
                      {patient && !patient.corporateClientId && (
                        <Button
                          bsStyle="danger"
                          fill
                          disabled={
                            people.filter((p) => p.id === patient.id).length > 0 ||
                            appointmentLoading
                          }
                          onClick={() => onAddOwnerAsPatient()}
                        >
                          <Svg name="user-plus" className="w-4 h-4" />
                          Add Requester
                        </Button>
                      )}
                      {patient && patient.corporateClientId && (
                        <React.Fragment>
                          <Button
                            bsStyle="danger"
                            fill
                            disabled={appointmentLoading}
                            onClick={() => setMemberModalOpen(true)}
                          >
                            <Svg name="users" className="w-4 h-4" />
                            Select Member
                          </Button>

                          <SelectMemberModal
                            patients={people}
                            onHide={() => setMemberModalOpen(false)}
                            show={memberModalOpen}
                            clientId={patient.corporateClientId}
                            onConfirm={onMemberAdded}
                          />
                        </React.Fragment>
                      )}
                      <Button
                        bsStyle="danger"
                        fill
                        disabled={appointmentLoading}
                        onClick={() => {
                          setUserSelectMode("new");
                          setAddPersonModalOpen(true);
                        }}
                      >
                        <Svg name="users-plus" className="w-4 h-4" />
                        Add Patients
                      </Button>
                    </div>
                  </div>
                }
                content={
                  <div className="flex flex-col gap-24">
                    {people.length === 0 && (
                      <div className="sub-text text-regular">No patients added</div>
                    )}

                    {people.length > 0 && (
                      <div className="flex flex-col gap-16">
                        {people.map((person, key) => (
                          <div
                            key={key}
                            style={{
                              padding: "8px 12px",
                              border: "1px solid var(--border-color)",
                              borderRadius: "6px",
                            }}
                            className="flex flex-col gap-12"
                          >
                            <div className="flex items-start">
                              <div className="flex flex-col gap-4">
                                <div className="text-semibold text-lg">
                                  {person.firstName} {person.lastName}
                                </div>
                                <div className="sub-text text-xs">
                                  {person.email}
                                  {" • "}
                                  {person.phone}
                                </div>
                                <div className="sub-text text-xs">
                                  {moment(person.dob).format(dateFormat)}
                                  {" • "}
                                  {genders.find((g) => g.key == person.gender.value)
                                    ? genders.find((g) => g.key == person.gender.value).value
                                    : ""}
                                </div>
                              </div>
                              <Button
                                onClick={() => {
                                  setSelectedPerson(person);
                                  setAddPersonModalOpen(true);
                                }}
                                disabled={appointmentLoading}
                                title="Edit Patient's Info"
                                bsStyle="danger"
                                outline="true"
                                style={{ marginLeft: "auto", padding: "6px 10px" }}
                              >
                                <Svg name="edit" className="w-4 h-4" />
                              </Button>
                              <Button
                                onClick={() => removePerson(person)}
                                disabled={appointmentLoading}
                                title="Remove Patient"
                                bsStyle="danger"
                                outline="true"
                                style={{ marginLeft: "6px", padding: "6px 10px" }}
                              >
                                <Svg name="trash" className="w-4 h-4" />
                              </Button>
                            </div>

                            <div className="separator horizontal"></div>

                            <div className="flex flex-col gap-6">
                              <div className="sub-text text-bold text-xxs">SERVICES</div>

                              {person.services && person.services.length > 0 && (
                                <div className="flex flex-col gap-4">
                                  {person.services.map((svc) => (
                                    <div key={svc.id} className="flex items-center">
                                      <div className="text-semibold flex items-center gap-6">
                                        <span>{svc.title}</span>
                                        <span className="theme-text text-regular">
                                          ($
                                          {new Intl.NumberFormat("en-US", {
                                            style: "decimal",
                                            minimumFractionDigits: 2,
                                            signDisplay: "never",
                                          }).format(svc.price > 0 ? svc.price / 100 : 0)}
                                          )
                                        </span>
                                      </div>

                                      <Button
                                        onClick={() => {
                                          setSelectedPerson(person);
                                          setSelectedService(svc);
                                          setServiceDetailsModalOpen(true);
                                        }}
                                        disabled={appointmentLoading}
                                        title="Edit Service Details"
                                        bsStyle="danger"
                                        outline="true"
                                        style={{ marginLeft: "auto", padding: "6px 10px" }}
                                      >
                                        <Svg name="edit" className="w-4 h-4" />
                                      </Button>
                                      <Button
                                        disabled={appointmentLoading}
                                        onClick={() => removeService(person, svc)}
                                        title="Remove Service"
                                        bsStyle="danger"
                                        outline="true"
                                        style={{ marginLeft: "6px", padding: "6px 10px" }}
                                      >
                                        <Svg name="trash" className="w-4 h-4" />
                                      </Button>
                                    </div>
                                  ))}
                                </div>
                              )}

                              {(!person.services || person.services.length === 0) && (
                                <div className="sub-text text-regular">No services selected</div>
                              )}

                              <Button
                                disabled={appointmentLoading}
                                style={{ marginRight: "auto", marginTop: "12px" }}
                                onClick={() => onAddPersonServices(person)}
                                title="Add Services for Patient"
                                bsStyle="danger"
                                fill
                              >
                                <Svg name="plus" className="w-4 h-4" />
                                <span>Add Services</span>
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                }
              />

              <Card
                content={
                  <div className="flex flex-col gap-12">
                    <div className="grid grid-2col gap-6" style={{ padding: "12px 6px" }}>
                      <div className="switch" style={{ padding: "0" }}>
                        <FormGroup>
                          <Switch
                            disabled={!costData || appointmentLoading || appointmentData != null}
                            onChange={(checked, e) =>
                              updateAppointmentType(checked ? "asap" : "scheduled")
                            }
                            checked={appointmentType === "asap"}
                            offColor="#E6E6E6"
                            onColor="#ffc6c9"
                            onHandleColor="#EA1B27"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={40}
                          />
                          <ControlLabel>ASAP</ControlLabel>
                        </FormGroup>
                        <span className="sub-text text-xs" style={{ marginTop: "-6px" }}>
                          Time will vary based on availability
                        </span>
                      </div>
                      <div className="switch" style={{ padding: "0" }}>
                        <FormGroup>
                          <Switch
                            disabled={!costData || appointmentLoading || appointmentData != null}
                            onChange={(checked, e) =>
                              updateAppointmentType(checked ? "scheduled" : "asap")
                            }
                            checked={appointmentType === "scheduled"}
                            offColor="#E6E6E6"
                            onColor="#ffc6c9"
                            onHandleColor="#EA1B27"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={40}
                          />
                          <ControlLabel>Schedule for later</ControlLabel>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="separator horizontal"></div>

                    <div className="grid grid-2col gap-16 items-center">
                      <div className="sub-text text-semibold text-xs flex items-center">DATE:</div>
                      <div className="flex items-center gap-12">
                        {appointmentType === "asap" && (
                          <span
                            style={{ margin: "10px 0" }}
                            className="theme-text text-bold flex items-center"
                          >
                            {moment(scheduledDate).format(dateFormat)}
                          </span>
                        )}

                        {appointmentType === "scheduled" && (
                          <ReactDatetime
                            className="w-full"
                            dateFormat={dateFormat}
                            value={scheduledDate}
                            inputProps={{
                              required: true,
                              placeholder: "Select Date",
                              disabled: !costData || appointmentData != null,
                            }}
                            timeFormat={false}
                            isValidDate={isValidDate}
                            closeOnSelect={true}
                            onChange={(e) => {
                              setScheduledTime(null);
                              setScheduledDate(moment(e).toDate());
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div className="separator horizontal" style={{ marginTop: "1px" }}></div>

                    <div className="grid grid-2col gap-16 items-center">
                      <div className="sub-text text-semibold text-xs flex items-center">TIME:</div>
                      <div className="flex items-center gap-12">
                        {appointmentType === "asap" && (
                          <span
                            style={{ margin: "10px 0" }}
                            className="theme-text text-bold flex items-center"
                          >
                            {availabilityText}
                          </span>
                        )}

                        {appointmentType === "scheduled" && (
                          <Select
                            isDisabled={!costData || appointmentData != null}
                            name="time-select"
                            isClearable={false}
                            className="react-select react-select-icon w-full"
                            isSearchable={false}
                            isLoading={availabilityLoading}
                            placeholder="Select Time"
                            noOptionsMessage={(inp) => "No time slots"}
                            isMulti={false}
                            value={scheduledTime}
                            onChange={(opt) => {
                              setScheduledTime(opt);
                            }}
                            options={availabilities.map((s) => {
                              return {
                                label: `${s.hour}:${s.minute.toString().padStart(2, "0")}`,
                                value: `${s.hour}:${s.minute.toString().padStart(2, "0")}`,
                              };
                            })}
                            formatGroupLabel={(data) => (
                              <div className="flex items-center text-theme font-semibold">
                                {data.label}
                              </div>
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                }
              />

              <Card
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>DISCOUNT CODE</ControlLabel>
                      <FormControl
                        type="text"
                        name="DiscountCode"
                        value={discountCode}
                        onChange={(event) => {
                          setDiscountCode(event.target.value);
                        }}
                      />
                    </FormGroup>
                  </div>
                }
              />

              <Card
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>PATIENT NOTES</ControlLabel>
                      <FormControl
                        type="textarea"
                        maxLength={500}
                        rows={4}
                        componentClass="textarea"
                        name="notes"
                        value={notes}
                        onChange={(event) => {
                          setNotes(event.target.value);
                        }}
                      />
                    </FormGroup>
                  </div>
                }
              />

              <Card
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>ADMIN NOTES</ControlLabel>
                      <FormControl
                        type="textarea"
                        maxLength={500}
                        rows={4}
                        componentClass="textarea"
                        name="admin-notes"
                        value={adminNotes}
                        onChange={(event) => {
                          setAdminNotes(event.target.value);
                        }}
                      />
                    </FormGroup>
                  </div>
                }
              />
            </div>
          </Col>

          <Col md={4}>
            <Card
              content={
                <div className="flex flex-col gap-12">
                  <p className="text-bold">Payment Summary</p>

                  <div className="separator horizontal"></div>
                  {corporateData && corporateData.isBilledExternally && (
                    <div className="flex flex-col gap-4">
                      <span className="main-text text-semibold text-lg">No Payment Required</span>
                      <span className="sub-text text-regular text-xs">Billed Externally</span>
                    </div>
                  )}

                  {(!corporateData || !corporateData.isBilledExternally) && (
                    <React.Fragment>
                      <div className="flex items-center">
                        <span>Subtotal</span>
                        {costData && (
                          <span className="text-semibold ml-auto">
                            ${" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              signDisplay: "never",
                            }).format(costData.subtotal > 0 ? costData.subtotal / 100 : 0)}
                          </span>
                        )}{" "}
                        {!costData && <span className="ml-auto">--</span>}
                      </div>

                      <div className="flex items-center">
                        <span>
                          GST
                          {costData
                            ? `(${new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 1,
                                signDisplay: "never",
                              }).format(costData.gstPercentage)}%)`
                            : ""}
                        </span>
                        {costData && (
                          <span className="text-semibold ml-auto">
                            ${" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              signDisplay: "never",
                            }).format(costData.gst > 0 ? costData.gst / 100 : 0)}
                          </span>
                        )}{" "}
                        {!costData && <span className="ml-auto">--</span>}
                      </div>

                      {costData && costData.otherTax > 0 && (
                        <div className="flex items-center">
                          <span>
                            Provincial Tax{" "}
                            {costData
                              ? `(${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 1,
                                  signDisplay: "never",
                                }).format(costData.otherTaxPercentage)}%)`
                              : ""}
                          </span>
                          {costData && (
                            <span className="text-semibold ml-auto">
                              ${" "}
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                                signDisplay: "never",
                              }).format(costData.otherTax > 0 ? costData.otherTax / 100 : 0)}
                            </span>
                          )}{" "}
                          {!costData && <span className="ml-auto">--</span>}
                        </div>
                      )}

                      <div className="flex items-center">
                        <span>Discount</span>
                        {costData && (
                          <span className="text-semibold ml-auto">
                            ${" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              signDisplay: "auto",
                            }).format(costData.discount != 0 ? costData.discount / 100 : 0)}
                          </span>
                        )}{" "}
                        {!costData && <span className="ml-auto">--</span>}
                      </div>

                      <div className="separator horizontal"></div>

                      <div className="flex items-center">
                        <span className="text-semibold">
                          Total {costData ? `(${costData.currency})` : ""}
                        </span>
                        {costData && (
                          <span className="text-semibold ml-auto">
                            ${" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              signDisplay: "never",
                            }).format(costData.total > 0 ? costData.total / 100 : 0)}
                          </span>
                        )}{" "}
                        {!costData && <span className="ml-auto font-semibold">--</span>}
                      </div>
                    </React.Fragment>
                  )}

                  {costData && appointmentData && (
                    <React.Fragment>
                      <div className="separator horizontal"></div>

                      <div className="flex items-center">
                        <span>Pre-Authorized/Paid</span>
                        <span className="text-semibold ml-auto">
                          -${" "}
                          {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            signDisplay: "auto",
                          }).format(costData.amountPaid != 0 ? costData.amountPaid / 100 : 0)}
                        </span>
                      </div>

                      <div className="flex items-center">
                        <span className="text-semibold">
                          To Pay {costData ? `(${costData.currency})` : ""}
                        </span>
                        <span className="text-semibold ml-auto">
                          ${" "}
                          {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            signDisplay: "never",
                          }).format(costData.toPay > 0 ? costData.toPay / 100 : 0)}
                        </span>
                      </div>
                    </React.Fragment>
                  )}

                  <div className="separator horizontal"></div>

                  {appointmentError && (
                    <div className="error-alert" style={{ margin: "12px 0", padding: "0.6rem" }}>
                      {appointmentError}
                    </div>
                  )}

                  <Button
                    loading={costLoading || appointmentLoading}
                    disabled={
                      costLoading ||
                      appointmentLoading ||
                      availabilityLoading ||
                      !costData ||
                      (appointmentType === "asap" && availabilities.length === 0) ||
                      (appointmentType === "scheduled" && (!scheduledTime || !scheduledDate))
                    }
                    bsStyle="danger"
                    fill
                    style={{ marginTop: "16px" }}
                    onClick={createAppointment}
                  >
                    {appointmentData ? "Save Changes" : "Create Appointment"}
                  </Button>
                </div>
              }
            />
          </Col>

          <AddPersonModal
            mode={userSelectMode}
            show={addPersonModalOpen}
            person={selectedPerson}
            onHide={() => {
              setAddPersonModalOpen(false);
              setUserSelectMode("new");
              setSelectedPerson(null);
            }}
            onConfirm={onPersonAdded}
          />
          <ServiceListModal
            onConfirm={(service) => onServicesSelected(service)}
            show={serviceListModalOpen}
            onHide={() => {
              setServiceListModalOpen(false);
              setSelectedService(null);
              setSelectedPerson(null);
            }}
            person={selectedPerson}
          />
          <SelectedServiceModal
            show={serviceDetailsModalOpen}
            onHide={() => {
              setServiceDetailsModalOpen(false);
              setSelectedPerson(null);
              setSelectedService(null);
            }}
            service={selectedService}
            onConfirm={(svc) => onServiceAddComplete(svc)}
          />
          <AddLocationModal
            show={addLocationModalOpen}
            onHide={() => setAddLocationModalOpen(false)}
            address={addressString}
            onSelectAddress={onAddressSelected}
          />
        </Row>
      </Grid>
    </div>
  );
};

export default CreateAppointment;
