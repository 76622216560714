import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import { parseError } from "api/common.js";
import { debounce } from "debounce";
import Svg from "components/Svg/Svg.jsx";
import "react-phone-number-input/style.css";
import { dateFormat } from "api/common";

const AddPatientModal = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState({ value: "" });
  const [dob, setDob] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [genders, setGenders] = useState([]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageSize] = useState(8);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (props.show && props.mode === "new") {
      axios
        .get(`${process.env.REACT_APP_API_URL}/account/genders`)
        .then((res) => {
          setGenders(res.data);

          if (props.person) {
            let gnd = res.data.find((s) => s.key === props.person.gender.value);
            if (gnd) {
              setGender({ value: gnd.key, label: gnd.value });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    setFirstName(props.person ? props.person.firstName : "");
    setLastName(props.person ? props.person.lastName : "");
    setDob(props.person ? props.person.dob : null);
    setEmail(props.person ? props.person.email : "");
    setGender(props.person ? props.person.gender : { value: "" });
    setPhone(props.person ? props.person.phone : "");
    setEmailError(null);
    setPhoneError(null);
    setData([]);
    setPreviousPageToken(null);
    setNextPageToken(null);
    setSelectedUser(null);
    setError(null);
    setLoading(false);
  }, [props.show]);

  const handleEmailChange = (event) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = re.test(event.target.value) === true;
    if (!isValid && event.target.value) {
      setEmailError(
        <small className="text-danger">
          Invalid email address format. Please make sure that you are using valid email address.
        </small>
      );
    } else {
      setEmailError(null);
    }
  };

  const handleBirthDayChange = (e) => {
    setDob(moment(e).toDate());
  };

  var isValidDob = function (current) {
    return current.isBefore(moment());
  };

  const onCreateAccount = () => {
    const obj =
      props.mode === "search"
        ? selectedUser
        : {
            id: props.person ? props.person.id : null,
            email: email,
            firstName: firstName,
            lastName: lastName,
            gender: gender,
            phone: phone,
            dob: dob,
          };

    if (props.onConfirm) {
      props.onConfirm(obj);
    }

    props.onHide();
  };

  const getUsers = (pageToken = null, isBackward = null) => {
    setLoading(true);
    setError(null);
    setData([]);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/account?pageSize=${pageSize}&paginationToken=${
          pageToken || ""
        }&isBackward=${isBackward ? "true" : "false"}`
      )
      .then((res) => {
        setNextPageToken(res.data.nextPageToken);
        setPreviousPageToken(res.data.previousPageToken);
        setData(mapItems(res.data.items));
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const onSearch = (filter) => {
    setLoading(true);
    setData([]);
    setError(null);

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/search`, filter)
      .then((res) => {
        setData(mapItems(res.data));
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const getFilters = (filter) => {
    var dataFilter = {};

    filter.forEach((f) => {
      dataFilter[f.id] = f.value;
    });

    return dataFilter;
  };

  const fetchData = (state) => {
    if (state.filtered.length > 0) {
      let dataFilter = getFilters(state.filtered);
      onSearch(dataFilter);
    } else {
      getUsers();
    }
  };

  const mapItems = (items) => {
    return items.map((prop) => {
      return mapUser(prop);
    });
  };

  const formatAddress = (address) => {
    if (!address) {
      return "";
    }
    return `${address.city}, ${address.province}`;
  };

  const bypassFilter = (filter, row, column) => {
    return true;
  };

  const mapUser = (prop) => {
    return {
      id: prop.id,
      firstName: prop.firstName,
      lastName: prop.lastName,
      email: prop.email,
      phone: prop.phoneNumber,
      status: (
        <div className={`badge ${prop.status === "enabled" ? "success" : "error"}`}>
          {prop.status}
        </div>
      ),
      address: formatAddress(prop.address),
      adminNotes: prop.adminNotes,
      actions: (
        <div className="flex items-center">
          <input
            style={{ margin: "0 0 0 4px" }}
            name={prop.id}
            type="radio"
            name="patient-select-group"
            checked={selectedUser && selectedUser.id === prop.id}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedUser(prop);
              }
            }}
          />
        </div>
      ),
    };
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName={props.mode === "search" ? "wide" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.mode === "search" ? "Select User Account" : "Patient's Information"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          {!props.mode ||
            (props.mode === "new" && (
              <Card
                content={
                  <div
                    className="grid gap-6"
                    style={{
                      gridTemplateColumns: "repeat(2,minmax(0,1fr))",
                      gridColumnGap: "16px",
                    }}
                  >
                    <FormGroup>
                      <ControlLabel>
                        First Name: <span className="star">*</span>
                      </ControlLabel>
                      <FormControl
                        type="text"
                        maxLength="32"
                        name="firstName"
                        value={firstName}
                        onChange={(event) => {
                          setFirstName(event.target.value.replace(/[^a-z ]/gi, ""));
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>
                        Last Name: <span className="star">*</span>
                      </ControlLabel>
                      <FormControl
                        type="text"
                        maxLength="32"
                        name="lastName"
                        value={lastName}
                        onChange={(event) => {
                          setLastName(event.target.value.replace(/[^a-z ]/gi, ""));
                        }}
                      />
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>
                        DATE OF BIRTH: <span className="star">*</span>
                      </ControlLabel>
                      <div style={{ marginBottom: 20 }}>
                        <ReactDatetime
                          dateFormat={dateFormat}
                          value={dob}
                          closeOnSelect={true}
                          inputProps={{
                            required: true,
                            placeholder: "Date of Birth",
                          }}
                          timeFormat={false}
                          isValidDate={isValidDob}
                          onChange={handleBirthDayChange}
                        />
                      </div>
                    </FormGroup>

                    <div className="form-group phone-input">
                      <ControlLabel>
                        Phone Number: <span className="star">*</span>
                      </ControlLabel>
                      <PhoneInput
                        maxLength="16"
                        max="10"
                        defaultCountry="CA"
                        extension="true"
                        value={phone}
                        onChange={(value) => {
                          setPhone(value);
                          if (value && !isPossiblePhoneNumber(value)) {
                            setPhoneError(
                              <small className="text-danger">
                                A valid Canadian or US phone number is required.
                              </small>
                            );
                          } else {
                            setPhoneError(null);
                          }
                        }}
                        required
                      />
                      {phoneError}
                    </div>

                    <FormGroup>
                      <ControlLabel>
                        Email address: <span className="star">*</span>
                      </ControlLabel>
                      <FormControl
                        maxLength="100"
                        type="text"
                        name="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        onBlur={(event) => handleEmailChange(event)}
                        required
                      />
                      {emailError}
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>
                        Gender: <span className="star">*</span>
                      </ControlLabel>
                      <Select
                        name="gender-select"
                        isClearable={false}
                        className="react-select react-select-icon"
                        isSearchable={true}
                        placeholder="Select Gender"
                        noOptionsMessage={(inp) => "No Genders Loaded"}
                        isMulti={false}
                        value={gender}
                        onChange={(opt) => {
                          setGender(opt);
                        }}
                        options={genders.map((s) => {
                          return { label: s.value, value: s.key };
                        })}
                        formatGroupLabel={(data) => (
                          <div className="flex items-center text-theme font-semibold">
                            {data.label}
                          </div>
                        )}
                      />
                    </FormGroup>
                  </div>
                }
              />
            ))}

          {props.mode === "search" && (
            <Card
              content={
                <div className="flex flex-col gap-16">
                  <ReactTable
                    loading={loading}
                    loadingText="Loading accounts..."
                    noDataText={error || "No accounts found..."}
                    data={data}
                    onFetchData={debounce(fetchData, 800)}
                    defaultFilterMethod={bypassFilter}
                    filterable
                    columns={[
                      {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        minWidth: 40,
                        maxWidth: 40,
                      },
                      {
                        Header: "First Name",
                        accessor: "firstName",
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: "Last Name",
                        accessor: "lastName",
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: "Email",
                        accessor: "email",
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: "Address",
                        accessor: "address",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                        sortable: false,
                        filterable: false,
                        maxWidth: 90,
                      },
                    ]}
                    defaultPageSize={pageSize}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    <Button
                      className="pagination-btn left btn-fill"
                      disabled={!previousPageToken || loading}
                      onClick={() => getUsers(previousPageToken, true)}
                      bsStyle="default"
                      fill
                    >
                      <Svg name="chevron-left" className="w-6 h-6" />
                    </Button>
                    <Button
                      className="pagination-btn right btn-fill"
                      disabled={!nextPageToken || loading}
                      onClick={() => getUsers(nextPageToken, false)}
                      bsStyle="default"
                      fill
                    >
                      <Svg name="chevron-right" className="w-6 h-6" />
                    </Button>
                  </div>
                </div>
              }
            />
          )}

          <div
            className="flex items-center gap-10"
            style={{ marginLeft: "auto", marginTop: "16px" }}
          >
            <Button bsStyle="danger" outline="true" onClick={() => props.onHide()}>
              Cancel
            </Button>

            <Button
              bsStyle="danger"
              fill
              onClick={() => onCreateAccount()}
              disabled={
                (props.mode === "search" && !selectedUser) ||
                ((props.mode === "new" || !props.mode) &&
                  (!firstName ||
                    !lastName ||
                    !dob ||
                    !email ||
                    !phone ||
                    !gender ||
                    gender.value == "" ||
                    phoneError ||
                    emailError))
              }
            >
              {props.mode === "search"
                ? "Select User"
                : props.person
                ? "Save Patient"
                : "Add Patient"}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddPatientModal;
