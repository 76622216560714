import React, { useState } from "react";
import { Modal, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import moment from "moment";
import Datetime from "react-datetime";
import Select from "react-select";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import { parseError } from "api/common";
import Svg from "components/Svg/Svg";
import "react-phone-number-input/style.css";
import { dateFormat } from "api/common";

const AddPromocodeModal = (props) => {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [value, setValue] = useState(1);
  const [expireAt, setExpiration] = useState(null);
  const [type, setType] = useState({ value: "fixed", label: "Fixed" });
  const [maxRedemptions, setRedemptions] = useState(1);
  const [loading, setLoading] = useState(false);
  const [types] = useState([
    { label: "Fixed", value: "fixed" },
    { label: "Percentage", value: "percentage" },
  ]);

  const disablePastDt = (current) => {
    return current.isAfter(moment().add(-1, "days"));
  };

  const changeDate = (event) => {
    setExpiration(event.toDate());
  };

  const saveDiscount = () => {
    setLoading(true);

    let priceValue = value;
    if (type.value == "fixed") {
      priceValue = priceValue * 100;
    }

    const obj = {
      name: name,
      code: code,
      value: priceValue,
      expireAt: {
        year: expireAt.getFullYear(),
        month: expireAt.getMonth() + 1,
        day: expireAt.getDate(),
      },
      type: type.value,
      maxRedemptions: maxRedemptions,
      active: true,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/discounts`, obj)
      .then((res) => {
        setLoading(false);

        Swal.fire({
          title: "Promo Code Created",
          icon: "success",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            props.onHide();
            props.onCreated();
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Promo Code Error", error, "error");
      });
  };

  const generateCode = async () => {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_API_URL}/discounts/generate-code`)
      .then((res) => {
        setLoading(false);
        setCode(res.data.code);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Generate Error", error, "error");
      });
  };

  return (
    <div className="add-promocode">
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>New Promo Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-promocode">
            <Card
              content={
                <div className="grid grid-2col gap-16">
                  <FormGroup style={{ gridColumn: "1 / span 2" }}>
                    <ControlLabel>Promo Name:</ControlLabel>
                    <FormControl
                      required
                      type="text"
                      name="name"
                      placeholder="Promo Code Name (only visible to admins)"
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </FormGroup>
                  <div className="flex items-center gap-10" style={{ gridColumn: "1 / span 2" }}>
                    <FormGroup className="w-full">
                      <ControlLabel>Code:</ControlLabel>
                      <FormControl
                        required
                        type="text"
                        name="code"
                        placeholder="Code"
                        value={code}
                        onChange={(event) => {
                          setCode(event.target.value);
                        }}
                      />
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel></ControlLabel>
                      <Button
                        style={{ width: "100%", marginTop: "3px" }}
                        title="Generate Random Unique Code"
                        fill
                        bsStyle="danger"
                        onClick={generateCode}
                        disabled={loading}
                      >
                        <Svg className="w-4 h-4" name="refresh" />
                        Generate Code
                      </Button>
                    </FormGroup>
                  </div>

                  <FormGroup>
                    <ControlLabel>
                      Type: <span className="star">*</span>
                    </ControlLabel>
                    <Select
                      name="type-select"
                      isClearable={false}
                      className="react-select react-select-icon"
                      isSearchable={true}
                      placeholder="Select Type"
                      isMulti={false}
                      value={type}
                      onChange={(opt) => {
                        setType(opt);
                        if (opt.value === "percentage") {
                          setValue(parseInt(value));
                        }
                      }}
                      options={types}
                      formatGroupLabel={(data) => (
                        <div className="flex items-center text-theme font-semibold">
                          {data.label}
                        </div>
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Value in {type.value === "percentage" ? "%" : "$"}:</ControlLabel>
                    <div className="flex items-center">
                      <NumberFormat
                        value={value}
                        defaultValue={0}
                        decimalScale={type.value === "percentage" ? 0 : 2}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        allowEmptyFormatting={true}
                        suffix={type.value === "percentage" ? "%" : ""}
                        prefix={type.value === "fixed" ? "$" : ""}
                        className="form-control"
                        inputMode="numeric"
                        isAllowed={({ floatValue }) => {
                          return (
                            floatValue == undefined ||
                            floatValue <= (type.value === "percentage" ? 100 : 9999999.99)
                          );
                        }}
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                          setValue(
                            value
                              ? type.value === "percentage"
                                ? parseInt(value)
                                : parseFloat(value)
                              : 0
                          );
                        }}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Maximum Redemptions:</ControlLabel>
                    <FormControl
                      required
                      type="number"
                      name="maxRedemptions"
                      placeholder="Maximum Redemptions"
                      min={0}
                      max={999999999}
                      value={maxRedemptions}
                      onChange={(event) => {
                        setRedemptions(parseInt(event.target.value));
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>
                      Expiry: <span className="star">*</span>
                    </ControlLabel>
                    <Datetime
                      dateFormat={dateFormat}
                      timeFormat={false}
                      inputProps={{ placeholder: "" }}
                      isValidDate={disablePastDt}
                      value={expireAt}
                      onChange={changeDate}
                      closeOnSelect={true}
                    />
                  </FormGroup>
                  <div
                    className="flex items-center gap-10"
                    style={{ marginBottom: "16px", gridColumn: "1 / span 2" }}
                  >
                    <Button
                      style={{ marginLeft: "auto" }}
                      bsStyle="danger"
                      outline="true"
                      onClick={() => {
                        props.onHide();
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      loading={loading}
                      disabled={
                        loading ||
                        !name ||
                        !expireAt ||
                        !value ||
                        !maxRedemptions ||
                        !type ||
                        !type.value ||
                        !code
                      }
                      bsStyle="danger"
                      fill
                      onClick={saveDiscount}
                    >
                      Add Promo Code
                    </Button>
                  </div>
                </div>
              }
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddPromocodeModal;
