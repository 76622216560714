import React, {useEffect, useState} from "react";
import {Col, Grid, Row} from "react-bootstrap";
import AppointmentsMap from "./AppointmentsMap";
import DashboardAggregates from "./DashboardAggregates";
import axios from "axios";
import {parseError} from "../../../api/common";
import Loader from "../../Components/Loader/Loader";
import {WebMercatorViewport} from "react-map-gl";

const Dashboard = (props) => {
    const [technicians, setTechnicians] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [statuses] = useState(["ended", "completed", "cancelled"]);
    const [serviceCentres, setServiceCentres] = useState([]);
    const [defaultCentre, setDefaultCentre] = useState({});
    const [aggregates, setAggregates] = useState({});
    const [updateCounter, setUpdateCounter] = useState(0);

    const loadInitialData = (centre) => {
        axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/${centre.id}`)
            .then(res => {
                const data = res.data;
                setAppointments(data.appointments);
                setTechnicians(data.technicians);
                setAggregates({
                    totalUsers: `${data.totalUsers}`,
                    companyEarnings: `$${(data.companyEarnings / 100).toLocaleString("en-US")}`,
                    totalAppointments: `${data.totalAppointments}`
                });
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                setError(parseError(error));
            });
    };


    const loadServiceCentres = async () => {
        try {
            setLoading(true);
            let res = await axios.get(`${process.env.REACT_APP_API_URL}/service-centres`);
            let centres = res.data.items.map(serviceCentre => {
                return {
                    id: serviceCentre.id,
                    name: serviceCentre.name
                };
            });
            setServiceCentres(centres);
            if (centres.length > 0)
                setDefaultCentre({
                    id: centres[0].id,
                    name: centres[0].name
                });
            return centres;
        } catch (e) {
            setLoading(false);
            setError(parseError(e));
        }
    };

    useEffect ( () => {
        loadServiceCentres().then((centres) => loadInitialData(centres[0])).catch(e => parseError(e));
    }, []);

    useEffect(() => {
        if (props.connection) {
            props.connection.on("UpdateAggregates", message => {
                let data = JSON.parse(message);
                setAggregates({
                    totalUsers: `${data.totalUsers}`,
                    companyEarnings: `$${(data.companyEarnings / 100).toLocaleString("en-US")}`,
                    totalAppointments: `${data.totalAppointments}`
                });
            });
            props.connection.on("AppointmentUpdate", message => {
                let data = JSON.parse(message);
                if (statuses.includes(data.status))
                    setAppointments(appts => appts.filter(appt => appt.id !== data.id));
                else
                    setAppointments(appts => {
                        let found = false;
                        for (let index = 0; index < appts.length && !found; index++)
                            if (appts[index].id === data.id) {
                                appts[index] = data;
                                found = true;
                            }
                        if (!found)
                            appts = [...appts, data];
                        return appts;
                    });
            });
            props.connection.on("TechnicianUpdate", message => {
                let data = JSON.parse(message);
                setTechnicians(techs => {
                    let found = false;
                    for (let index = 0; index < techs.length && !found; index++)
                        if (techs[index].id === data.id){
                            techs[index] = data;
                            found = true;
                        }
                    if (!found)
                        techs = [...techs, data];
                    return techs;
                });
                setUpdateCounter(prevState => (prevState+1) % Number.MAX_SAFE_INTEGER);
            });
        }
    }, [props.connection]);

    return (
        <div className="main-content dashboard">
            {loading && (
                <div className="flex flex-col items-center justify-center">
                    <Loader />
                    <p className="text-xs text-gray-400 mt-6" style={{ color: "var(--theme-color)" }}>
                        Loading Dashboard...
                    </p>
                </div>
            )}
            {error && (
                <div className="error-alert" style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}>
                    {error}
                </div>
            )}
            {!loading && !error && (
                <Grid fluid>
                    <Row>
                        <Col sm={12}>
                            <DashboardAggregates
                                totalUsers={aggregates.totalUsers}
                                companyEarnings={aggregates.companyEarnings}
                                totalAppointments={aggregates.totalAppointments}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "24px" }}>
                        <Col className="dashboard-map" sm={12}>
                            <AppointmentsMap
                                height={700}
                                appointments={appointments}
                                technicians={technicians}
                                updateCounter={updateCounter}
                            />
                        </Col>
                    </Row>
                </Grid>
            )}
        </div>
    );
};

export default Dashboard;
