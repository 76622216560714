import React, { useState } from "react";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import { parseError } from "api/common.js";
import { debounce } from "debounce";
import Svg from "components/Svg/Svg.jsx";
import Swal from "sweetalert2";
import DropOffLocationsMap from "./DropOffLocationsMap";

const DropOffLocations = (props) => {
  const [originalItems, setOriginalItems] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [pageSize] = useState(12);
  const [error, setError] = useState(null);

  const getLocations = (pageToken = null, isBackward = null) => {
    setLoading(true);
    setError(null);
    setData([]);
    setOriginalItems([]);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/dropofflocations?pageSize=${pageSize}&paginationToken=${pageToken || ""}&isBackward=${
          isBackward ? "true" : "false"
        }`
      )
      .then((res) => {
        setNextPageToken(res.data.nextPageToken);
        setPreviousPageToken(res.data.previousPageToken);
        setData(mapItems(res.data.items));
        setOriginalItems(res.data.items);
        setLoading(false);
      })
      .catch((err) => {
        let error = parseError(err);
        setLoading(false);
        setError(error);
      });
  };

  const fetchData = (state) => {
    getLocations();
  };

  const mapItems = (items) => {
    return items.map((prop) => {
      return mapUser(prop);
    });
  };

  const mapUser = (prop) => {
    return {
      id: prop.id,
      address: prop.address,
      name: prop.name,
      actions: (
        <div className="flex items-center gap-6">
          <Button
            bsStyle="danger"
            outline="true"
            style={{ padding: "7px 10px" }}
            title="View or Edit Location"
            onClick={() => {
              props.history.push(`/admin/drop-off/${prop.id}`);
            }}
          >
            <Svg name="edit" className="w-4 h-4" />
          </Button>
          <Button
            bsStyle="danger"
            outline="true"
            title="Delete Location"
            style={{ padding: "7px 10px" }}
            onClick={() => deleteLocation(prop.id)}
          >
            <Svg name="trash" className="w-4 h-4" />
          </Button>
        </div>
      ),
    };
  };

  const deleteLocation = (id) => {
    Swal.fire({
      title: "Delete Drop Off Location",
      text: "Are you sure you want to delete this drop-off location?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ED1B24",
      cancelButtonColor: "#565656",
      confirmButtonText: "Delete Location",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/dropofflocations/${id}`)
          .then(() => {
            getLocations();

            Swal.fire({
              title: "Drop Off Location has been deleted successfully.",
              icon: "success",
              showCancelButton: false,
            });
          })
          .catch((err) => {
            let error = parseError(err);
            Swal.fire("Delete Error", error, "error");
          });
      }
    });
  };

  return (
    <div className="main-content flex flex-col" style={{ padding: "24px" }}>
      <Card
        title={
          <div className="flex items-center">
            <div className="title">Drop Off Locations</div>
            <Button
              style={{ marginLeft: "auto" }}
              bsStyle="danger"
              fill
              onClick={() => {
                props.history.push("/admin/drop-off/new");
              }}
            >
              <Svg name="plus" className="w-4 h-4" />
              New Location
            </Button>
          </div>
        }
        content={
          <div className="flex flex-col gap-16">
            <DropOffLocationsMap locations={originalItems} height={380} />
            <ReactTable
              loading={loading}
              loadingText="Loading locations..."
              noDataText={error ? error : loading ? "" : "No drop-off locations found..."}
              data={data}
              onFetchData={debounce(fetchData, 800)}
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                  sortable: false,
                },
                {
                  Header: "Address",
                  accessor: "address",
                  sortable: false,
                },
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  minWidth: 100,
                  maxWidth: 100,
                },
              ]}
              defaultPageSize={pageSize}
              showPaginationBottom={false}
              className="-striped -highlight"
            />
            <div className="flex items-center" style={{ marginLeft: "auto" }}>
              <Button
                className="pagination-btn left btn-fill"
                disabled={!previousPageToken || loading}
                onClick={() => getLocations(previousPageToken, true)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-left" className="w-6 h-6" />
              </Button>
              <Button
                className="pagination-btn right btn-fill"
                disabled={!nextPageToken || loading}
                onClick={() => getLocations(nextPageToken, false)}
                bsStyle="default"
                fill
              >
                <Svg name="chevron-right" className="w-6 h-6" />
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default DropOffLocations;
