import React, { useState, useEffect } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import axios from "axios";
import Card from "components/Card/Card.jsx";
import ReactTable from "react-table";
import AddCorporateClientModal from "./AddCorporateClientModal.jsx";
import { parseError } from "api/common.js";
import Svg from "components/Svg/Svg.jsx";

const CorporateUsers = (props) => {
	const [tableData, setTableData] = useState([]);
	const [nextPageToken, setNextPageToke] = useState("");
	const [previousPageToken, setPreviousPageToken] = useState("");
	const [pageSize] = useState(10);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [newModalOpen, setNewModalOpen] = useState(false);

	useEffect(() => {
		if (
			props.location &&
			props.location.state &&
			props.location.state.pageToken
		) {
			getCorporateUsers(
				props.location.state.pageToken,
				props.location.state.isBackward
			);
		} else {
			getCorporateUsers();
		}
	}, []);

	const getCorporateUsers = (pageToken = null, isBackward = null) => {
		setLoading(true);
		setError(null);
		setTableData([]);

		axios
			.get(
				`${
					process.env.REACT_APP_API_URL
				}/corporateclients?pageSize=${pageSize}&paginationToken=${
					pageToken || ""
				}&isBackward=${isBackward ? "true" : "false"}`
			)
			.then((res) => {
				setLoading(false);
				const items = res.data.items;
				const itemsList = items.map((corporation) => {
					return {
						id: corporation.id,
						company: corporation.name,
						phone: corporation.phone,
						email: corporation.email,
						address: corporation.address.street,
						status: (
							<div
								className={`badge ${
									corporation.status === "enabled" ? "success" : "error"
								}`}
							>
								{corporation.status}
							</div>
						),
						actions: (
							<div className="actions-right">
								<Button
									bsStyle="danger"
									outline="true"
									onClick={() => {
										props.history.push({
											pathname: `/admin/corporate-clients/${corporation.id}`,
											state: { pageToken: pageToken, isBackward: isBackward },
										});
									}}
								>
									<Svg className="w-4 h-4" name="edit" />
								</Button>
							</div>
						),
					};
				});

				setTableData(itemsList);
				setNextPageToke(res.data.nextPageToken);
				setPreviousPageToken(res.data.previousPageToken);
			})
			.catch((err) => {
				let error = parseError(err);
				setLoading(false);
				setError(error);
			});
	};

	const filterMethod = (filter, row, column) => {
		const id = filter.pivotId || filter.id;
		return row[id] !== undefined
			? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
			: true;
	};

	return (
		<div className="main-content corp-users flex flex-col gap-24">
			<AddCorporateClientModal
				show={newModalOpen}
				onHide={() => setNewModalOpen(false)}
				onSubmit={() => {
					getCorporateUsers();
					setNewModalOpen(false);
				}}
			/>

			<Card
				title={
					<div className="flex items-center">
						<span className="title">Corporate Clients</span>
						<Button
							style={{ marginLeft: "auto" }}
							bsStyle="danger"
							fill
							onClick={() => setNewModalOpen(true)}
						>
							<Svg name="plus" className="w-4 h-4" />
							Add New Client
						</Button>
					</div>
				}
				content={
					<div className="flex flex-col gap-12">
						<ReactTable
							data={tableData}
							loading={loading}
							loadingText="Loading accounts..."
							noDataText={error ? error : loading ? "" : "No accounts found..."}
							filterable
							defaultFilterMethod={filterMethod}
							columns={[
								{
									Header: "Company",
									accessor: "company",
									style: { whiteSpace: "unset" },
								},
								{
									Header: "Email",
									accessor: "email",
									sortable: false,
									filterable: false,
									style: { whiteSpace: "unset" },
								},
								{
									Header: "Phone",
									accessor: "phone",
									width: 120,
									sortable: false,
									filterable: false,
								},
								{
									Header: "Address",
									accessor: "address",
									sortable: false,
									filterable: false,
									style: { whiteSpace: "unset" },
								},
								{
									Header: "Status",
									accessor: "status",
									sortable: false,
									filterable: false,
									maxWidth: 100,
									width: 100,
								},
								{
									Header: "Actions",
									accessor: "actions",
									sortable: false,
									filterable: false,
									maxWidth: 80,
									width: 65,
								},
							]}
							defaultPageSize={10}
							showPaginationBottom={false}
							className="-striped -highlight"
						/>
						<div className="flex items-center" style={{ marginLeft: "auto" }}>
							<Button
								className="pagination-btn left btn-fill"
								disabled={!previousPageToken || loading}
								onClick={() => getCorporateUsers(previousPageToken, true)}
								bsStyle="default"
								fill
							>
								<Svg name="chevron-left" className="w-6 h-6" />
							</Button>
							<Button
								className="pagination-btn right btn-fill"
								disabled={!nextPageToken || loading}
								onClick={() => getCorporateUsers(nextPageToken, false)}
								bsStyle="default"
								fill
							>
								<Svg name="chevron-right" className="w-6 h-6" />
							</Button>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default CorporateUsers;
