import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import routes from "routes.js";
import { getSession, getAccessTokenFactory } from "api/auth";
import "assets/css/custom-css.css";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { style } from "variables/Variables.jsx";
import NotificationSystem from "react-notification-system";
import "../assets/css/notification-css.css";
import axios from "axios";
import { parseError } from "api/common";

const Main = (props) => {
  const [pageMini, setPageMini] = useState(false);
  const [connection, setConnection] = useState(null);
  const [startedConnection, setStartedConnection] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const navbar = false;
  const notificationSystem = useRef();
  const history = useHistory();

  useEffect(() => {
    if (getSession()) {
      const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_URL}/SignalR/AdminHub`, getAccessTokenFactory())
        .withAutomaticReconnect()
        .build();
      setConnection(connection);
    }

    return history.listen(() => {
      if (history.action === "PUSH") {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
      }
      if (window.innerWidth < 993 && history.action === "PUSH" && document.documentElement.className.indexOf("nav-open") !== -1) {
        document.documentElement.classList.toggle("nav-open");
      }
    });
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          setStartedConnection(connection);
          connection.on("NewNotification", (message) => {
            window.dispatchEvent(new CustomEvent("newNotification"));

            let msgData = JSON.parse(message);
            if (notificationSystem.current) {
              let actionTitle = "View Appointment";
              let actionRoute = "";
              let level = "info";

              switch (msgData.type) {
                case "lowTechnicianRating":
                  actionTitle = "View Technician";
                  actionRoute = `/admin/lab-techs-details/${encodeURIComponent(msgData.data.TechnicianId)}`;
                  level = "warning";
                  break;
                case "appointmentPaymentIssue":
                case "appointmentPreAuthIssue":
                  actionTitle = "View Appointment";
                  actionRoute = `/admin/appointments/${encodeURIComponent(msgData.data.AppointmentId)}`;
                  level = "error";
                  break;
              }

              const markAsRead = (id) => {
                axios
                  .put(`${process.env.REACT_APP_API_URL}/notifications/read`, { ids: [id] })
                  .then(() => {
                    window.dispatchEvent(new CustomEvent("notificationsRead"));
                  })
                  .catch((err) => {
                    parseError(err);
                  });
              };

              notificationSystem.current.addNotification({
                uid: msgData.id,
                title: `${msgData.title}`,
                message: `${msgData.body}`,
                level: level,
                autoDismiss: 6,
                dismissible: "both",
                action: {
                  label: actionTitle,
                  callback: function () {
                    history.push(actionRoute);
                    markAsRead(msgData.id);
                  },
                },
              });
            }
          });
        })
        .catch((e) => console.error(e));
    }
  }, [connection]);

  useEffect(() => {
    return () => {
      if (document.documentElement.className.indexOf("nav-open") !== -1) {
        document.documentElement.classList.toggle("nav-open");
      }
    };
  }, []);

  const handleMiniClick = () => {
    setPageMini(!pageMini);
    document.body.classList.toggle("sidebar-mini");
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            exact={prop.exact || false}
            render={(routeProps) => <prop.component {...routeProps} connection={startedConnection} />}
          />
        );
      } else {
        return null;
      }
    });
  };

  let session = getSession();

  if (!session) return <Redirect to={"/auth/login"} />;
  else
    return (
      <div className="wrapper">
        <NotificationSystem style={style} ref={notificationSystem} newOnTop={true} />
        <Sidebar {...props} mini={pageMini} />
        <div className={"main-panel" + (props.location.pathname === "/maps/full-screen-maps" ? " main-panel-maps" : "")}>
          <AdminNavbar {...props} handleMiniClick={handleMiniClick} navbar={navbar} />
          <Switch>{getRoutes(routes)}</Switch>
          <Footer fluid />
        </div>
      </div>
    );
};

export default Main;
