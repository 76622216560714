import React, { useState, useEffect, useRef } from "react";
import { FormControl, FormGroup, ControlLabel, OverlayTrigger, Popover } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Button from "components/CustomButton/CustomButton.jsx";
import Swal from "sweetalert2";
import Switch from "react-switch";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import Svg from "components/Svg/Svg.jsx";
import TextListControl from "./TextListControl/TextListControl.jsx";
import PlaceholderImage from "../../../assets/img/placeholder.svg";
import HtmlEditor from "components/HtmlEditor/HtmlEditor.jsx";
import { parseError } from "api/common.js";
import { SketchPicker } from "react-color";

const ServiceView = (props) => {
  const imageFileInput = useRef();
  const [serviceId, setServiceId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [disclaimer, setDisclaimer] = useState("");
  const [price, setPrice] = useState(0);
  const [duration, setDuration] = useState(0);
  const [commission, setCommission] = useState(0);
  const [pictureFile, setPictureFile] = useState(null);
  const [pictureData, setPictureData] = useState(null);
  const [picturePreview, setPicturePreview] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [checklist, setChecklist] = useState([]);
  const [dropOffRequired, setDropOffRequired] = useState(false);
  const [requisitionRequired, setRequisitionRequired] = useState(false);
  const [color, setColor] = useState("#fff");
  const [defaultColors] = useState([
    "#004E9A",
    "#DE3C63",
    "#EEA620",
    "#37AB85",
    "#014751",
    "#CE9FB7",
    "#FF9382",
    "#CDAD7A",
    "#AABB5D",
    "#F96300",
    "#6C47FF",
    "#587052",
    "#7D3D54",
    "#AF2C2C",
    "#00A656",
  ]);

  useEffect(() => {
    let svcId = props.match.params.id;
    if (svcId !== "new") {
      setServiceId(svcId);
    }
  }, []);

  useEffect(() => {
    if (serviceId && serviceId != "new") {
      setLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL}/services/${serviceId}`)
        .then((res) => {
          setLoading(false);
          initializeData(res.data);
        })
        .catch((err) => {
          setLoading(false);
          let error = parseError(err);
          Swal.fire("Service Error", error, "error");
        });
    }
  }, [serviceId]);

  useEffect(() => {
    return function cleanup() {
      if (picturePreview) {
        URL.revokeObjectURL(picturePreview);
      }
    };
  }, [picturePreview]);

  const initializeData = (service) => {
    setTitle(service.title);
    setDescription(service.description || "");
    setColor(service.color || "#fff");
    setPrice(service.basePrice / 100.0);
    setCommission(service.commissionPercentage);
    setDisclaimer(service.disclaimer || "");
    setDuration(service.durationInMinute);
    setRequisitionRequired(service.isRequisitionFormRequired);
    setDropOffRequired(service.isDropOffLocationRequired);
    setQuestions(
      (service.questionnaire || []).map((q) => {
        return { text: q.description, id: q.id };
      })
    );
    setChecklist(
      (service.checklist || []).map((q) => {
        return { text: q.description, id: q.id };
      })
    );

    if (service.picture) {
      setPictureData(service.picture);
    }
  };

  const onImageFileUpload = () => {
    imageFileInput.current.click();
  };

  const onImageFileChange = (e) => {
    setPictureFile(e.target.files[0]);
    setPicturePreview(URL.createObjectURL(e.target.files[0]));
  };

  const saveService = async (e) => {
    const isUpdate = serviceId && serviceId !== "new";
    setLoading(true);

    let uploadCompleted = false;
    let imageId = pictureData ? pictureData.id : null;

    try {
      if (pictureFile) {
        const file = pictureFile;
        let formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("type", "serviceImage");

        let fileRes = await axios.post(
          `${process.env.REACT_APP_API_URL}/file/upload/image`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        imageId = fileRes.data.id;
      }

      uploadCompleted = true;
    } catch (err) {
      setLoading(false);
      let error = parseError(err);
      Swal.fire("Image Upload Error", error, "error");
    }

    if (!uploadCompleted) {
      setLoading(false);
      return;
    }

    const serviceData = {
      id: serviceId ? serviceId : null,
      serviceTitle: title,
      serviceDescription: description || "",
      basePrice: parseInt(price * 100),
      durationInMinute: duration,
      commissionPercentage: commission,
      disclaimer: disclaimer || null,
      isDropOffLocationRequired: dropOffRequired,
      isRequisitionFormRequired: requisitionRequired,
      color: color,
      questionnaire: questions.map((q) => {
        return { description: q.text, id: q.id };
      }),
      checklist: checklist.map((q) => {
        return { description: q.text, id: q.id };
      }),
      picture: {
        fileName: imageId || null,
      },
    };

    const requestOptions = {
      method: isUpdate ? "PUT" : "POST",
      url: `${process.env.REACT_APP_API_URL}/services`,
      data: serviceData,
    };

    axios(requestOptions)
      .then((res) => {
        if (isUpdate) {
          initializeData(res.data);
        }
        setLoading(false);
        Swal.fire({
          title: "Service Created",
          text: `The service '${serviceData.serviceTitle}' has been ${
            isUpdate ? "updated" : "created"
          } successfully.`,
          icon: "success",
          showCancelButton: false,
        }).then(() => {
          if (!isUpdate) {
            props.history.push("/admin/services");
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Service Error", error, "error");
      });
  };

  const limitPrice = ({ floatValue }) => {
    return floatValue == undefined || floatValue < 999999.99;
  };
  const limitCommission = ({ floatValue }) => {
    return floatValue == undefined || floatValue <= 100.0;
  };
  const limitDuration = ({ floatValue }) => {
    return floatValue == undefined || floatValue < 3600;
  };

  return (
    <div className="main-content service">
      <Button
        simple
        icon
        style={{ padding: "0", marginBottom: "12px", marginRight: "auto" }}
        onClick={() => {
          props.history.go(-1);
        }}
      >
        <Svg name="chevron-left" className="w-6 h-6" />
        <span className="text-bold">Go Back</span>
      </Button>

      <Card
        content={
          <div className="flex flex-col gap-24">
            <div className="flex items-start col-2span gap-24">
              <FormGroup style={{ flexShrink: "0", marginBottom: "0" }}>
                <ControlLabel>Service Image:</ControlLabel>
                <div className="flex flex-col gap-12 items-start">
                  <img
                    src={
                      picturePreview
                        ? picturePreview
                        : pictureData
                        ? pictureData.url
                        : PlaceholderImage
                    }
                    alt="Service Picture"
                    style={{
                      width: "144px",
                      height: "144px",
                      overflow: "hidden",
                      borderRadius: "6px",
                      objectFit: "cover",
                      border: "1px solid var(--border-color)",
                    }}
                  />
                  <Button disabled={loading} bsStyle="danger" fill onClick={onImageFileUpload}>
                    <Svg name="upload" className="w-4 h-4" />
                    Select Image
                  </Button>
                  <span
                    className="sub-text text-xxs text-center"
                    style={{
                      width: "144px",
                    }}
                  >
                    Only JPEG/PNG images are allowed.
                  </span>

                  <input
                    accept="image/png,image/jpeg"
                    ref={imageFileInput}
                    id="file-upload"
                    style={{ display: "none" }}
                    type="file"
                    onChange={onImageFileChange}
                  />
                </div>
              </FormGroup>

              <div className="flex flex-col gap-16" style={{ width: "100%", marginBottom: "0" }}>
                <div className="flex items-start gap-16">
                  <FormGroup style={{ marginBottom: "0", width: "70%" }}>
                    <ControlLabel>
                      Service Name: <span className="star">*</span>
                    </ControlLabel>
                    <FormControl
                      required
                      disabled={loading}
                      type="text"
                      maxLength={100}
                      name="serviceTitle"
                      placeholder="Enter service name"
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                    />
                  </FormGroup>

                  <FormGroup style={{ marginBottom: "0" }}>
                    <ControlLabel>Service Color:</ControlLabel>
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="left"
                      overlay={
                        <Popover id="service-color-popover" positionLeft={0} arrowOffsetLeft={0}>
                          <SketchPicker
                            disableAlpha={true}
                            presetColors={defaultColors}
                            color={color}
                            onChange={(color) => {
                              setColor(color.hex);
                            }}
                          />
                        </Popover>
                      }
                    >
                      <Button bsStyle="danger" outline="true" style={{ padding: "10px 12px" }}>
                        <div className="service-color-preview" style={{ background: color }}></div>
                      </Button>
                    </OverlayTrigger>
                  </FormGroup>
                </div>

                <FormGroup style={{ marginBottom: "0" }}>
                  <ControlLabel>Disclaimer:</ControlLabel>
                  <FormControl
                    disabled={loading}
                    type="textarea"
                    maxLength={500}
                    componentClass="textarea"
                    rows={5}
                    placeholder="Enter the disclaimer (optional)"
                    value={disclaimer}
                    onChange={(event) => {
                      setDisclaimer(event.target.value);
                    }}
                  />
                </FormGroup>
              </div>
            </div>

            <FormGroup style={{ marginBottom: "0" }}>
              <ControlLabel>
                Description: <span className="star">*</span>
              </ControlLabel>
              <div className="service-editor">
                <HtmlEditor value={description} onChange={(val) => setDescription(val)} />
              </div>
            </FormGroup>

            <div className="separator horizontal col-2span"></div>

            <div className="grid grid-2col gap-24">
              <div className="flex flex-col gap-16">
                <FormGroup>
                  <ControlLabel>
                    Price: <span className="star">*</span>
                  </ControlLabel>

                  <NumberFormat
                    value={price}
                    defaultValue={0}
                    decimalScale={2}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    prefix="$"
                    className="form-control"
                    inputMode="numeric"
                    isAllowed={limitPrice}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setPrice(value ? parseFloat(value) : 0);
                    }}
                  />
                </FormGroup>

                <FormGroup style={{ marginBottom: "0" }}>
                  <ControlLabel>Technician Commission (Percentage):</ControlLabel>

                  <NumberFormat
                    value={commission}
                    defaultValue={0}
                    decimalScale={2}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    suffix="%"
                    className="form-control"
                    inputMode="numeric"
                    isAllowed={limitCommission}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setCommission(value ? parseFloat(value) : 0);
                    }}
                  />
                </FormGroup>
              </div>

              <div className="flex flex-col gap-16">
                <FormGroup>
                  <ControlLabel>Service Duration (Minutes):</ControlLabel>

                  <NumberFormat
                    value={duration}
                    defaultValue={0}
                    decimalScale={0}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={true}
                    suffix=" min"
                    className="form-control"
                    inputMode="numeric"
                    isAllowed={limitDuration}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setDuration(value ? parseInt(value) : 0);
                    }}
                  />
                </FormGroup>
              </div>

              <div className="separator horizontal col-2span"></div>

              <div className="swith  gap-12 flex item-center">
                <Switch
                  disabled={loading}
                  onChange={() => setRequisitionRequired((prev) => !prev)}
                  checked={requisitionRequired}
                  offColor="#E6E6E6"
                  onColor="#ffc6c9"
                  onHandleColor="#EA1B27"
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={40}
                />
                <div style={{ marginTop: "2px" }} className="text-xxs text-semibold">
                  REQUISITION FORM REQUIRED
                </div>
              </div>

              <div className="swith gap-12 flex item-center">
                <Switch
                  disabled={loading}
                  onChange={() => setDropOffRequired((prev) => !prev)}
                  checked={dropOffRequired}
                  offColor="#E6E6E6"
                  onColor="#ffc6c9"
                  onHandleColor="#EA1B27"
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={40}
                />
                <div style={{ marginTop: "2px" }} className="text-xxs text-semibold">
                  DROP OFF LOCATION REQUIRED
                </div>
              </div>

              <div className="separator horizontal col-2span"></div>

              <Card
                title="Questionnaire"
                category="Add questionnaire for the customer"
                content={
                  <div>
                    <TextListControl
                      disabled={loading}
                      onChange={(items) => {
                        setQuestions(items);
                      }}
                      textLabel="QUESTION"
                      items={questions}
                    />
                  </div>
                }
              />
              <Card
                title="Checklist"
                category="Add checklist for the technician"
                content={
                  <div>
                    <TextListControl
                      disabled={loading}
                      onChange={(items) => {
                        setChecklist(items);
                      }}
                      textLabel="DESCRIPTION"
                      items={checklist}
                    />
                  </div>
                }
              />
            </div>

            <Button
              loading={loading}
              disabled={loading || !title || price <= 0 || duration <= 0}
              onClick={() => saveService()}
              style={{ marginLeft: "auto" }}
              bsStyle="danger"
              fill
            >
              Save Service
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default ServiceView;
