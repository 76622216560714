import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import "react-phone-number-input/style.css";
import Svg from "components/Svg/Svg";
import FormPreviewModal from "./FormPreviewModal.jsx";

const ServiceFillFormModal = (props) => {
  const requisitionFileInput = useRef();
  const insuranceFileInput = useRef();
  const [questions, setQuestions] = useState([]);
  const [requisitionRequired, setRequisitionRequired] = useState(false);
  const [requisitionFile, setRequisitionFile] = useState();
  const [insuranceFile, setInsuranceFile] = useState();
  const [serviceData, setServiceData] = useState(null);
  const [insuranceFormId, setInsuranceFormId] = useState(null);
  const [requisitionFormId, setRequisitionFormId] = useState(null);
  const [formPreviewOpen, setFormPreviewOpen] = useState(false);
  const [formType, setFormType] = useState("requisition");

  useEffect(() => {
    setRequisitionRequired(false);
    setQuestions([]);
    setRequisitionFile(null);
    setRequisitionFormId(null);
    setInsuranceFile(null);
    setInsuranceFormId(null);

    if (props.service) {
      if (props.service.requisitionFormFile) {
        if (typeof props.service.requisitionFormFile === "string") {
          setRequisitionFormId(props.service.requisitionFormFile);
        } else {
          setRequisitionFile(props.service.requisitionFormFile);
        }
      }

      if (props.service.insuranceFormFile) {
        if (typeof props.service.insuranceFormFile === "string") {
          setInsuranceFormId(props.service.insuranceFormFile);
        } else {
          setInsuranceFile(props.service.insuranceFormFile);
        }
      }

      axios
        .get(`${process.env.REACT_APP_API_URL}/services/${props.service.id}`)
        .then((res) => {
          setServiceData(res.data);

          let items = res.data.questionnaire;
          items.forEach((q) => {
            q.completed = false;
            if (props.service.questionnaire) {
              var question = props.service.questionnaire.find((qs) => qs.id === q.id);
              if (question) {
                q.completed = question.completed;
              }
            }
          });

          setQuestions(items);
          setRequisitionRequired(res.data.isRequisitionFormRequired);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [props.service]);

  const onRequisitionFileUpload = () => {
    requisitionFileInput.current.click();
  };

  const onRequisitionFileChange = (e) => {
    setRequisitionFile(e.target.files[0]);
    setRequisitionFormId(null);
  };

  const onInsuranceFileUpload = () => {
    insuranceFileInput.current.click();
  };

  const onInsuranceFileChange = (e) => {
    setInsuranceFile(e.target.files[0]);
    setInsuranceFormId(null);
  };

  const onComplete = () => {
    var svc = props.service;

    props.onConfirm({
      key: svc.key || null,
      id: svc.id,
      title: svc.title,
      price: svc.price,
      questionnaire: questions,
      requisitionFormFile: requisitionFile || requisitionFormId,
      insuranceFormFile: insuranceFile || insuranceFormId,
    });
  };

  const getServicePicture = () => {
    if (props.service && props.service.picture && props.service.picture.url) {
      return props.service.picture.url;
    } else if (serviceData && serviceData.picture && serviceData.picture.url) {
      return serviceData.picture.url;
    }

    return null;
  };

  const gerServiceDescription = () => {
    if (props.service && props.service.description) {
      return props.service.description;
    } else if (serviceData && serviceData.description) {
      return serviceData.description;
    }

    return "";
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{props.service ? props.service.title : "Service"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card
          content={
            <div className="flex flex-col gap-12">
              <div className="flex items-start gap-24">
                {getServicePicture() && (
                  <img
                    style={{
                      flexShrink: "0",
                      border: "1px solid var(--border-color)",
                      width: "72px",
                      height: "72px",
                      borderRadius: "6px",
                      overflow: "hidden",
                      objectFit: "cover",
                    }}
                    alt="Service Preview"
                    src={getServicePicture()}
                  />
                )}
                <div
                  className="text-regular text-normal"
                  dangerouslySetInnerHTML={{
                    __html: gerServiceDescription(),
                  }}
                ></div>
              </div>

              {questions.length > 0 && (
                <React.Fragment>
                  <div className="separator horizontal"></div>

                  <div className="flex flex-col gap-6">
                    <div className="main-text text-semibold">
                      QUESTIONNAIRE <span className="star">*</span>
                    </div>
                    {questions.map((q) => (
                      <div className="checkbox" key={q.id}>
                        <input
                          name={q.id}
                          type="checkbox"
                          checked={q.completed}
                          onChange={() => {}}
                        />
                        <label
                          onClick={() => {
                            let question = questions.find((qq) => qq.id === q.id);
                            setQuestions((list) =>
                              list.map((qq) => {
                                if (qq.id === question.id) {
                                  question.completed = !question.completed;
                                  return question;
                                }
                                return qq;
                              })
                            );
                          }}
                          htmlFor={q.id}
                        >
                          {q.description}
                        </label>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              )}

              {requisitionRequired && (
                <React.Fragment>
                  <div className="separator horizontal"></div>

                  <div className="flex items-center gap-16">
                    <div className="flex flex-col gap-4">
                      <span className="text-semibold main-text">REQUISITION FORM</span>
                      <span className="text-xxs sub-text text-light">
                        UPLOAD PNG, JPEG OR PDF FILES
                      </span>
                    </div>

                    <input
                      accept="image/png, image/jpeg, application/pdf"
                      ref={requisitionFileInput}
                      id="file-upload"
                      style={{ display: "none" }}
                      type="file"
                      onChange={onRequisitionFileChange}
                    />

                    {!requisitionFile && !requisitionFormId && (
                      <Button
                        fill
                        bsStyle="danger"
                        style={{ marginLeft: "auto" }}
                        onClick={onRequisitionFileUpload}
                      >
                        <Svg name="upload" className="w-4 h-4" />
                        <span>Upload File</span>
                      </Button>
                    )}
                  </div>

                  <div className="flex items-center">
                    <div className="text-bold text-lg main-text mr-auto">
                      {requisitionFile ? requisitionFile.name : "Requisition Form"}
                    </div>

                    <div className="ml-auto gap-10 items-center flex">
                      {props.service && props.service.key && requisitionFormId && (
                        <Button
                          bsStyle="danger"
                          fill
                          onClick={() => {
                            setFormType("requisition");
                            setFormPreviewOpen(true);
                          }}
                        >
                          <Svg name="eye" className="w-4 h-4" />
                          Preview
                        </Button>
                      )}

                      {(requisitionFile || requisitionFormId) && (
                        <Button
                          fill
                          bsStyle="danger"
                          onClick={() => {
                            setRequisitionFile(null);
                            setRequisitionFormId(null);
                          }}
                        >
                          <Svg name="trash" className="w-4 h-4" />
                          <span>Remove File</span>
                        </Button>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              )}

              <div className="separator horizontal"></div>

              <div className="flex items-center gap-16">
                <div className="flex flex-col gap-4">
                  <span className="text-semibold main-text">INSURANCE FORM</span>
                  <span className="text-xxs sub-text text-light">
                    UPLOAD PNG, JPEG OR PDF FILES
                  </span>
                </div>

                <input
                  accept="image/png, image/jpeg, application/pdf"
                  ref={insuranceFileInput}
                  id="file-upload"
                  style={{ display: "none" }}
                  type="file"
                  onChange={onInsuranceFileChange}
                />

                {!insuranceFile && !insuranceFormId && (
                  <Button
                    fill
                    bsStyle="danger"
                    style={{ marginLeft: "auto" }}
                    onClick={onInsuranceFileUpload}
                  >
                    <Svg name="upload" className="w-4 h-4" />
                    <span>Upload File</span>
                  </Button>
                )}
              </div>

              {(insuranceFile || insuranceFormId) && (
                <div className="flex items-center">
                  <div className="text-bold text-lg main-text">
                    {insuranceFile ? insuranceFile.name : "Insurance Form"}
                  </div>
                  <div className="flex items-center" style={{ marginLeft: "auto" }}>
                    {props.service && props.service.key && insuranceFormId && (
                      <React.Fragment>
                        <Button
                          bsStyle="danger"
                          fill
                          onClick={() => {
                            setFormType("insurance");
                            setFormPreviewOpen(true);
                          }}
                        >
                          <Svg name="eye" className="w-4 h-4" />
                          Preview
                        </Button>
                      </React.Fragment>
                    )}
                    <Button
                      fill
                      bsStyle="danger"
                      style={{ marginLeft: "6px" }}
                      onClick={() => {
                        setInsuranceFile(null);
                        setInsuranceFormId(null);
                      }}
                    >
                      <Svg name="trash" className="w-4 h-4" />
                      <span>Remove File</span>
                    </Button>
                  </div>
                </div>
              )}

              <div className="separator horizontal"></div>

              <Button
                bsStyle="danger"
                disabled={questions.filter((q) => q.completed).length !== questions.length}
                fill
                onClick={onComplete}
              >
                Add Service
              </Button>

              {props.service && props.service.key && (
                <FormPreviewModal
                  show={formPreviewOpen}
                  formKey={props.service.key}
                  type={formType}
                  onHide={() => setFormPreviewOpen(null)}
                />
              )}
            </div>
          }
        />
      </Modal.Body>
    </Modal>
  );
};

export default ServiceFillFormModal;
