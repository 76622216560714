import React, { useState, useEffect, useRef } from "react";
import { FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Swal from "sweetalert2";
import Select from "react-select";
import Card from "components/Card/Card.jsx";
import axios from "axios";
import Svg from "components/Svg/Svg.jsx";
import PlaceholderImage from "../../../assets/img/placeholder.svg";
import { parseError } from "api/common.js";

const ServiceFolderView = (props) => {
  const imageFileInput = useRef();
  const [folderId, setFolderId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [pictureFile, setPictureFile] = useState(null);
  const [pictureData, setPictureData] = useState(null);
  const [picturePreview, setPicturePreview] = useState(null);
  const [services, setServices] = useState([]);
  const [locationServices, setLocationServices] = useState([]);

  useEffect(() => {
    getServices();

    let fId = props.match.params.id;
    if (fId !== "new") {
      setFolderId(fId);
    }
  }, []);

  useEffect(() => {
    if (folderId && folderId != "new") {
      setLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL}/services/folders/${folderId}`)
        .then((res) => {
          setLoading(false);
          initializeData(res.data);
        })
        .catch((err) => {
          setLoading(false);
          let error = parseError(err);
          Swal.fire("Service Folder Error", error, "error");
        });
    }
  }, [folderId]);

  useEffect(() => {
    return function cleanup() {
      if (picturePreview) {
        URL.revokeObjectURL(picturePreview);
      }
    };
  }, [picturePreview]);

  const getServices = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/services`);
    const data = res.data;
    const serviceItems = data.map((service) => ({
      value: service.id,
      label: service.title,
    }));

    setServices(serviceItems);
  };

  const initializeData = (folder) => {
    setTitle(folder.title);
    if (folder.picture) {
      setPictureData(folder.picture);
    }

    setLocationServices(
      folder.services.map((s) => {
        return {
          value: s.id,
          label: s.title,
        };
      })
    );
  };

  const onImageFileUpload = () => {
    imageFileInput.current.click();
  };

  const onImageFileChange = (e) => {
    setPictureFile(e.target.files[0]);
    setPicturePreview(URL.createObjectURL(e.target.files[0]));
  };

  const saveFolder = async (e) => {
    const isUpdate = folderId && folderId !== "new";
    setLoading(true);

    let uploadCompleted = false;
    let imageId = pictureData ? pictureData.id : null;

    try {
      if (pictureFile) {
        const file = pictureFile;
        let formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("type", "serviceImage");

        let fileRes = await axios.post(
          `${process.env.REACT_APP_API_URL}/file/upload/image`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        imageId = fileRes.data.id;
      }

      uploadCompleted = true;
    } catch (err) {
      setLoading(false);
      let error = parseError(err);
      Swal.fire("Image Upload Error", error, "error");
    }

    if (!uploadCompleted) {
      setLoading(false);
      return;
    }

    const folderData = {
      id: folderId ? folderId : null,
      title: title,
      picture: {
        fileName: imageId || null,
      },
      services: locationServices.map((s) => `SE#${s.value}`),
    };

    const requestOptions = {
      method: isUpdate ? "PUT" : "POST",
      url: isUpdate
        ? `${process.env.REACT_APP_API_URL}/services/folders/${folderId}`
        : `${process.env.REACT_APP_API_URL}/services/folders`,
      data: folderData,
    };

    axios(requestOptions)
      .then((res) => {
        if (isUpdate) {
          initializeData(res.data);
        }
        setLoading(false);
        Swal.fire({
          title: "Service Folder Created",
          text: `The service folder '${folderData.title}' has been ${
            isUpdate ? "updated" : "created"
          } successfully.`,
          icon: "success",
          showCancelButton: false,
        }).then(() => {
          if (!isUpdate) {
            props.history.push("/admin/services");
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        Swal.fire("Service Folder Error", error, "error");
      });
  };

  return (
    <div className="main-content service">
      <Button
        simple
        icon
        style={{ padding: "0", marginBottom: "12px", marginRight: "auto" }}
        onClick={() => {
          props.history.go(-1);
        }}
      >
        <Svg name="chevron-left" className="w-6 h-6" />
        <span className="text-bold">Go Back</span>
      </Button>

      <Card
        content={
          <div className="flex flex-col gap-24">
            <div className="flex items-start col-2span gap-24">
              <FormGroup style={{ flexShrink: "0", marginBottom: "0" }}>
                <ControlLabel>
                  Folder Image: <span className="star">*</span>
                </ControlLabel>
                <div className="flex flex-col gap-12 items-start">
                  <img
                    src={
                      picturePreview
                        ? picturePreview
                        : pictureData
                        ? pictureData.url
                        : PlaceholderImage
                    }
                    alt="Service Picture"
                    style={{
                      width: "144px",
                      height: "144px",
                      overflow: "hidden",
                      borderRadius: "6px",
                      objectFit: "cover",
                      border: "1px solid var(--border-color)",
                    }}
                  />
                  <Button disabled={loading} bsStyle="danger" fill onClick={onImageFileUpload}>
                    <Svg name="upload" className="w-4 h-4" />
                    Upload Image
                  </Button>
                  <span
                    className="sub-text text-xxs text-center"
                    style={{
                      width: "144px",
                    }}
                  >
                    Only JPEG/PNG images are allowed.
                  </span>

                  <input
                    accept="image/png,image/jpeg"
                    ref={imageFileInput}
                    id="file-upload"
                    style={{ display: "none" }}
                    type="file"
                    onChange={onImageFileChange}
                  />
                </div>
              </FormGroup>

              <div className="flex flex-col gap-16" style={{ width: "100%", marginBottom: "0" }}>
                <FormGroup>
                  <ControlLabel>
                    Folder Name: <span className="star">*</span>
                  </ControlLabel>
                  <FormControl
                    required
                    disabled={loading}
                    type="text"
                    maxLength={100}
                    name="serviceTitle"
                    placeholder="Enter service name"
                    value={title}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                  />
                </FormGroup>

                <div className="form-group" style={{ marginBottom: "0" }}>
                  <ControlLabel>Included Services:</ControlLabel>
                  <Select
                    isDisabled={loading}
                    required
                    isMulti
                    options={services}
                    value={locationServices}
                    onChange={(value) => setLocationServices(value)}
                    className="react-select info"
                    classNamePrefix="react-select"
                    placeholder="Select Services"
                    name="multipleSelect"
                  />
                </div>
              </div>
            </div>

            <div className="separator horizontal col-2span"></div>

            <Button
              loading={loading}
              disabled={loading || !title}
              onClick={() => saveFolder()}
              style={{ marginLeft: "auto" }}
              bsStyle="danger"
              fill
            >
              Save Folder
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default ServiceFolderView;
